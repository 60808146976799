<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <div
      class="is-flex is-flex-direction-column"
      style="height: 100%"
    >
      <ion-header class="is-flex ion-align-items-center ion-padding-start ion-padding-vertical">
        <h3 class="pl-6">
          <b> {{ $t('verifyProfile.profession.add') }} </b>
        </h3>

        <ion-buttons class="ml-auto">
          <ion-button
            class="modal-close-button"
            @click="close"
          >
            <ion-icon
              :icon="closeIcon"
              class="ion-margin-start"
            />
          </ion-button>
        </ion-buttons>
      </ion-header>

      <section class="ion-padding-vertical ion-padding-horizontal h-100">
        <UserProfessionsSelect
          :professions-list="professionsList"
          :add-user-profession-fn="addUserProfessionFn"
          :selected-profession-ids="selectedProfessionIds"
          :show-title="false"
          @close="close"
          @success-submit="submit"
          @update:bignumber-details="$emit('update:bignumber-details', $event)"
        />
      </section>
    </div>
  </ion-content>
</template>

<script>
import {
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';

import { close as closeIcon } from 'ionicons/icons';

import UserProfessionsSelect from "@/components/profile/professions/UserProfessionSelect";

export default {
  name: "EditProfessionModal",
  components: {
    UserProfessionsSelect,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    submitHandler: {
      type: Function,
      default: () => {},
    },
    professionsList: {
      type: Array,
      default () {
        return [];
      },
    },
    addUserProfessionFn: {
      type: Function,
      required: true,
    },
    selectedProfessionIds: {
      type: Array,
      default () {
        return [];
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      closeIcon,
    };
  },

  methods: {
    async submit () {
      await this.submitHandler().finally(() => {
        this.close();
      });
    },
  },
};
</script>
