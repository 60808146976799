<template>
  <div
    v-if="allForms.length"
    class="accreditation-requirements is-flex is-flex-direction-column"
    :class="{ 'full-height': isExpanded }"
  >
    <div>
      <ion-button
        class="is-relative uppercase"
        color="primary"
        mode="ios"
        expand="block"
        size="large"
        @click="isExpanded = !isExpanded"
      >
        <IonIcon
          slot="start"
          :icon="documentTextOutline"
          class="icon"
        />
        {{ $t('accreditation.requirements') }}
        <IonIcon
          slot="end"
          :class="[isExpanded ? 'rotate-90-self' : 'rotate-0-self']"
          :icon="chevronForwardOutline"
          class="icon"
        />
      </ion-button>
    </div>
    <div
      v-show="isExpanded"
      class="accreditation-content"
    >
      <PossibleProfessions />
      <p class="fz-12 fw-300 py-2">{{ $t('accreditation.steps') }}</p>

      <ion-button
        class="uppercase ion-margin-bottom"
        :class="{ animated: !isConfirmedProfile }"
        :fill="isConfirmedProfile ? 'outline' : 'solid'"
        mode="ios"
        expand="block"
        @click="openVerificationModal"
      >
        <div
          class="
              is-flex
              ion-align-items-center ion-justify-content-between
              w-100
            "
        >
          <div class="is-flex ion-align-items-center">
            <IonIcon :icon="personCircleOutline" />
            <p class="pl-2">
              {{
                !isConfirmedProfile
                  ? $t('accreditation.verifyBtn')
                  : $t('accreditation.verifyBtnConfirmed')
              }}
            </p>
          </div>
          <IonIcon
            v-if="isConfirmedProfile"
            :icon="checkmarkDone"
            color="success"
          />
        </div>
      </ion-button>
      <div
        v-if="!isConfirmedProfile"
        class="is-flex ion-padding-horizontal"
      >
        <IonIcon :icon="personCircleOutline" />
        <p class="pl-2 fz-12">{{ $t('accreditation.verifyPlaceholder') }}</p>
      </div>

      <div
        v-if="isConfirmedProfile"
        class="exam-btns"
      >
        <ExamButton
          v-if="getFormResult('accreditation')"
          :form-result="getFormResult('accreditation')"
          :is-accreditation-passed="examState.isPassed"
          :stream-id="streamId"
        />
        <ExamButton
          v-if="getFormResult('review') && examState.isCompleted"
          :form-result="getFormResult('review')"
          :stream-id="streamId"
        />
        <div
          v-if="examState.isPassed && isReviewPassed"
          class="is-flex ion-padding-horizontal"
        >
          <IonIcon :icon="medalOutline" />
          <p class="pl-2 fz-12">{{ $t('accreditation.congratulationPlaceholder') }}</p>
        </div>

        <!--        temp solution for commission-->
        <ReplayQuestionsContainer
          v-if="false"
          :form-id="getFormResult('accreditation')?.id"
          :stream-id="streamId"
          :missed-questions="missedQuestions"
        />

        <SkippedPlayerTrackContainer v-if="!scrubbingDisabled" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { IonIcon, IonButton, modalController } from '@ionic/vue';
import {
  chevronForwardOutline,
  documentTextOutline,
  personCircleOutline,
  checkmarkDone,
  medalOutline,
} from 'ionicons/icons';
import PossibleProfessions from '@/components/accreditation/PossibleProfessions';
import VerifyInformationModal from '@/components/accreditation/VerifyInformationModal';
import ExamButton from '@/components/accreditation/ExamButton';
import ReplayQuestionsContainer from '@/components/accreditation/ReplayQuestionsContainer';
import SkippedPlayerTrackContainer from "@/components/accreditation/SkippedPlayerTrackContainer";

import { useStore } from 'vuex';
import { onUnmounted, watch } from '@vue/runtime-core';
import {
  DISPLAY_TYPE_VIDEO,
  DISPLAY_ACTION_SHOW_QUESTION,
} from "@/config/constants";
import { convertStringHHMMSStoSec, showManually, showByPlayerTime, showByCurrentTime } from "@/helpers/functions";
import isNil from "lodash/isNil";
import {useRouter} from "vue-router";

export default {
  name: 'AccreditationRequirements',
  components: {
    IonIcon,
    IonButton,
    PossibleProfessions,
    ExamButton,
    ReplayQuestionsContainer,
    SkippedPlayerTrackContainer,
  },
  props: {},

  setup() {
    const store = useStore();
    const router = useRouter();

    const isExpanded = ref(false);

    const userResults = computed(() => store.state.questionnaires.userResults);
    const examState = computed(() => {
      const formResults = userResults.value;
      const isCompleted = isNil(formResults.total_questions_count)
        ? null
        : formResults.total_questions_count === formResults.user_answered_questions_count;
      const isPassed = isNil(formResults.user_score_percentage)
        ? null
        : formResults.user_score_percentage >= formResults.passing_score_percentage;

      return { isCompleted, isPassed };
    });

    const isAccreditationPassed = computed(() => {
      const form = getFormResult('accreditation');
      if (form) {
        const countQuestions = countQuestionsAnswers(form);
        return  form.results.user_score_percentage >=  form.results.passing_score_percentage && countQuestions.completedQuestions === countQuestions.allQuestions;
      }
      return false;
    });
    const isReviewPassed = computed(() => {
      const form = getFormResult('review');
      if (form) {
        const countQuestions = countQuestionsAnswers(form);
        return countQuestions.completedQuestions === countQuestions.allQuestions;
      }
      return false;
    });

    const allFormsResult = computed(() => store.state.questionnaires.allFormsResult);

    const isConfirmedProfile = computed(() => store.state.accreditation.isConfirmedProfile);
    const allForms = computed(() => store.state.questionnaires.allForms);
    const streamId = computed(() => store.getters['player/getCurrentEpisode']?.id);
    const scrubbingDisabled = computed(() => store.getters['player/getCurrentEpisode']?.disable_scrubbing);

    const accreditationFormResult = computed(() => store.state.questionnaires.userAnswers);
    const accreditationForm = computed(() => store.state.questionnaires.formAccreditation);
    const playerCurrentTime = computed(() => store.state.player.trackInfo.trackCurrentTime);

    const openQuestions = computed(() =>
      accreditationFormResult.value.filter((answer) => !answer.answers.length || (answer.attempts_left && answer.is_correct === false)),
    );

    const getFilteredFormQuestions = computed(() => {
      const questionsResultIds = getQuestionsResultIds(openQuestions.value);
      const filteredFormQuestions = accreditationForm.value.questions.filter(question => questionsResultIds.includes(question.id) && question.display_moments.length).sort((a, b) => a.position - b.position);
      return filteredFormQuestions;
    });

    const missedQuestions = computed(() => {
      const questions = getFilteredFormQuestions.value.filter((question) => {
        return !showQuestion(question) && questionSkipped(question);
      });
      return questions;
    });

    const currentTime = computed(() => store.state.app.currentTime);
    const currentTimeIntervalId = computed(() => store.state.app.currentTimeIntervalId);

    const questionSkipped = (question) => {
      const playerRanges = [];
      const videoPlayerMoments = question.display_moments.filter(setting => setting.type === DISPLAY_TYPE_VIDEO && setting.action === DISPLAY_ACTION_SHOW_QUESTION);
      videoPlayerMoments.forEach((setting) => {
        const secondsEnd = convertStringHHMMSStoSec(setting.time_close);
        playerRanges.push(secondsEnd);
      });
      for (let i = 0; i < playerRanges.length; i++) {
        if (playerCurrentTime.value > playerRanges[i]) {
          return true;
        }
      }
      return false;
    };

    const showQuestion = (question) => {
      const visible =
        showManually(question, DISPLAY_ACTION_SHOW_QUESTION) ||
        showByPlayerTime(question, DISPLAY_ACTION_SHOW_QUESTION, playerCurrentTime.value) ||
        showByCurrentTime(question, DISPLAY_ACTION_SHOW_QUESTION, currentTime.value);

      return visible;
    };

    const getFormResult = (type) => {
      return allFormsResult.value.find((form) => form.type === type);
    };

    const openVerificationModal = async () => {
      const modal = await modalController.create({
        component: VerifyInformationModal,
        cssClass: 'verify-information-modal',
        componentProps: {
          streamId: streamId.value,
          onConfirm() {
            const form = getFormResult('accreditation');
            if (!form) { return; }
            store.commit('player/setPlayerCollapse', true);
            router.push(`/accreditation/${streamId.value}/${form.id}`);
          },
        },
      });
      await modal.present();
    };
    const countQuestionsAnswers = ((form) => {
      const answeredQuestions = form?.questions.filter((question) => question.answers.length && (question.is_correct !== false || !question.attempts_left));
      return {
        completedQuestions: answeredQuestions?.length,
        allQuestions: form?.questions.length,
      };
    });

    const getQuestionsResultIds = (arr) => {
      return  arr.map(question => question.id);
    };

    watch(() => streamId.value, (newValue) => {
      if (newValue) {
        store.commit('questionnaires/resetStore');
        store
          .dispatch('questionnaires/getAllForms', newValue)
          .then((data) => store.dispatch('questionnaires/getAllFormsResult', data.forms));
      }
    });

    store
      .dispatch('questionnaires/getAllForms', streamId.value)
      .then((data) => store.dispatch('questionnaires/getAllFormsResult', data.forms));

    watch(() => currentTimeIntervalId.value, (newValue) => {
      if (!newValue) {
        store.dispatch('app/startUpdateCurrentTime');
      }
    });

    if (!currentTimeIntervalId.value) {
      store.dispatch('app/startUpdateCurrentTime');
    }

    onUnmounted(() => {
      store.commit('app/resetCurrentTimeIntervalId');
    });

    return {
      isExpanded,
      chevronForwardOutline,
      documentTextOutline,
      personCircleOutline,
      openVerificationModal,
      VerifyInformationModal,
      isConfirmedProfile,
      checkmarkDone,
      allForms,
      streamId,
      allFormsResult,
      getFormResult,
      isAccreditationPassed,
      isReviewPassed,
      medalOutline,
      accreditationFormResult,
      accreditationForm,
      openQuestions,
      getFilteredFormQuestions,
      missedQuestions,
      examState,
      scrubbingDisabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.accreditation-requirements {
  overflow-y: auto;
  height: 65px;
  p {
    line-height: 1.2;
  }
  .button-left-content {
    display: flex;
    width: 100%;
  }
  transition: height .25s;
}
.full-height {
  height: calc(100vh - 287px);
}
.animated {
  animation: shiny-btn 2s ease-in-out infinite;
}
.accreditation-content {
  overflow-y: auto;
}
@keyframes shiny-btn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .full-height {
  height: calc(100vh - 253px);
}
}

</style>
