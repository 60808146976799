import createFirebaseApp from "@/plugins/firebase";
import { getDBServerValue } from "@/plugins/firebase";

let app = null;
let auth = null;
let db = null;

export default () => {
  const createApp = () => {
    app = createFirebaseApp();
  };

  // auth
  const initAuth = () => {
    if (!app) {
      createApp();
    }
    if (auth) { return auth; }
    auth = app.auth();
    return auth;
  };
  const signAnonymously = () => {
    if (!auth) {
      initAuth();
    }
    return auth.signInAnonymously();
  };

  // db
  const initDB = () => {
    if(!app) {
      createApp();
    }
    if (db) { return db; }
    db = app.database();
    db.goOnline();
    return db;
  };

  const getUid = () => {
    if (auth) {
      return auth.getUid();
    }
    return null;
  };

  const initFirebase = async (signIn = true) => {
    await createApp();
    // console.log('app is', app);
    await initAuth();
    await initDB();
    if (signIn) {
      await signAnonymously();
    }

    // console.log('app', app);
    // console.log('auth', auth);
    // console.log('db', db);
    // console.log('uid', getUid());
  };

  const getTimestampKey = () => {
    return getDBServerValue();
  };

  return {
    initFirebase,
    initDB,
    initAuth,
    signAnonymously,
    getTimestampKey,
  };
};
