<template>
  <div
    ref="targetEl"
    :style="!shouldRender ? `min-height:${minHeight}px`: null"
  >
    <slot v-if="shouldRender"></slot>
  </div>
</template>

<script>
import { useIntersectionObserver } from "@vueuse/core";
import { ref } from "vue";
export default {
  name: 'AppLazy',
  props: {
    renderOnIdle: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 350,
    },
  },
  setup(props) {
    const shouldRender = ref(false);
    const targetEl = ref();
    const fixedMinHeight = ref(0);
    const { stop } = useIntersectionObserver(
      targetEl,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          shouldRender.value = true;
          stop();
        }
      },
      {
        rootMargin: "0px 0px 0px 0px",
        threshold: 0,
      },
    );
    return { targetEl, shouldRender, fixedMinHeight };
  },
};
</script>
