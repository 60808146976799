<template>
  <div
    ref="tooltip"
    :class="rootClasses"
  >
    <transition name="fade">
      <div
        v-show="active && (isActive || always)"
        ref="content"
        :class="['tooltip-content is-primary', contentClass]"
        @click="onTooltipClick"
      >
        <template v-if="label">{{ label }}</template>
        <template v-else-if="$slots.content">
          <slot name="content" ></slot>
        </template>
      </div>
    </transition>
    <div
      ref="trigger"
      class="tooltip-trigger"
      @click="onClick"
      @mouseenter="onHover"
      @focus.capture="onFocus"
      @blur.capture="close"
      @mouseleave="close"
    >
      <slot ref="slot" ></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTooltip",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    label: String,
    wrapperClass: String,
    contentClass: String,
    position: {
      type: String,
      default: 'is-bottom',
    },
    always: Boolean,
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    rootClasses() {
      return ['tooltip', this.position, this.wrapperClass];
    },
  },
  methods: {
    onClick() {
      this.open();
    },
    onTooltipClick() {
      this.$emit('click:label');
    },
    onHover() {
      this.open();
    },
    open() {
      this.isActive = true;
    },
    close() {
      this.isActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
  .tooltip {
    position: relative;

    .is-primary {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }

    .tooltip-content {
      width: auto;
      padding: 0.35rem 0.75rem;
      border-radius: 6px;
      font-size: 0.85rem;
      font-weight: 400;
      box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
      z-index: 38;
      white-space: nowrap;
      position: absolute;
    }

    .tooltip-content::before {
      position: absolute;
      content: "";
      pointer-events: none;
      z-index: 38;
    }
    .tooltip-trigger {
      width: 100%;
    }

    &.is-bottom {
      .tooltip-content {
        top: calc(100% + 7px);
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      .tooltip-content::before {
        top: auto;
        right: auto;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-right: 5px solid transparent;
        border-bottom: 5px  solid var(--ion-color-primary);
        border-left: 5px  solid transparent;
      }
    }

    &.is-left {
      .tooltip-content {
        top: 50%;
        right: calc(100% + 7px);
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
      }

      .tooltip-content::before {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 100%;
        transform: translateY(-50%);

        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid var(--ion-color-primary);
      }
    }

    &.is-multiline {
      .tooltip-content {
        display: flex;
        text-align: center;
        white-space: normal;
      }
    }

    &.is-size {
      .tooltip-content {
        width: 170px;
      }
    }
  }

</style>
