import axios from "@/http";
import {
  STORE_PUSH_NOTIFICATIONS_TOKEN,
  APP_VERSIONS, INVITE_TOKEN_VERIFY, GET_APP_CONFIG,
} from "@/config/api";
import useAppStorage from "@/composables/useAppStorage";
import { INVITE_TOKEN_PASSED_STORAGE_KEY } from "@/config/constants";

import { Device } from '@capacitor/device';

const { setKey, getKey } = useAppStorage();

const initializeStates = () => {
  return {
    appActive: false,
    notifications: {
      enabled: false,
    },
    network: {
      appInitState: null,
    },
    inviteKeyEnabled: false,
    playerActive: false,
    isNotificationOpen: false,
    pushNotification: {
      id: '',
      title: '',
      body: '',
      image: '',
      data: {},
    },
    platform: null,
    currentTime: null,
    currentTimeIntervalId: null,

    homeOrderConfig: {
      recently_played: -5,
      popular_streams: -4,
      popular_courses: -3,
      recommended: -2,
      new_release_streams: -1,
      following: 1000,
      new_release_courses: 9999,
    },
  };
};


export const state = () => initializeStates();

// getters
const getters = {
  notificationsEnabled: state => state.notifications.enabled,
};

// actions
const actions = {
  savePushToken (context, data) {
    return new Promise((resolve, reject) => {
      axios.post(STORE_PUSH_NOTIFICATIONS_TOKEN, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLatestVersion (context) {
    return new Promise((resolve, reject) => {
      axios.get(APP_VERSIONS)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  validateInviteToken (_, token) {
    return new Promise((resolve, reject) => {
      axios.post(INVITE_TOKEN_VERIFY, { token })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async saveInviteTokenStateInStorage ({ commit }) {
    setKey(INVITE_TOKEN_PASSED_STORAGE_KEY, 'true');
    commit('setInviteKeyState', false);
  },

  getAppConfig({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(GET_APP_CONFIG)
        .then(({ data }) => {
          dispatch('getInviteKeyState', Boolean(data.invite_token_config?.invite_token_enabled));
          commit('setHomeOrderPositions', data.order_config);

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getInviteKeyState ({ commit }, inviteTokenEnabled) {
    const info = await Device.getInfo();

    if (info.platform !== 'ios' && info.platform !== 'android') {
      return;
    }

    if (inviteTokenEnabled) {
      getKey(INVITE_TOKEN_PASSED_STORAGE_KEY).then((tokenClaimed) => {
        if (tokenClaimed !== 'true') {
          commit('setInviteKeyState', inviteTokenEnabled);
        }
      });
    }
  },
  startUpdateCurrentTime({ commit }) {
    const id = setInterval(() => {
      commit('updateCurrentTime');
    }, 1000);
    commit('setCurrentTimeIntervalId', id);
  },
};

// mutations
const mutations = {
  setNotificationsState (state, value) {
    state.notifications.enabled = value;
  },

  setPlayerState (state, value) {
    state.playerActive = value;
  },

  setAppState (state, value) {
    state.appActive = value;
  },

  setAppPlatform (state, value) {
    console.log('set platform', value);
    state.platform = value;
  },

  setAppInitNetworkState (state, value) {
    state.network.appInitState = value;
  },
  setInviteKeyState (state, value) {
    state.inviteKeyEnabled = value;
  },
  setPushNotification (state, payload) {
    state.isNotificationOpen = true;
    state.pushNotification = payload;
  },
  closeNotification (state) {
    state.isNotificationOpen = false;
  },
  updateCurrentTime (state) {
    state.currentTime = new Date();
  },
  setCurrentTimeIntervalId (state, value) {
    state.currentTimeIntervalId = value;
  },

  setHomeOrderPositions (state, value) {
    state.homeOrderConfig = value;
  },

  resetCurrentTimeIntervalId (state) {
    clearInterval(state.currentTimeIntervalId);
    state.currentTimeIntervalId = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
