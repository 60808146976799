<template>
  <ion-content >
    <div class="share-bottom-alert">
      <div class="ion-text-center ion-align-items-center ion-padding card is-flex is-flex-direction-column">
        <ion-header class="is-flex ion-align-items-center ion-padding-start ion-padding-vertical">
          <h3>
            <b> {{ modalTitle || $t('shareModal.share') }} </b>
          </h3>

          <ion-buttons class="ml-auto">
            <ion-button
              class="modal-close-button"
              @click="close"
            >
              <ion-icon :icon="closeIcon"/>
            </ion-button>
          </ion-buttons>
        </ion-header>

        <div class="content ion-padding-start">
          <ul class="items-list ion-margin-top">
            <li
              v-for="item in NETWORKS"
              :key="item.name"
              class="
                is-flex
                ion-align-items-center ion-justify-content-between
                mb-3
                network
                pointer
              "
              @click="handleLinkClick(item)"
            >
              <span>
                {{ item.title }}
              </span>

              <span class="app-icon">
                <ion-icon
                  v-if="item.logo"
                  :icon="item.logo"
                  size="large"
                />
                <component
                  v-else
                  :is="item.icon"
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';

import TelegramIcon from '@/components/icons/TelegramIcon.vue';
import AppInput from '@/components/common/AppInput.vue';

import useCopyToClipboard from '@/composables/useCopyToClipboard';
import useInAppBrowser from '@/composables/useInAppBrowser';

import { Share } from '@capacitor/share';

import { useI18n } from 'vue-i18n/index';
import {
  logoWhatsapp,
  copyOutline,
  mailOutline,
  ellipsisHorizontalOutline,
  close as closeIcon,
} from 'ionicons/icons';

import useTracking from "@/composables/useTracking";
import {TRACKING_SHARE_COURSE, TRACKING_SHARE_STREAM} from "@/config/constants";

export default {
  name: 'ShareModal',
  components: {
    AppInput,
    TelegramIcon,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    shareLink: {
      type: String,
      default: "",
    },
    shareText: {
      type: String,
      default: "",
    },
    courseId: {
      type: [String, Number],
      default: null,
    },
    streamId: {
      type: [String, Number],
      default: null,
    },
    showLink: {
      type: Boolean,
      default: true,
    },
    modalTitle: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const { openUrl } = useInAppBrowser();
    const { copy } = useCopyToClipboard();
    const { trackThis } = useTracking();

    const NETWORKS = [
      {
        name: 'copy',
        title: t('shareModal.share_link'),
        logo: copyOutline,
        showLink: true,
        handler: () => copyShare(),
      },
      {
        name: 'whatsapp',
        linkPattern: 'https://wa.me?text={{text}}',
        title: 'WhatsApp',
        logo: logoWhatsapp,
        showLink: true,
      },
      {
        name: 'telegram',
        linkPattern: 'https://telegram.me/share/url?url={{url}}&text={{text}}',
        title: 'Telegram',
        icon: TelegramIcon,
      },
      {
        name: 'email',
        linkPattern: `mailto:?subject=${t(
          'shareModal.email_subject',
        )}&body={{text}}`,
        title: t('userProfile.email'),
        logo: mailOutline,
        showLink: true,
      },
      {
        name: 'more',
        title: t('shareModal.more_options'),
        logo: ellipsisHorizontalOutline,
        handler: () => shareSystem(),
      },
    ];

    const handleLinkClick = (network) => {
      // console.log('click,', network);
      // tracking
      if (props.streamId) {
        trackThis(TRACKING_SHARE_STREAM, {}, props.streamId);
      } else if (props.courseId && !props.streamId) {
        trackThis(TRACKING_SHARE_COURSE, {}, props.courseId);
      }

      network.linkPattern ? share(network) : network.handler();
    };

    const share = (network) => {
      let url = network.linkPattern
        .replace(/{{url}}/g, encodeURIComponent(props.shareLink || ''))
        .replace(/{{text}}/g, encodeURIComponent(`${props.shareText} `));
      if (network.showLink && props.showLink) {
        url += props.shareLink;
      }
      openUrl(url);
      props.close();
    };

    const copyShare = () => {
      const v = props.shareLink;
      copy(v);
      props.close();
    };

    const shareSystem = () => {
      Share.share({
        url: props.shareLink,
      });
      props.close();
    };

    return {
      NETWORKS,
      closeIcon,
      handleLinkClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.share-bottom-alert {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 350px;
  background: var(--ion-color-medium);
}
.items-list {
  padding: 0;
}
.content {
  width: 100%;
}
</style>

<style lang="scss">
  .share-modal {
    --height: 350px;
    --border-radius: 30px 30px 0 0;
    align-items: flex-end;

    .modal-wrapper {
      position: absolute;
      bottom: 0;
    }
    .modal-shadow {
      bottom: 0;
    }
  }
</style>
