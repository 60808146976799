import {PushNotifications} from '@capacitor/push-notifications';
import {Device} from '@capacitor/device';
import {Capacitor} from '@capacitor/core';
import {alertController, toastController} from "@ionic/vue";

import {useStore} from 'vuex';

import useAppStorage from "@/composables/useAppStorage";

import {
  DEEP_LINK_REDIRECT_STORAGE_KEY,
  NOTIFICATIONS_ASK_COUNT_KEY,
  PUSH_ACTION_REDIRECT,
  TRACKING_NOTIFICATION_ACTION,
} from "@/config/constants";
import {NOTIFICATIONS_ASK_MAX_ATTEMPTS} from "../config/constants";

import {useI18n} from 'vue-i18n/index';
import {useRouter} from "vue-router";
import useTracking from "@/composables/useTracking";
import useNativeSettings from "@/composables/useNativeSettings";

let notificationListenersInitialized = false;

export default () => {
  const store = useStore();
  const router = useRouter();
  const { trackThis } = useTracking();
  const { t } = useI18n();

  const { openAppDetails } = useNativeSettings();

  const isPermissionsAvailable = () => Capacitor.isPluginAvailable('PushNotifications');


  // push actions
  // redirect:
  const redirectOnPushAction = (notification) => {
    // console.log('redirectOnPushAction', notification);
    trackThis(TRACKING_NOTIFICATION_ACTION, { push_type: notification?.data?.type });
    if (!notification?.data?.meta_data) {
      return;
    }
    const parsedMeta = JSON.parse(notification.data.meta_data);
    const metaCourse = parsedMeta.course_id;
    const metaStream = parsedMeta.stream_id;
    let redirect = `/course/${metaCourse}`;
    if (metaStream){
      redirect += `?s=${metaStream}`;
    }

    // console.log('redirect on push action', redirect);

    if (redirect) {
      if (!store.getters['authentication/isLogged']) {
        setKey(DEEP_LINK_REDIRECT_STORAGE_KEY, redirect);
      }

      router.push(redirect);
    }
  };

  const openPushToast = async (title, body, notification) => {
    const toast = await toastController
      .create({
        header: title,
        message: body,
        position: 'top',
        buttons: [
          {
            side: 'start',
            text: t('commonKeys.open'),
            handler: () => {
              redirectOnPushAction(notification);
            },
          }, {
            text: t('commonKeys.close'),
            role: 'cancel',
          },
        ],
      });
    await toast.present();

    const ond = await toast.onDidDismiss();
    // console.log('onDidDismiss resolved with', ond);
  };



  // storage
  const { setKey, getKey } = useAppStorage();
  const getNotificationsAskingCount = async () =>  {
    const count = await getKey(NOTIFICATIONS_ASK_COUNT_KEY);
    return count ?? 0;
  };
  const incrementNotificationsAskingCount = async () => {
    let current = await getNotificationsAskingCount();
    const newOne = String(++current);
    setKey(NOTIFICATIONS_ASK_COUNT_KEY, newOne);
  };

  const requestPermission = async () => {
    const permissionStatus = await PushNotifications.checkPermissions();

    if (permissionStatus.receive === 'denied') {
      return permissionStatus;
    }

    const result = await PushNotifications.requestPermissions();
    console.log('await PushNotifications.requestPermissions()', result);
    return result;
  };

  const checkNotificationsGranted = async () => {
    if (!isPermissionsAvailable()) {
      console.log('permission not avaliable');
      return;
    }

    const info = await Device.getInfo();
    const platform = info.platform;
    const result = await requestPermission(platform);

    console.log('checkNotificationsGranted result', result);

    // console.log('results in checkNotificationsGranted');

    // for android first permission request was granted be default

    const permissionGranted = result.receive === 'granted';

    if (!permissionGranted) {
      const currentAttempts = await getNotificationsAskingCount();
      if (Number(currentAttempts) < Number(NOTIFICATIONS_ASK_MAX_ATTEMPTS)) {
        openSettingsPrompt();
        incrementNotificationsAskingCount();
      }
    }

    store.commit('app/setNotificationsState', result.granted);

    // granted = true or false
    return result.receive === 'granted';
  };

  const initNotificationListeners = () => {
  //  console.log('init listeners');
    if ( notificationListenersInitialized ) { return; }

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error) => {
    //  console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      // console.log('original Push received', notification);
      switch (notification?.data?.action) {
      case PUSH_ACTION_REDIRECT: store.commit('app/setPushNotification', notification);
        break;
      };
      await PushNotifications.removeAllDeliveredNotifications();
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      // console.log('Push action performed: ' + JSON.stringify(notification));
      // console.log('original notification', notification);
      switch (notification?.notification?.data?.action) {
      case PUSH_ACTION_REDIRECT: redirectOnPushAction(notification.notification);
        break;
      }
    });


    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token) => {
      // console.log('Registered!: ',  token);
      // console.log('registration, store:', store);
      // console.log('registration, this', this);
      store.commit('app/setNotificationsState', true);

      const info = await Device.getInfo();

      // console.log('device info', info);

      if (info.platform !== 'ios' && info.platform !== 'android') {
        return;
      }

      const deviceData = await Device.getId();
      console.log('deviceid is', deviceData);

      store.dispatch('app/savePushToken', { type: info.platform, token:token?.value, device_id: deviceData.identifier });
    });

    notificationListenersInitialized = true;
  };

  const openSettings = () => {
    setTimeout(() => {
      openAppDetails();
    });
  };

  const openSettingsPrompt = async() => {
    const alert = await alertController
      .create({
        header: t('pushNotifications.disabledPrompt.title'),
        message: t('pushNotifications.disabledPrompt.message'),
        buttons: [
          {
            role: 'cancel',
            text: t('pushNotifications.disabledPrompt.actionNo') },
          {
            text: t('pushNotifications.disabledPrompt.actionYes'),
            handler: openSettings,
          },
        ],
      });
    return alert.present();
  };

  const permissionAccessExplain = async() => {
    const alert = await alertController
      .create({
        header: t('pushNotifications.describe.title'),
        message: t('pushNotifications.describe.message'),
        buttons: [
          { text: t('pushNotifications.describe.actionNo') },
          {
            text: t('pushNotifications.describe.actionYes'),
            handler: registerPushNotifications,
          },
        ],
      });
    return alert.present();
  };

  const registerPushNotifications = async (platform) => {
    const result = await requestPermission(platform);
    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      // console.log('PushNotifications.register()');
      PushNotifications.register();
    }
  };


  const initNotifications = async (forceRegister) => {
    if (!isPermissionsAvailable()) {
      return;
    }


    await PushNotifications.removeAllListeners();
    initNotificationListeners();

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting

    const info = await Device.getInfo();

    const askedCount = await getNotificationsAskingCount();
    // first visit
    if (!askedCount || forceRegister) {
      registerPushNotifications();
      incrementNotificationsAskingCount();
      return;
    }
    // for each next permission request use custom prompt
    const result = await checkNotificationsGranted();
    console.log('check granted', result);
    if (result) {
      registerPushNotifications();
    }
  };



  return {
    initNotifications,
    requestPermission,
    checkNotificationsGranted,
    initNotificationListeners,
    redirectOnPushAction,
  };
};
