import axios from '@/http';
import auth from '@/plugins/auth';
import { professionBignumberMapper } from '@/helpers/functions';
import cloneDeep from 'lodash/cloneDeep';


import {
  GET_PROFILE_DETAILS_URL,
  SEND_OTP_URL,
  VERIFY_OTP_URL,
  PROFESSIONS_LIST_URL,
  COMPLETE_PROFILE_URL,
  VERIFY_ACCOUNT_URL,
  UPDATE_ADDRESS,
  UPDATE_PERSONALIZATION,
  UPDATE_COMPANY,
  UPDATE_PROFILE_IMAGE,
  MEDIA_URL,
  UPDATE_AUTH_USER_EMAIL,
  VERIFY_PASSWORD_URL,
  SEND_VERIFICATION_EMAIL_URL,
  USER_PROFESSIONS,
  PROFILE_BIGNUMBER_UPDATE,
  PROFILE_BIGNUMBER_ADD,
  PROFILE_BIGNUMBER_REMOVE,
  BIGNUMBER_URL,
  CHANGE_PASSWORD,
  NOTIFICATIONS,
  VERIFY_PASSWORD_2FA_URL,
  VERIFY_TWO_FA,
  GET_PROFILE_TWO_FA_QR_IMAGE,
  DISABLE_2FA,
  GET_TWO_FA_QR_IMAGE,
} from "@/config/api";

const initializeStates = () => {
  return {
    profileDetails: {
      salutation: null,
      initials: null,
      title: null,
      first_name: null,
      insertion: null,
      last_name: null,
      birth_date: null,
      mobile: null,
      otp: null,
      mobile_verified_at: null,
      company: null,
      kvk_company: null,
      btw_number: null,
      agb_number: null,
      postal_code: null,
      street: null,
      street_number: null,
      street_addition: null,
      city: null,
      profession_id: null,
      specialization: null,
      registration_number: null,
      description: null,
      bignumber: '',
      newsletter_frequency: '',
      newsletter_abroad: true,
      preferences: null,
      isBignumberUpdated: 0,
      media: [],
      profession_name: null,
      check_required: 0,
      function: '',
    },
    notificationFrequencies: [],
    salutations: [],
    userProfessions: [],
    profileDetailsFetched: false,
    professionFromOccupation: [],
    language: auth.user()?.language,
    notificationsPreferences: {
      followed_companies: 0,
      notifications_favorite_courses: 0,
    },
  };
};

export const state = () => initializeStates();

// getters
const getters = {
  getProfileDetails: (state) => state.profileDetails,
  profileDetailsFetched: (state) => state.profileDetailsFetched,
  getProfessionFromOccupation: (state) => state.professionFromOccupation,
  getUserProfessions: (state) => {
    return cloneDeep(state.userProfessions).map(professionBignumberMapper);
  },
};

// actions
const actions = {
  setProfileDetails({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_PROFILE_DETAILS_URL)
        .then(({ data }) => {
          commit('setProfileDetails', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendOtp(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(SEND_OTP_URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyOtp(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(VERIFY_OTP_URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyPassword2fa (context, data) {
    return new Promise((resolve, reject) => {
      axios.post(VERIFY_PASSWORD_2FA_URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setProfessionsFromOccupations({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(PROFESSIONS_LIST_URL)
        .then((response) => {
          commit('setProfessionsFromOccupations', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyAccount(context, data) {
    const url = VERIFY_ACCOUNT_URL.replace('{user}', auth.user()?.id);

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  completeProfile(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(COMPLETE_PROFILE_URL, data)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateAddress(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(UPDATE_ADDRESS, data)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updatePersonalization(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(UPDATE_PERSONALIZATION, data)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCompany(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(UPDATE_COMPANY, data)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveImage({ commit, dispatch }, data) {
    const { originalImage, croppedImage } = data;

    return new Promise((resolve, reject) => {
      axios
        .post(UPDATE_PROFILE_IMAGE, {
          profile_pic_original: originalImage,
          profile_pic_cropped: croppedImage,
        })
        .then(() => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteMedia(_, mediaId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(MEDIA_URL.replace('{mediaId}', mediaId))
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAuthUserEmail(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(UPDATE_AUTH_USER_EMAIL.replace('{user}', data.user_id), data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyPassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(VERIFY_PASSWORD_URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyEmail(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(SEND_VERIFICATION_EMAIL_URL)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyTwoFA(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(VERIFY_TWO_FA, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generate2FAQRAndSecret(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(GET_PROFILE_TWO_FA_QR_IMAGE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generatePublic2FAQRAndSecret(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(GET_TWO_FA_QR_IMAGE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  disable2FA(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(DISABLE_2FA, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CHANGE_PASSWORD, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateBignumber (context, data) {
    return new Promise((resolve, reject) => {
      const url = PROFILE_BIGNUMBER_UPDATE.replace('{user}', auth.user()?.id);
      axios.post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addBignumber (context, data) {
    const url = PROFILE_BIGNUMBER_ADD.replace('{user}', auth.user()?.id);
    return new Promise((resolve, reject) => {
      axios.post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeBignumber (context, professionUserId) {
    const url = PROFILE_BIGNUMBER_REMOVE.replace('{profession_user_id}', professionUserId);
    return new Promise((resolve, reject) => {
      axios.delete(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setUserProfessions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(USER_PROFESSIONS)
        .then((response) => {
          commit('seUserProfessions', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setBignumberDetails ({ commit }, {
    bignumber,
    skipCache = false,
    userId,
  }) {
    return new Promise((resolve, reject) => {
      const data = skipCache ? { save_to_cache: 0 } : {};

      let url = BIGNUMBER_URL.replace('{bignumber}', bignumber);

      if (userId) {
        url = url + '/' + userId;
      }

      axios.get(url, {
        params: { ...data, multiProfessions: 1 },
      },
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getNotificationsPreferences({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(NOTIFICATIONS)
        .then(({ data }) => {
          commit('setNotificationsPreferences', data.push_preferences);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateNotificationsPreferences({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(NOTIFICATIONS, data)
        .then((response) => {
          commit('updateNotificationsPreferences', data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};

// mutations
const mutations = {
  setProfileDetails(state, data) {
    const profileDetails = data.profileDetails ? data.profileDetails : {};

    profileDetails.newsletter_abroad = data.preferences
      ? data.preferences.newsletter_abroad !== 0
      : false;

    profileDetails.newsletter_frequency = data.preferences
      ? data.preferences.newsletter_frequency
      : null;

    profileDetails.bignumber = data.bignumber ? data.bignumber : null;
    profileDetails.check_required = data.check_required;
    profileDetails.profession_id = data.profession_id;
    profileDetails.profession_name = data.profession_name;

    state.profileDetails = Object.assign(
      {},
      state.profileDetails,
      profileDetails,
    );

    state.salutations = data.salutations;
    state.notificationFrequencies = data.notificationFrequencies;
    state.userProfessions = data.professions || [];

    state.profileDetailsFetched = true;
  },

  changeLanguage(state, data) {
    state.language = data;
  },

  setProfessionsFromOccupations(state, list) {
    state.professionFromOccupation = list;
  },

  updateProfileDetails(state, data) {
    state.profileDetails = Object.assign({}, state.profileDetails, data);
  },
  updateNotificationsPreferences (state, { key, value }) {
    state.notificationsPreferences = {...state.notificationsPreferences, [key]: value};
  },
  setNotificationsPreferences (state, data) {
    state.notificationsPreferences = {...state.notificationsPreferences, ...data};
  },
  seUserProfessions (state, data) {
    state.userProfessions = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
