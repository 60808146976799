import axios from '@/http';

import useAppStorage from "@/composables/useAppStorage";
import useTracking from "@/composables/useTracking";

import {
  RECENTLY_PLAYED_MAX_COUNT,
  RECENTLY_PLAYED_PROGRESS_STORAGE_KEY,
  RECENTLY_PLAYED_STORAGE_KEY,
  TRACKING_PODCAST_VIEW,
} from "@/config/constants";

import {
  getArrayItemFromLocalStorage,
  getObjectItemFromLocalStorage,
  isObject,
  getCurrentUnix, getLatestTrackPositionByTimestamp,
} from '@/helpers/functions';

import { STREAM_USER_PROGRESS } from '@/config/api';

import cloneDeep from 'lodash/cloneDeep';

const { setKey } = useAppStorage();

const initializeStates = () => {
  return {
    recentlyPlayed: [],
    recentlyPlayedProgress: {},

    playerActive: false,
    playerCollapsed: false,

    currentCompany: {},
    currentCourse: {},

    playlist: [],
    playlistTrackStart: 0,
    playlistCurrentIndex: 0,

    seekPosition: null,

    trackInfo: {
      trackPlaying: false,
      trackDuration: 0,
      trackCurrentTime: 0,
    },

    streamSkipAutoplay: null,

    progressHistory: [],

    playerPlayPauseTriggers: {
      play: 0,
      pause: 0,
    },
  };
};


export const state = () => initializeStates();

export const actions = {
  async openPlayer(
    { commit, state, dispatch },
    {
      company,
      course,
      playlist,
      startAt = 0,
      tracking = TRACKING_PODCAST_VIEW,
      skipAutoplay = false,
      collapse = false,
    },
  ) {
    const { trackThis } = useTracking();
    trackThis(tracking, {}, course.streams[startAt]?.id);

    const savedStreamId = course.streams[startAt]?.id;

    const sortedPlaylist = cloneDeep(playlist).sort((streamA, streamB) => {
      const streamADate = new Date(streamA.rss_feed_publish_date ?? streamA.updated_at);
      const streamBDate = new Date(streamB.rss_feed_publish_date ?? streamB.updated_at);
      return streamADate - streamBDate;
    });

    const newStartedAt = sortedPlaylist.findIndex(stream => stream.id === savedStreamId);

    if (newStartedAt === -1) {
      return;
    }

    if (skipAutoplay === true) {
      commit('setSkipAutoplayId', savedStreamId);
    }

    commit('resetTrackInfo');
    commit('setPlaylist', { playlist: sortedPlaylist, startAt: newStartedAt });
    commit('setCompanyData', company); // @todo remove this
    commit('setCourseData', course); // @todo accept this, make required
    commit('setPlayerState', true);
    commit('setPlayerCollapse', collapse);



    dispatch('addRecentlyPlayed', { course, startAt });
  },

  async addRecentlyPlayed({ state, commit }, { course, startAt }) {
    if (!course) {
      return;
    }

    const recentlyPlayedList = await getArrayItemFromLocalStorage(RECENTLY_PLAYED_STORAGE_KEY);

    const stream = course?.streams?.[startAt];
    if (!stream) { return; }

    const storageData = state.recentlyPlayedProgress[stream?.id];
    const streamUserData = stream?.stream_user?.[0];

    const lastPosition = getLatestTrackPositionByTimestamp(streamUserData, storageData) ?? '00:00:00';

    // console.log('storing stream', stream);
    // console.log('last pos of stream', lastPosition);

    const recentlyPlayedStream = {
      course_title: course.course_title,
      course_id: course.id,
      course_media: course.media,
      stream_id: stream.id,
      id: stream.id,
      media: stream.media,
      stream_title: stream.title,
      duration: stream.duration,
      progress_end_time: lastPosition,
      progress_updated_timestamp: getCurrentUnix(),
      rss_feed_publish_date: stream.rss_feed_publish_date,
      created_at: stream.created_at,
    };

    const courseInRecentIndex = recentlyPlayedList.findIndex(played => played.stream_id === stream.id);

    if (courseInRecentIndex > -1) {
      recentlyPlayedList.splice(courseInRecentIndex, 1);
      recentlyPlayedList.splice(recentlyPlayedList.length, 0, recentlyPlayedStream);
    } else {
      recentlyPlayedList.push(recentlyPlayedStream);
    }

    const recentListStringified = JSON.stringify(recentlyPlayedList.slice(-RECENTLY_PLAYED_MAX_COUNT));

    await setKey(RECENTLY_PLAYED_STORAGE_KEY, recentListStringified);
    commit('setRecentlyPlayed', JSON.parse(recentListStringified));
  },

  async removeFromRecentlyPlayed ({ commit }, streamId) {
    const recentlyPlayedList = await getArrayItemFromLocalStorage(RECENTLY_PLAYED_STORAGE_KEY);
    const courseInRecentIndex = recentlyPlayedList.findIndex(played => played.stream_id === streamId);

    if (courseInRecentIndex > -1) {
      recentlyPlayedList.splice(courseInRecentIndex, 1);
    }

    const recentListStringified = JSON.stringify(recentlyPlayedList.slice(-RECENTLY_PLAYED_MAX_COUNT));

    await setKey(RECENTLY_PLAYED_STORAGE_KEY, recentListStringified);
    commit('setRecentlyPlayed', JSON.parse(recentListStringified));
  },

  closePlayer ({ commit }) {
    commit('resetTrackInfo');
    commit('setPlaylist', { playlist: []});
    commit('setCompanyData', null);
    commit('setPlayerState', false);
    commit('setPlayerCollapse', false);
  },

  async setRecentlyPlayedStorage({ commit }) {
    const recently = await getArrayItemFromLocalStorage(RECENTLY_PLAYED_STORAGE_KEY);

    // @todo mutate with data from BE and replace it;

    commit('setRecentlyFromStorage', recently);
  },

  async loadRecentlyPlayedProgressStorage({ commit }) {
    const progress = await getObjectItemFromLocalStorage(RECENTLY_PLAYED_PROGRESS_STORAGE_KEY);
    // console.log('loadRecentlyPlayedProgressStorage: ', progress);
    commit('setRecentlyPlayedProgress', progress);
  },

  async resetRecentlyPlayedProgressStorage({ commit, dispatch }) {
    commit('setRecentlyPlayedProgress', {});
    dispatch('setRecentlyPlayedProgressStorage');
  },

  updateRecentlyPlayedProgressStorage({ commit, dispatch }, { stream, progress }) {
    const timestamp = getCurrentUnix();
    // console.log('updateRecentlyPlayedProgressStorage: ', stream, progress, timestamp);
    commit('updateRecentlyPlayedProgress', { stream, progress, timestamp });
    dispatch('setRecentlyPlayedProgressStorage');
  },

  async setRecentlyPlayedProgressStorage({ commit, state }) {
    await setKey(RECENTLY_PLAYED_PROGRESS_STORAGE_KEY, JSON.stringify(state.recentlyPlayedProgress));
  },

  getStreamUserProgress ({ commit }, { streamId, userId }) {
    const url = STREAM_USER_PROGRESS.replace('{stream_id}', streamId).replace('{user_id}', userId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export const mutations = {
  setPlayerState (state, value) {
    state.playerActive = value;
  },

  setPlayerCollapse (state, value) {
    state.playerCollapsed = value;
  },

  setEpisode (state, value) {
    state.currentEpisode = value;
  },

  setCompanyData (state, value) {
    state.currentCompany = value;
  },

  setCourseData (state, value) {
    state.currentCourse = value;
  },

  seekStream (state, seconds) {
    state.seekPosition = seconds;
  },

  setRecentlyPlayed (state, data) {
    state.recentlyPlayed = data;
  },

  setRecentlyFromStorage (state, data) {
    state.recentlyPlayed = data;
  },

  setSkipAutoplayId (state, id) {
    state.streamSkipAutoplay = id;
  },

  setPlaylist(state, { playlist, startAt = 0 }) {
    if (Array.isArray(playlist)) {
      state.playlist = playlist;
      state.playlistTrackStart = startAt;
      state.playlistCurrentIndex = startAt;
    } else if (isObject(playlist)) {
      state.playlist = [playlist];
      state.playlistTrackStart = 0;
      state.playlistCurrentIndex = 0;
    }
  },

  setPlaylistCurrentIndex (state, index) {
    state.playlistCurrentIndex = index;
  },

  setRecentlyPlayedProgress (state, data) {
    state.recentlyPlayedProgress = data;
  },

  updateRecentlyPlayedProgress (state, { stream, progress, timestamp }) {
    state.recentlyPlayedProgress[stream] = { progress, timestamp };
  },

  setTrackInfo (state, data) {
    Object.assign(state.trackInfo, data);
  },

  resetTrackInfo (state) {
    state.trackInfo = { trackPlaying: false, trackDuration: 0, trackCurrentTime: 0 };
  },

  resetPlayerState (state) {
    Object.assign(state, initializeStates());
  },
  setProgressHistory (state, data) {
    state.progressHistory = data;
  },
  incrementPlayerPauseTrigger (state) {
    state.playerPlayPauseTriggers.pause += 1;
  },
  incrementPlayerPlayTrigger (state) {
    state.playerPlayPauseTriggers.play += 1;
  },
};

export const getters = {
  getCurrentEpisode: state => state.playlist[state.playlistCurrentIndex],
  getSeekPosition: state => state.seekPosition,
  getPlayerPlayPauseTriggers: state => state.playerPlayPauseTriggers,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
