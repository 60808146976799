import { VueReCaptcha } from 'vue-recaptcha-v3';

const VueReCaptchaSettings = {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
};

export default (app) => {
  app.use(VueReCaptcha, VueReCaptchaSettings);
};
