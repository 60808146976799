<template>
  <div
    class="featured-podcast pointer has-edit-button"
    :class="featuredPodcast.theme_size"
  >
    <ion-card class="m-y-4">
      <EditButton
        v-if="canEdit"
        :url="`featured-course/${featuredPodcast.id}/edit`"
      />
      <div class="img-wrap">
        <AppImg
          :img-src="imageUrl"
          img-alt="featured podcast image"
          border-radius="10px"
        />
      </div>
      <ion-card-header class="card-header">
        <ion-card-title class="card-title fz-12 fz-14-lg fw-500">
          {{ featuredPodcast.main_title }}
        </ion-card-title>
        <IonCardSubtitle
          class="card-subtitle fz-10 fz-12-lg fw-300 is-displaying-three-lines-text"
          v-html="featuredPodcast.content"
        />
      </ion-card-header>
    </ion-card>
  </div>
</template>

<script>
import AppImg from '@/components/common/AppImg.vue';
import EditButton from '@/components/common/EditButton.vue';
import { getSmallestSizeImage } from '@/helpers/functions';

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
} from '@ionic/vue';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'FeaturedPodcast',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonImg,
    AppImg,
    EditButton,
  },
  props: {
    featuredPodcast: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const imageUrl = computed(() => getSmallestSizeImage(props.featuredPodcast.media)?.url || null);
    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_featured_course');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    return { imageUrl, canEdit };
  },
};
</script>

<style scoped lang="scss">
@import "src/theme/custom";
.featured-podcast {
  width: 160px;
}
.card-header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 0px;

  .card-subtitle {
    text-transform: none;
    --color: #fff;
  }
}
.img-wrap {
  width: 143px;
  height: 143px;
  overflow: hidden;
  border-radius: 10px;
}
.featured-podcast ion-card {
  margin: 10px 16px 24px 0px;
}

.card-title {
  overflow: hidden;
  line-height: 1.5em;
  max-height: 1.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.featured-podcast.small {
  width: 116px;

  .img-wrap {
    width: 100px;
    height: 100px;
  }
  .card-header {
    display: none;
  }
}

.featured-podcast.large {
  width: 216px;

  .img-wrap {
    width: 200px;
    height: 200px;
  }
}

.featured-podcast.xl {
  width: 316px;

  .img-wrap {
    width: 300px;
    height: 300px;
  }
  .card-title {
    font-size: 16px;
  }
  .card-subtitle {
    font-size: 12px;
  }
}

.featured-podcast.full {
  width: $tablet-breakpoint;
  padding-right: 0.6rem;

  .img-wrap {
    width: calc(100vw - 1.2rem);
    height: calc(100vw - 1.2rem);

    @media (min-width: $tablet-breakpoint) {
      width: calc(#{$tablet-breakpoint} - 1.2rem);
      height: calc(#{$tablet-breakpoint} - 1.2rem);
    }
  }
  .card-title {
    font-size: 20px !important;
  }
  .card-subtitle {
    font-size: 14px !important;
  }
  ion-card {
    margin-right: 0;
  }
}
</style>
