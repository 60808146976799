import axios from "@/http";

import { SEARCH_PODCAST } from '@/config/api';

const containString = (targetString, searchText) => {
  if (!targetString) { return false; }
  return (
    targetString
      .toString()
      .toLowerCase()
      .indexOf(searchText.toLowerCase()) >= 0
  );
};


const initializeStates = () => {
  return {
    courses: [],

    filteredResultCourses: [],
    filteredResultStreams: [],

    userCollectionCourses: [],
    userCollectionStreams: [],

    searchQuery: '',

    pagination: {
      limit: 50,
      offset: 0,
    },
  };
};

export const state = () => initializeStates();

export const actions = {
  searchPodcasts ({ commit, state, rootState }, query) {
    return new Promise((resolve, reject) => {
      axios.get(SEARCH_PODCAST, {
        params: {
          q: query,
          limit: state.pagination.limit,
          offset: state.pagination.offset,
        },
      })
        .then(({ data }) => {
          commit('setSearchQuery', query);
          commit('setSearchResults', { data, rootState});
          commit('favorites/setFavoritesCoursesIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          commit('favorites/setFavoritesStreamsIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const mutations = {
  setSearchQuery(state, q) {
    state.searchQuery = q;
  },

  setSearchResults (state, { data, rootState }) {
    state.courses = data.courses;

    const favCourseIds = data.favourite_course_ids.map(course => course.course_id);
    const favStreamIds = data.favourite_course_ids.map(course => course.stream_id);

    const allStreams = [];
    data.courses.forEach(course => allStreams.push(...course.streams));

    state.userCollectionStreams = allStreams.filter(stream => {
      const favorite = favStreamIds.includes(stream.id);
      const playedBefore = stream?.stream_user?.[0]?.end_time || rootState.player.recentlyPlayedProgress[stream.id];
      return favorite || playedBefore;
    });
    state.userCollectionCourses = data.courses.filter(course => favCourseIds.includes(course.id));

    state.filteredResultCourses = data.courses.filter(course => {
      const favorite = favCourseIds.includes(course.id);
      const companyNameMatch = containString(course.company_name, state.searchQuery);
      const courseTitleMatch = containString(course.course_title, state.searchQuery);
      return !favorite;
    });

    state.filteredResultStreams = allStreams.filter(stream => {
      const favorite = favStreamIds.includes(stream.id);
      const playedBefore = stream?.stream_user?.[0]?.end_time || rootState.player.recentlyPlayedProgress[stream.id];
      const titleMatch = containString(stream.title, state.searchQuery);
      return !favorite && !playedBefore;
    });

  },

  resetMobileState (state) {
    Object.assign(state, initializeStates());
  },
};

export const getters = {
  getSearchCourses: state => state.courses,
  getUserCollectionCourses: state => state.userCollectionCourses,
  getUserCollectionStreams: state => state.userCollectionStreams,
  getFilteredCourses: state => state.filteredResultCourses,
  getFilteredStreams: state => state.filteredResultStreams,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
