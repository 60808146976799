<template>
  <div class="new-podcast pointer has-edit-button">
    <ion-card class="new-podcast-card is-flex">
      <EditButton
        v-if="canEdit"
        :url="`courses/${newPodcast.id}/edit`"
      />
      <div class="image-wrap">
        <AppImg
          :img-src="banner"
          img-alt="banner"
          border-radius="10px"
        />
      </div>
      <ion-card-header class="card-header">
        <ion-card-title class="card-title fz-10 fz-12-lg fw-500 is-displaying-two-lines-text">
          {{ newPodcast.company_name }}
        </ion-card-title>
        <ion-card-subtitle class="card-subtitle fz-10 fz-12-lg fw-300 is-displaying-two-lines-text">
          {{ newPodcast.course_title }}
        </ion-card-subtitle>
      </ion-card-header>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonImg,
} from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import { getSmallestSizeImage } from '@/helpers/functions';
import { computed } from '@vue/reactivity';
import EditButton from '@/components/common/EditButton.vue';
import { useStore } from 'vuex';

export default {
  name: 'NewPodcast',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonImg,
    AppImg,
    EditButton,
  },
  props: {
    newPodcast: {
      type: Object,
      required: true,
    },
  },

  setup (props) {
    const store = useStore();

    const banner = computed(() => getSmallestSizeImage(props.newPodcast.media)?.url ?? null );
    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_course');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    return {
      banner,
      canEdit,
    };
  },
};
</script>

<style scoped lang="scss">
/*.new-podcast {*/
/*  width: 50%;*/
/*}*/
.new-podcast-card {
  align-items: center;
  /*column-gap: 10px;*/
  text-align: left;
  margin: 10px 16px 10px 0;

  .image-wrap {
    width: 70px;
    min-width: 70px;
    height: 70px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
  }
}
.card-header {
  padding-left: 0;
  padding-right: 0;
  padding: 0px;
  max-width: 50%;

  .card-subtitle {
    text-transform: none;
    --color: #fff;
  }
}
ion-img {
  height: auto;
  width: 100%;
  object-fit: cover;
}
</style>
