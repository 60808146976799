import axios from '@/http';
import {
  GET_STREAM,
  GET_EXAM_USER_RESPONSE,
  QUESTIONNAIRE_SEND_SINGLE_USER_ANSWERS,
  QUESTIONNAIRE_USER_RESET_LAST_ATTEMPT,
  GET_EXAM_RESULTS,
  CERTIFICATE_EMAIL_SEND,
} from '@/config/api';
import cloneDeep from "lodash/cloneDeep";

const initializeStates = () => {
  return {
    formAccreditation: {},
    userAnswers: [],
    userResults: [],
    allForms: [],
    allFormsResult: [],
    courseId: null,
    examPassedState: false,
    examPassedPopupShown: false,
    setExamPassedStated: false,
  };
};
export const state = () => initializeStates();

// actions
const actions = {
  getUserResponses({ commit }, formId) {
    const url = GET_EXAM_USER_RESPONSE.replace('{formId}', formId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          commit('setUserAnswers', data);
          if (data.results) {
            commit('setUserResults', data.results);
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getForm({ commit }, { streamId, formId }) {
    const url = GET_STREAM.replace('{stream_id}', streamId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          const form = data.forms.find(form => form.id === +formId);
          commit('setFormAccreditation', form);
          commit('setCourseId', data.course_id);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllForms({ commit }, streamId) {
    const url = GET_STREAM.replace('{stream_id}', streamId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          if(data.forms) {
            const accreditationForm = data.forms.find(form => form.type === 'accreditation');
            commit('setAllForms', data.forms);
            commit('setCourseId', data.course_id);
            if (accreditationForm) {
              commit('setFormAccreditation', accreditationForm);
            }
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllFormsResult({ commit }, forms) {
    const result = [];
    commit('setUserResults', []);

    forms.forEach((form) => {
      const url = GET_EXAM_USER_RESPONSE.replace('{formId}', form.id);
      axios.get(url).then(({ data }) => {
        if (form.type === 'accreditation') {
          commit('setUserAnswers', {
            ...data,
            questions: cloneDeep(data.questions),
          });

          if (data.results) {
            commit('setUserResults', data.results);
          }
        }
        result.push({...data, id: form.id, type: form.type});
      });
    });
    commit('setAllFormsResult', result);
  },

  sendAnswer({ commit }, payload) {
    const { questionId, data } = payload;
    const answers = data.answers;

    const url = QUESTIONNAIRE_SEND_SINGLE_USER_ANSWERS.replace(
      '{question_id}',
      questionId,
    );
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(({ data }) => {
          commit('updateUserAnswers', { data, answers, questionId });
          commit('updateFormsResult', { data, answers, questionId });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetUserAnswerLastAttempt (_, { formId }) {
    const url = QUESTIONNAIRE_USER_RESET_LAST_ATTEMPT.replace('{form_id}', formId);

    return new Promise((resolve, reject) => {
      axios.post(url).then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
    });
  },

  getExamResults ({ commit }, streamId) {
    const url = GET_EXAM_RESULTS.replace('{stream_id}', streamId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          commit('setUserResults', data.results[0]);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendCertificateToUserEmail (_, formId) {
    const url = CERTIFICATE_EMAIL_SEND.replace('{form_id}', formId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  setUserAnswers(state, { questions, attempts_left }) {
    state.userAnswers = questions;
    if (attempts_left !== undefined) {
      state.formAccreditation.attempts_left = attempts_left;
    }
    state.userAnswers.forEach(el => {
      el.answered = !!el.answers.length;
      el.retry_answered = !!el.answers.length;
    });
  },
  setUserResults(state, value) {
    state.userResults = value;
  },
  setFormAccreditation(state, value) {
    state.formAccreditation = value;
    state.formAccreditation.attempts_left = state.formAccreditation.attempts_left ?? null;
  },
  setAllForms(state, value) {
    state.allForms = value;
  },
  updateUserAnswers(state, {data, answers, questionId}) {
    const answerIndex = state.userAnswers.findIndex(
      (answer) => answer.id === questionId,
    );
    state.userAnswers[answerIndex].is_correct = data.is_correct;
    state.userAnswers[answerIndex].attempts_left = state.userAnswers[answerIndex].attempts_left - 1;
    state.userAnswers[answerIndex].answers = answers;
    state.userAnswers[answerIndex].answered = true;

    state.userResults = data;
  },
  setAllFormsResult(state, value) {
    state.allFormsResult = value;
  },
  updateFormsResult(state, {data, answers, questionId}) {
    const formIndex = state.allFormsResult.findIndex(form => form.id === state.userResults.form_id);
    if (formIndex >= 0) {
      const answerIndex = state.allFormsResult[formIndex].questions.findIndex(
        (answer) => answer.id === questionId,
      );

      state.allFormsResult[formIndex].questions[answerIndex].is_correct = data.is_correct;
      state.allFormsResult[formIndex].questions[answerIndex].answers = answers;
      state.allFormsResult[formIndex].questions[answerIndex].attempts_left = state.allFormsResult[formIndex].questions[answerIndex].attempts_left - 1;
      state.allFormsResult[formIndex].questions[answerIndex].answered = true;

      if (state.allFormsResult[formIndex].results) {
        state.allFormsResult[formIndex].results = data;
      }
    }
  },
  setCourseId(state, value) {
    state.courseId = value;
  },
  updateFormAttempts (state, payload) {
    const { newAttempts } = payload;
    if (newAttempts !== undefined) {
      state.formAccreditation.attempts_left = newAttempts;
    } else {
      state.formAccreditation.attempts_left = (state.formAccreditation.attempts_left ?? 1) - 1;
    }
  },
  clearAccreditationAnswersOnRetry (state) {
    state.userAnswers.map((question) => {
      question.answers = [];
      question.answered = false;
      question.retry_answered = true;
      question.is_correct = false;
      question.attempts = 1;
      return question;
    });
  },
  setExamPassedStated(state, value) {
    state.examPassedState = value;
  },
  setExamSuccessPopUpState(state, value) {
    state.examPassedState = value;
    state.examPassedPopupShown = true;
  },
  resetStore(state) {
    state.formAccreditation = {};
    state.userAnswers = [];
    state.userResults = [];
    state.allForms = [];
    state.allFormsResult = [];
    state.examPassedState = false;
    state.examPassedPopupShown = false;
    state.setExamPassedStated = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
