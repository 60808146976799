import { getApiVersion } from '@/helpers/functions';

export const LOGIN_URL = getApiVersion() + '/login';
export const REGISTER_URL = getApiVersion() + '/register';
export const LOGOUT_URL = getApiVersion() + '/logout';
export const ME_URL = getApiVersion() + '/users/me';
export const GET_PROFILE_DETAILS_URL = getApiVersion() + '/profile/details';
export const GET_MOBILE_DETAILS_URL = getApiVersion() + '/mobile';
export const SEND_OTP_URL = getApiVersion() + '/otp/send';
export const VERIFY_OTP_URL = getApiVersion() + '/otp/verify';
export const PROFESSIONS_LIST_URL = getApiVersion() + '/professions';
// export const VERIFY_ACCOUNT_URL = getApiVersion() + '/profile/verify-account'; old api
export const VERIFY_ACCOUNT_URL = getApiVersion(2) + '/users/{user}/verify-account';
export const COMPLETE_PROFILE_URL = getApiVersion() + '/profile/complete-profile';
export const FORGOT_PASSWORD_URL = getApiVersion() + '/password/email';
export const FORGOT_EMAIL_URL = getApiVersion() + '/forgot-email';
export const CONTACT_FORM = getApiVersion() + '/contacts';
export const UPDATE_ADDRESS = getApiVersion() + '/podcast/profile/address';
export const UPDATE_PERSONALIZATION = getApiVersion() + '/podcast/profile/personal-data';
export const UPDATE_COMPANY = getApiVersion() + '/podcast/profile/company-details';
export const UPDATE_PROFILE_IMAGE = getApiVersion() + '/profile/media';
export const MEDIA_URL = getApiVersion() + '/media/{mediaId}';
export const STORE_PUSH_NOTIFICATIONS_TOKEN = getApiVersion() + '/podcast/push-token';
export const UPDATE_AUTH_USER_EMAIL = getApiVersion() + '/users/{user}/email/update';
export const VERIFY_PASSWORD_URL = getApiVersion() + '/users/password/verify';
export const SEND_VERIFICATION_EMAIL_URL = getApiVersion() + '/profile/send-verification-mail';
export const USER_PROFESSIONS = getApiVersion() + '/user-professions';
// export const PROFILE_BIGNUMBER_UPDATE = getApiVersion() + '/bignumber/update'; old api
export const PROFILE_BIGNUMBER_UPDATE = getApiVersion() + '/users/{user}/bignumber/update';
// export const PROFILE_BIGNUMBER_ADD = getApiVersion() + '/bignumber/add'; old api
export const PROFILE_BIGNUMBER_ADD = getApiVersion() + '/users/{user}/profile/bignumber';
export const PROFILE_BIGNUMBER_REMOVE = getApiVersion() + '/bignumber/remove/{profession_user_id}';
export const BIGNUMBER_URL = getApiVersion() + '/bignumber/{bignumber}';
export const PROFILE_LANGUAGE_CHANGE = getApiVersion() + '/profile/update-language';
export const CHANGE_PASSWORD = getApiVersion() + '/change-password';
export const PROFILE_REQUEST_ACCOUNT_DELETE_PIN = getApiVersion() + '/users/request-account-delete-pin';
export const PROFILE_ACCOUNT_DELETE_REQUEST_PIN = getApiVersion() + '/users/account-delete-request/pin';
export const PROFILE_DELETE_ACCOUNT = getApiVersion() + '/users/account-delete-request';
export const APP_VERSIONS = getApiVersion() + '/app-versions';
export const NEW_RELEASES = getApiVersion() + '/podcast/new-releases';
export const NEW_COURSE_RELEASES = getApiVersion() + '/podcast/new-courses';
export const GET_FEATURED_COURSES_URL = getApiVersion() + '/podcast/featured-courses';
export const COURSE_DETAILS = getApiVersion() + '/podcast/courses/{course_id}';
export const PUBLIC_COURSE_DETAILS = getApiVersion() + '/podcast/public/channels/{course_id}';
export const GET_RECENTLY_PLAYED_URL = getApiVersion() + '/podcast/recently-played';
export const GET_RECENTLY_PLAYED_PAGE_URL = getApiVersion() + '/podcast/recently-played/page';
export const PUBLIC_COURSE_IS_FAVORITE = getApiVersion() + '/courses/{course_id}/is-favourite';
export const USER_COURSES = getApiVersion() + '/users/{user_id}/courses';
export const DELETE_USER_COURSE = getApiVersion() + '/users/{user_id}/courses/{course_id}';
export const DELETE_USER_STREAM = getApiVersion() + '/users/{user_id}/courses/{course_id}/{stream_id}';
export const FAVOURITES_LIST = getApiVersion() + '/podcast/favourite-courses';
export const FOLLOWING_LIST = getApiVersion() + '/podcast/following-company-courses';
export const FOLLOW_COMPANY = getApiVersion() + '/companies/{company_id}/follow';
export const UNFOLLOW_COMPANY = getApiVersion() + '/companies/{company_id}/unfollow';
export const LIBRARY = getApiVersion() + '/podcast/library';
export const SEARCH_PODCAST = getApiVersion() + '/podcast/search'; // q=query&limit=50&offset=0
export const NOTIFICATIONS = getApiVersion() + '/podcast/push-preferences';
export const INVITE_TOKEN_VERIFY = getApiVersion() + '/podcast/validate-invite-token';
export const INVITE_TOKEN_STATE = getApiVersion() + '/podcast/invite-token-enabled';
export const GET_INVITE_TOKEN = getApiVersion() + '/podcast/invite-token';
export const TRACKING_URL = getApiVersion() + '/trackings';
export const COMPANY_DETAILS = getApiVersion() + '/podcast/companies/{company_id}';
export const COMPANY_POPULAR = getApiVersion() + '/podcast/companies/{company_id}/popular';
export const COMPANY_RELEASES = getApiVersion() + '/podcast/companies/{company_id}/new-releases';
export const COMPANY_LIBRARY = getApiVersion() + '/podcast/companies/{company_id}/library';
export const GET_POPULAR = getApiVersion() + '/podcast/popular'; // query: filter - {weekly|monthly}
export const GET_POPULAR_CHANNELS = getApiVersion() + '/podcast/popular/channels';
export const VERIFY_PASSWORD_2FA_URL = getApiVersion() + '/user/2fa/password/verify';
export const VERIFY_TWO_FA = getApiVersion() + '/2fa/verify';
export const GET_PROFILE_TWO_FA_QR_IMAGE = getApiVersion() + '/user/2fa/qr-code';
export const DISABLE_2FA = getApiVersion() + '/user/2fa/disable';
export const GET_TWO_FA_QR_IMAGE = getApiVersion() + '/2fa/qr-code';
export const STREAM_USER_PROGRESS = getApiVersion() + '/users/{user_id}/streams/{stream_id}/progress';
export const GET_STREAM = getApiVersion() + '/podcast/streams/{stream_id}?attach=forms.questions.displayMoments,forms.questions.answers';
export const GET_EXAM_USER_RESPONSE = getApiVersion() + '/podcast/questionnaire/{formId}/user-response';
export const GET_EXAM_USER_RESPONSE_temp_np = getApiVersion() + '/questionnaire/{formId}/user-response';
export const QUESTIONNAIRE_UPDATE_ACCREDITATION_DATA = getApiVersion() + '/podcast/user-question-answers';
export const QUESTIONNAIRE_SEND_SINGLE_USER_ANSWERS = getApiVersion() + '/podcast/user-question-answers/{question_id}';
export const GET_COURSE_ACCREDITATION_PROFESSIONS = getApiVersion() + '/courses/{course_id}/accreditation-professions';
export const VERIFY_EMAIL = getApiVersion() + '/verify-email';
export const CERTIFICATE_EMAIL_SEND = getApiVersion() + '/forms/{form_id}/certificate/send-mail';
export const USER_SUBSCRIBED_COURSES = getApiVersion() + '/podcast/subscribed-courses';
export const GET_APP_CONFIG = getApiVersion() + '/podcast/config';
export const NEW_PODCAST_RELEASES = getApiVersion() + '/podcast/new-podcast-releases';
export const RECOMMENDED_PODCASTS = getApiVersion() + '/podcast/recommended';
export const QUESTIONNAIRE_USER_RESET_LAST_ATTEMPT = getApiVersion() + '/user-question-answers/{form_id}/reset-last-attempt';
export const GET_EXAM_RESULTS = getApiVersion() + '/streams/{stream_id}/questionnaire-results';
