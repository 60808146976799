import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
/* eslint-disable */
let sentryErrorPrefix = '';
Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
  promises.map(p => p
    .then(value => ({
      status: "fulfilled",
      value,
    }))
    .catch(reason => ({
      status: "rejected",
      reason,
    })),
  ),
));
Promise.allSettled([Device.getInfo(), App.getInfo()]).then(([deviceResult, appResult]) => {
  const deviceInfo = deviceResult.value ?? {};
  const appInfo = appResult.value ?? {};
  const pattern = '[{platform}{version}] ';
  const platform = deviceInfo.platform ?? '';
  const version = appInfo.version ? ` v.${appInfo.version}`: '';
  sentryErrorPrefix = pattern.replace('{platform}',platform).replace('{version}', version);
});

const config = {
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,
  tracingOptions: {
    trackComponents: true,
  },
  // Vue specific
  logErrors: true,
  attachProps: true,
  attachStacktrace: true,
};

const isIonSlideWarning = (breadcrumb) => {
  return (breadcrumb?.category === 'console' &&
          breadcrumb?.message?.startsWith('[Deprecation Warning]: ion-slides has been'));
};

export default function (app, router) {
  config.app = app;

  config.beforeSend = (e, hint) => {
    if (e && e.breadcrumbs) {
      e.breadcrumbs = e.breadcrumbs.filter(breadcrumbs => {
        return !isIonSlideWarning(breadcrumbs);
      });
    }

    if (e && e.level === 'error') {
      if (e?.exception?.values?.[0]?.type) {
        e.exception.values[0].type = sentryErrorPrefix + e.exception.values[0].type;
      }
    }

    if (e && e.level === 'info') {
      if (e?.message) {
        e.message = sentryErrorPrefix + e.message;
      }
    }

    return e;
  };

  config.integrations = [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ];
  Sentry.init(config);
}
