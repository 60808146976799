import axios from '@/http';
import {
  COURSE_DETAILS,
  PUBLIC_COURSE_IS_FAVORITE,
  USER_COURSES,
  DELETE_USER_COURSE,
  DELETE_USER_STREAM,
  PUBLIC_COURSE_DETAILS,
  GET_COURSE_ACCREDITATION_PROFESSIONS,
} from '@/config/api';

// import store from '@/state/store';

const initializeStates = () => {
  return {
    courseData: {
      id: 0,
      company_id: 0,
      course_title: '',
      company_name: '',
      episodes: 0,
      media: [],
      company: {
        id: 0,
        user_follow_count: 0,
        media: [],
      },
      streams: [],
    },
    courseTrackingEnabled: true,
    validAccreditationProfessions: [],
  };
};

export const state = () => initializeStates();

export const actions = {
  setCourseData({ commit }, courseId) {
    return new Promise((resolve, reject) => {
      const url = COURSE_DETAILS.replace('{course_id}', courseId);
      axios
        .get(url)
        .then(({ data }) => {
          if (!data.streams || !data.company) {
            reject();
            return;
          }

          commit('setCourseData', data);
          commit('favorites/setFavoritesCoursesIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          commit('favorites/setFavoritesStreamsIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          commit('favorites/setfollowedCompaniesIds', data.following_company_ids, {root: true});
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setPublicCourseData({ commit }, courseId) {
    return new Promise((resolve, reject) => {
      const url = PUBLIC_COURSE_DETAILS.replace('{course_id}', courseId);
      axios
        .get(url)
        .then(({ data }) => {
          if (!data.streams || !data.company) {
            reject();
            return;
          }
          commit('setCourseData', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  favoriteStream( { commit }, { user_id, course_id, stream_id }) {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_COURSES.replace('{user_id}', user_id), { course_id, stream_id })
        .then(() => {
          commit('favorites/addFavoriteStreamsId', stream_id, {root: true});
          commit('favorites/setFavoritesUpdated', true, {root: true});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  favoriteCourse( { commit }, { user_id, course_id }) {
    return new Promise((resolve, reject) => {
      axios
        .post(USER_COURSES.replace('{user_id}', user_id), { course_id })
        .then(() => {
          commit('favorites/addFavoritesCourseId', course_id, {root: true});
          commit('favorites/setFavoritesUpdated', true, {root: true});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unfavoriteCourse({ commit }, { user_id, course_id }) {
    const url = DELETE_USER_COURSE
      .replace('{user_id}', user_id)
      .replace('{course_id}', course_id);

    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then(() => {
          commit('favorites/deleteFavoritesCourseId', course_id, {root: true});
          commit('favorites/setFavoritesUpdated', true, {root: true});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unfavoriteStream({ commit }, { user_id, course_id, stream_id }) {
    const url = DELETE_USER_STREAM
      .replace('{user_id}', user_id)
      .replace('{course_id}', course_id)
      .replace('{stream_id}', stream_id);

    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then(() => {
          commit('favorites/deleteFavoriteStreamsId', stream_id, {root: true});
          commit('favorites/setFavoritesUpdated', true, {root: true});
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getValidAccreditationProfessions ({ commit }, courseId) {
    const url = GET_COURSE_ACCREDITATION_PROFESSIONS.replace('{course_id}', courseId);
    return new Promise((resolve, reject) => {
      axios.get(url).then(({ data }) => {
        commit('setValidAccreditationProfessions', data.professions);
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};

export const mutations = {
  setCourseData(state, data) {
    state.courseData = data;
  },

  setCourseTrackingState(state, data) {
    state.courseTrackingEnabled = data;
  },
  refreshCourseData(state) {
    state.courseData = {
      id: 0,
      company_id: 0,
      course_title: '',
      company_name: '',
      episodes: 0,
      media: [],
      company: {
        id: 0,
        user_follow_count: 0,
        media: [],
      },
      streams: [],
    };
  },
  setValidAccreditationProfessions (state, data) {
    state.validAccreditationProfessions = data;
  },
};

export const getters = {
  getCourseData: (state) => state.courseData,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
