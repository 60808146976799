<template>
  <div
    class="single-podcast pointer has-edit-button"
    @click="openPodcast"
  >
    <ion-card
      color="medium"
      class="single-podcast-card is-flex"
    >
      <EditButton
        v-if="canEdit"
        :id="podcastData.id"
        :url="`streams/${podcastData.id}/edit`"
      />
      <ion-grid>
        <ion-row class="h-100">
          <ion-col size="5">
            <div class="img-wrap">
              <AppImg
                :img-src="imageUrl"
                img-alt="recently played podcast image"
                border-radius="10px"
              />
            </div>
          </ion-col>
          <ion-col size="7">
            <div class="card-content h-100">
              <ion-card-header class="single-podcast-card-header">
                <p class="title fz-12 fw-500 mb-2 overflow-text-hidden">
                  {{ podcastData.stream_title }}
                </p>
                <p class="fz-12 fw-300 is-displaying-two-lines-text">
                  {{ podcastData.course_title }}
                </p>
              </ion-card-header>
              <ion-card-content class="single-podcast-card-content is-flex">
                <PlayerReproduction
                  :duration="podcastData.duration"
                  :current-time="getCurrentTime"
                />
              </ion-card-content>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</template>

<script>
import PlayerReproduction from '@/components/common/PlayerReproduction.vue';
import AppImg from '@/components/common/AppImg.vue';
import EditButton from '@/components/common/EditButton.vue';

import {
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonGrid,
  IonCol,
  IonRow,
  IonSpinner,
} from '@ionic/vue';
import { computed } from '@vue/reactivity';
import {
  getSmallestSizeImage,
  getSecondsFromHMSString, getPlayerLatestPosition, getLatestTrackPositionByTimestamp,
} from '@/helpers/functions';
import { useStore } from 'vuex';

import { CATEGORY_GENERAL_MEDIA, CATEGORY_BANNER_MEDIA, CATEGORY_STREAM_STILL, CATEGORY_PODCAST_IMG } from '@/config/constants';

const PRIOR_IMAGES = [
  CATEGORY_PODCAST_IMG,
  CATEGORY_STREAM_STILL,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_GENERAL_MEDIA,
];

export default {
  name: 'RecentlySinglePodcast',
  components: {
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonImg,
    IonGrid,
    IonCol,
    IonRow,
    IonSpinner,
    PlayerReproduction,
    AppImg,
    EditButton,
  },
  props: {
    podcastData: {
      type: Object,
      required: true,
    },
    streamUserProgress: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const canEdit = computed(() => {
      const hasPermission = store.getters['authentication/hasPermission']('update_stream');
      const isWeb = store.state.app.platform === 'web';
      return hasPermission && isWeb;
    });

    const imageUrl = computed(() => {
      const validStreamImages = props.podcastData.media.filter(media => PRIOR_IMAGES.includes(media.category));
      validStreamImages.sort((a,b) => b.category - a.category);
      const streamImg = getSmallestSizeImage(validStreamImages)?.url ?? null;
      const courseImg = getSmallestSizeImage(props.podcastData.course_media)?.url ?? null;
      return streamImg || courseImg;
    });

    const openPlayer = (id, course) => {
      const startAt = course.streams.findIndex((stream) => stream.id === id);
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
      });
    };

    const openPodcast = () => {
      store
        .dispatch('course/setCourseData', props.podcastData.course_id)
        .then((course) => {
          openPlayer(props.podcastData.id, course);
        });
    };

    const getCurrentTime = computed(() => {
      const storageData = store.state.player.recentlyPlayedProgress[props.podcastData.id];

      let userData = props.podcastData;

      if (props.streamUserProgress) {
        userData = props.podcastData?.stream_user?.[0] ?? props.podcastData;
      }

      const time =  getLatestTrackPositionByTimestamp(userData, storageData) ?? '00:00:00';

      //  console.log('time recalculated for stream', time);

      return getSecondsFromHMSString(time);
    });

    return {
      imageUrl,
      getCurrentTime,
      openPodcast,
      canEdit,
    };
  },
};
</script>

<style scoped lang="scss">
.single-podcast {
  width: 310px;
}

.single-podcast-card {
  margin-left: auto;
  margin-right: auto;
  height: 130px;
  margin: 10px 0 24px;
  border-radius: 10px;

  .img-wrap {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.single-podcast-card-header {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  text-align: left;
}
.single-podcast-card-content {
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;

  .series-quantity p {
    font-size: 12px;
  }
}
.podcast-social {
  /*column-gap: 10px;*/
}
.overflow-text-hidden {
  overflow: hidden;
  line-height: 1.5em;
  max-height: 1.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ion-grid {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
  width: 100%;
}
ion-img {
  object-fit: cover;
  height: 100%;
}
</style>
