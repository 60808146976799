import { createStore, createLogger } from 'vuex';
import authentication from './modules/authentication';
import profile from './modules/profile';
import mobile from './modules/mobile';
import app from './modules/app';
import player from './modules/player';
import newReleases from './modules/newReleases';
import featuredCourses from './modules/featuredCourses';
import recentlyPlayed from './modules/recentlyPlayed';
import course from './modules/course';
import favorites from './modules/favorites';
import library from './modules/library';
import search from "./modules/search";
import company from "./modules/company";
import questionnaires from "./modules/questionnaires";
import accreditation from "./modules/accreditation";

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    authentication,
    profile,
    mobile,
    app,
    player,
    featuredCourses,
    recentlyPlayed,
    newReleases,
    course,
    favorites,
    library,
    search,
    company,
    questionnaires,
    accreditation,
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : [],
});
