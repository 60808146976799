import { Preferences } from '@capacitor/preferences';
import {CAP2_MIGRATED_STORAGE_KEY, IS_INVITE_CARD_CLOSED_FIRST} from "@/config/constants";
let migratePromise = null;

export default () => {
  const setKey = async (key, value) => {
    if (migratePromise) { await migratePromise; }
    return await Preferences.set({
      key,
      value,
    });
  };

  const getKey = async (key) => {
    if (migratePromise) { await migratePromise; }
    const { value } = await Preferences.get({ key });
    return value;
  };

  const getKeys = async () => {
    if (migratePromise) { await migratePromise; }
    return await Preferences.keys();
  };

  const removeKey = async (key) => {
    if (migratePromise) { await migratePromise; }
    return await Preferences.remove({ key });
  };

  const setKeyWithExpire = (key, value, ttl) => {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    return setKey(key, JSON.stringify(item));
  };

  const getKeyWithExpire = async (key) => {
    const item = await getKey(key);
    if (!item) {
      return null;
    }
    const parsedItem = JSON.parse(item);
    const now = new Date();

    if (now.getTime() > parsedItem.expiry) {
      await removeKey(key);
      return null;
    }
    return parsedItem.value;
  };

  const migrateFromCapacitor2 = async () => {
    // eslint-disable-next-line no-async-promise-executor
    migratePromise = new Promise (async resolve => {
      try {
        const wasMigrated = await getKey(CAP2_MIGRATED_STORAGE_KEY);
        if (wasMigrated) {
          resolve();
          return;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}


      try {
        await Preferences.migrate();
        await Preferences.removeOld();
        // eslint-disable-next-line no-empty
      } catch (e) {}
      resolve();
    });

    migratePromise.then(() => {
      setKey(CAP2_MIGRATED_STORAGE_KEY, 1);
    });
  };

  return {
    setKey,
    getKey,
    getKeys,
    removeKey,
    setKeyWithExpire,
    getKeyWithExpire,
    migrateFromCapacitor2,
  };
};
