<template>
  <div v-if="following.length" >
    <div class="is-flex ion-justify-content-between ion-align-items-center">
      <h1 class="">{{ $t('home.following') }}</h1>
      <ion-button
        fill="clear"
        color="light"
        class="fz-12 fw-300"
        router-link="/main/following?tab=following"
      >{{ $t('home.view') }}</ion-button>
    </div>

    <div class="is-flex follow-company-wrap">
      <FollowingCompany
        v-for="(data, i) in filteredFollowing"
        :key="`following-${i}`"
        class="podcast-item"
        :following-company="data"
      />
    </div>

  </div>
</template>

<script>
import FollowingCompany from '@/components/home/FollowingCompany.vue';
import { IonSlide, IonSlides, IonButton } from "@ionic/vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";

export default {
  name: "HomeFollowing",

  components: {
    FollowingCompany,
    IonSlides,
    IonSlide,
    IonButton,
  },


  setup () {
    const store = useStore();

    const following = computed(() => store.getters['favorites/getFollowingList']);
    const filteredFollowing = computed(() => {
      return following.value.slice(0, 4);
    });

    return {
      following,
      filteredFollowing,
    };
  },
};
</script>

<style scoped lang="scss">
  ion-slide {
    max-width: 172px !important;
  }
  .follow-company-wrap {
    flex-wrap: wrap;
  }
  .podcast-item {
    width: 50%;
  }
</style>
