<template>
  <section v-if="popularData.length">
    <h1 v-if="title">
      {{ title }}
    </h1>
    <ion-slides
      :options="slideOptions"
      :key="sliderKey"
    >
      <ion-slide
        v-for="data in popularData"
        :key="data.id"
      >
        <RecentlySinglePodcast
          :podcast-data="formattedPodcastData(data)"
          stream-user-progress
          :key="`${data.id}-popular`"
        />
      </ion-slide>
    </ion-slides>
  </section>
</template>

<script>
import { IonSlides, IonSlide } from '@ionic/vue';
import RecentlySinglePodcast from '@/components/common/RecentlySinglePodcast.vue';
import { ref } from '@vue/reactivity';
import { watch } from "@vue/runtime-core";


export default {
  name: 'PopularStreamsCarousel',
  components: {
    IonSlides,
    IonSlide,
    RecentlySinglePodcast,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    popularData: {
      type: Array,
      default: () => ([]),
    },
  },

  setup(props) {
    const sliderKey = ref(Math.random());

    const slideOptions = {
      slidesPerView: 'auto',
    };

    watch(() => [...props.popularData], () => {
      sliderKey.value = Math.random();
    } );

    const formattedPodcastData = (data) => Object.assign({}, data, { course_media: data.course.media });

    return { slideOptions, sliderKey, formattedPodcastData };
  },
};
</script>

<style scoped lang="scss">
  ion-slide {
    max-width: 310px !important;
    margin-right: 14px;
  }
</style>
