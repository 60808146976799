<template>
  <transition name="slide-player" >
    <div
      :style="{ transform: `translate(0, ${translateY}px)` }"
      v-if="playerActive"
      class="player-wrapper"
      :class="{'transform-up': transformUp}"
    >

      <transition-group
        name="slide-player"
        mode="out-in"
      >
        <ion-content
          v-show="!playerCollapsed"
          class="player-layout top-safe-space"
          :key="1"
        >
          <EditButton
            v-if="canEdit"
            :url="`streams/${streamId}/edit`"
            always-shown
          />
          <div class="is-flex is-flex-direction-column h-100 tablet-centered">
            <div>
              <div
                v-if="!isLogged"
                class="public-block is-flex ion-justify-content-between ion-padding  tablet-centered"
              >
                <AppImg
                  is-transparent
                  img-src="./assets/medicast-logo.png"
                  style="height: 50px; width: 50px"
                />
                <div class="is-flex">
                  <ion-button
                    router-link="/login"
                    fill="solid"
                    mode="ios"
                    shape="round"
                    expand="full"
                    class="fw-500 fz-14 uppercase ion-margin-end"
                  >
                    {{ $t('commonKeys.login') }}
                  </ion-button>
                  <ion-button
                    router-link="/sign-up"
                    fill="outline"
                    mode="ios"
                    shape="round"
                    expand="full"
                    class="fw-500 fz-14 uppercase"
                  >
                    {{ $t('commonKeys.register') }}
                  </ion-button>
                </div>
              </div>
              <div
                class="is-flex ion-justify-content-between ion-align-items-start ion-padding-horizontal ion-padding-top"
                @touchstart="swipeStart"
                @touchmove="swiping"
                @touchend="swipeOff"
              >
                <CompanyDetails
                  :key="companyInfo.id"
                  :company-details="companyInfo"
                  style="flex: 1"
                  class="translate-x-30"
                />
                <IonIcon
                  class="pointer"
                  :icon="chevronDown"
                  size="large"
                  @click="$store.commit('player/setPlayerCollapse', true)"
                />
              </div>
            </div>
            <div
              class="ion-padding-horizontal"
              style="overflow-y: auto;flex: 1 auto;"
            >
              <PodcastInfo
                :key="currentEpisode.id"
                :podcast-info="currentEpisode"
                :course-media="currentCourse.media"
                :stream-duration="streamDuration"
              />
            </div>

            <div
              class="ion-padding-horizontal ion-padding-bottom"
              style="color: white; margin-top: auto"
            >

              <AccreditationRequirements />

              <AudioPlayer
                ref="player"
                autoplay
                :playlist="playerPlaylist"
                :playlist-start-index="playerPlaylistStartAt"
                :seek-position="currentSeekPosition"
                :course="currentCourse"
                :skip-autoplay-id="skipAutoplayId"
                :progress="progressHistory"
                :scrubbing-disabled="!!currentEpisode.disable_scrubbing"
                @seeked="initTrackingInterval"
                @track-ready="startTracking"
                @duration="streamDuration = $event"
                @autoplay-skipped="$store.commit('player/setSkipAutoplayId', null)"
              />
            </div>
          </div>
        </ion-content>


        <div
          v-show="playerCollapsed"
          :key="2"
          class="player-fixed pointer"
          :class="{ 'player-fixed-bottom': !hasPaddingBottom }"
          @click="$store.commit('player/setPlayerCollapse', false)"
        >
          <MiniPlayer
            :title="currentEpisode.title"
            :subtitle="currentEpisode.description"
            :duration="trackInfo.trackDuration || currentEpisode.duration"
            :current-time="trackInfo.trackCurrentTime"
            :is-playing="trackInfo.trackPlaying"
            :podcast-media="currentEpisode.media"
            :course-media="currentCourse.media"
            :closable="isMiniplayerClosable"
            @close="$store.dispatch('player/closePlayer')"
            @toggle-play.stop="$refs.player.togglePlay()"
            @pause="$refs.player.pause()"
          />
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script>

import { IonContent, IonButton, IonIcon, IonImg } from '@ionic/vue';
import PlayerReproduction from "@/components/common/PlayerReproduction";
import AudioPlayer from "@/components/player/AudioPlayer";
import CompanyDetails from "@/components/common/CompanyDetails";
import AccreditationRequirements from "@/components/common/AccreditationRequirements";
import { chevronDown } from 'ionicons/icons';
import MiniPlayer from "@/components/player/MiniPlayer";
import AppImg from '@/components/common/AppImg.vue';

import { close } from 'ionicons/icons';
import PodcastInfo from "@/components/common/PodcastInfo";

import streamTrackingMixin from "@/mixins/streamTrackingMixin";
import EditButton from '@/components/common/EditButton.vue';
import debounce from 'lodash/debounce';

import { BackgroundMode } from '@awesome-cordova-plugins/background-mode';


export default {
  name: "PlayerOverlay",

  components: {
    PodcastInfo,
    MiniPlayer,
    CompanyDetails,
    IonContent,
    IonIcon,
    IonButton,
    AudioPlayer,
    PlayerReproduction,
    IonImg,
    AccreditationRequirements,
    AppImg,
    EditButton,
  },

  mixins: [streamTrackingMixin],

  data() {
    return {
      collapsed: false,
      swipeY: 0,
      swipeStartY: 0,
      transformUp: false,

      chevronDown,
      close,
      streamDuration: null,
    };
  },

  computed: {
    canEdit (){
      return this.$store.getters['authentication/canEdit'];
    },
    playerActive (){
      return this.$store.state.player.playerActive;
    },
    playerCollapsed (){
      return this.$store.state.player.playerCollapsed;
    },
    skipAutoplayId (){
      return this.$store.state.player.streamSkipAutoplay;
    },
    playerPlaylist (){
      return this.$store.state.player.playlist;
    },
    playerPlaylistStartAt (){
      return this.$store.state.player.playlistTrackStart;
    },
    trackInfo () {
      return this.$store.state.player.trackInfo;
    },
    companyInfo () {
      return this.$store.state.player.currentCompany;
    },
    currentEpisode() {
      return this.$store.getters['player/getCurrentEpisode'] ?? {};
    },

    currentCourse() {
      return this.$store.state.player.currentCourse ?? { media: [] };
    },

    getPlayerPlayPauseTriggers() {
      return this.$store.state.player.playerPlayPauseTriggers;
    },

    hasPaddingBottom() {
      return this.$route.name !== 'PodcastSeries'
        && this.$route.name !== 'Contact'
        && this.$route.name !== 'Company'
        && this.$route.name !== 'PublicPodcastSeries'
        && this.$route.name !== 'AccreditationQuestions'
        && this.$route.name !== 'ContinueWatching';
    },
    translateY() {
      return this.swipeY - this.swipeStartY;
    },
    isLogged() {
      return this.$store.getters['authentication/isLogged'];
    },
    isMiniplayerClosable() {
      return this.$route.name !== 'AccreditationQuestions';
    },
  },

  created() {
    this.clearExpiredStorageProgress();
  },

  methods: {
    swipeStart(e) {
      this.swipeStartY = e.changedTouches[0].clientY;
      this.swipeY = e.changedTouches[0].clientY;
    },
    swiping(e) {
      this.transformUp = false;
      this.swipeY = e.touches[0].clientY;
    },
    swipeOff() {
      if (this.translateY > 100) {
        this.$store.commit('player/setPlayerCollapse', true);
        setTimeout(() => {
          this.swipeStartY = 0;
          this.swipeY = 0;
        }, 300);
      } else {
        this.transformUp = true;
        this.swipeY = this.swipeStartY;
      }
    },

    handleBackgroundMode: debounce(function (isPlaying) {
      const isAndroid = this.$store.state.app.platform === 'android';
      if (!isAndroid) {
        return;
      }
      if (isPlaying) {
        BackgroundMode.enable();
      } else {
        BackgroundMode.disable();
      }

    }, 1000),

  },

  watch: {
    playerActive() {
      if (!this.playerActive) {
        this.clearTrackingInterval();
      }
    },
    progressHistory () {
      this.$store.commit('player/setProgressHistory', this.progressHistory);
    },

    'trackInfo.trackPlaying' (isPlaying) {
      this.handleBackgroundMode(isPlaying);
    },

    'getPlayerPlayPauseTriggers.pause': {
      handler() {
        this.$refs.player.pause();
      },
      deep: true,
    },
    'getPlayerPlayPauseTriggers.play': {
      handler() {
        this.$refs.player.play();
      },
      deep: true,
    },
  },

};
</script>

<style scoped lang="scss">
  .player-wrapper {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 6;
    pointer-events: none;

    * {
      pointer-events: all;
    }
  }
  .transform-up {
    transition: transform .3s;
  }


  .player-layout {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 24px;
    background-color: rgb(0, 0, 0);
    background-image: linear-gradient(rgba(96, 24, 24, 0.6), rgb(0, 0, 0) 85%);
    /*opacity: 1;*/
    /*animation: 0.2s cubic-bezier(0.3, 0, 0, 1) 0.2s 1 normal forwards running transIn;*/
  }

  .player-fixed {
    position: fixed;
    bottom: calc(51px + var( --safe-area-inset-bottom)) !important;
    opacity: 1;
    left: 0px;
    /*height: 55px;*/
    /*-webkit-box-align: center;*/
    /*align-items: center;*/
    display: block;
    /*flex-direction: column;*/
    width: 100%;
    /*-webkit-box-pack: center;*/
    /*justify-content: center;*/
    color: white;
    padding: 0px;
    /*background-color: #2E2E2E;*/

    &-bottom{
      bottom: var( --safe-area-inset-bottom) !important;
    }
  }


  .collapse-icon {
    font-size: 24px;
  }

  .public-block {
    background: var(--grey);
  }
</style>

