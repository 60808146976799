<template>
  <div
    class="wrapper is-relative"
    :class="{'transform': isTransform}"
    :style="{ transform: `translate(${transformX}px, 0px)` }"
    @touchmove="swipeChange"
    @touchend="swipeOff"
  >

    <ion-fab
      v-if="closable && platform === 'web'"
      slot="fixed"
    >
      <ion-fab-button @click.stop="$store.dispatch('player/closePlayer')">
        <ion-icon
          :icon="close"
          size="small"
        />
      </ion-fab-button>
    </ion-fab>
    <EpisodePreview
      :img-src="banner"
      :title="title"
      :subtitle="subtitle"
    >
      <template #end>
        <IonIcon
          class="pointer"
          style="min-width: 50px;"
          :icon="isPlaying ? pauseCircle : playCircle"
          size="large"
          @click="$emit('toggle-play', $event)"
        />
      </template>
    </EpisodePreview>
    <PlayerReproduction
      :duration="duration"
      :current-time="currentTime"
      :is-playing="isPlaying"
      :has-play="false"
      :has-close="false"
      :has-left-hint="false"
      @close="$emit('close')"
      @toggle-play="$emit('toggle-play')"
    />
  </div>
</template>

<script>
import PlayerReproduction from '@/components/common/PlayerReproduction';
import { IonIcon, IonFab, IonFabButton } from '@ionic/vue';
import { playCircle, pauseCircle, close } from 'ionicons/icons';
import { computed, ref, toRefs } from '@vue/reactivity';
import { useStore } from 'vuex';
import { getSmallestSizeImage } from "@/helpers/functions";
import { CATEGORY_PODCAST_AUDIO } from "@/config/constants";
import AppImg from '@/components/common/AppImg.vue';
import EpisodePreview from "@/components/player/EpisodePreview";

export default {
  name: 'MiniPlayer',
  components: {
    EpisodePreview,
    PlayerReproduction,
    IonIcon,
    AppImg,
    IonFab,
    IonFabButton,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    duration: {
      type: Number,
      default: null,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    currentTime: {
      type: Number,
      default: null,
    },
    podcastMedia: {
      type: Array,
      default() {
        return [];
      },
    },
    courseMedia: {
      type: Array,
      default() {
        return [];
      },
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { podcastMedia } = toRefs(props);

    const isSwiping = ref(false);
    const isTransform = ref(false);
    const swipeStartX = ref(0);
    const swipeX = ref(0);

    const platform = store.state.app.platform;
    const transformX = computed(() => swipeX.value - swipeStartX.value);

    const banner = computed(() => {
      const media = getSmallestSizeImage(podcastMedia.value.filter(m => m.category !== CATEGORY_PODCAST_AUDIO))?.url ?? null;
      const mediaFallback = getSmallestSizeImage(props.courseMedia)?.url ?? null;
      return media || mediaFallback;
    });

    const swipeChange = (e) => {
      if (props.closable) {
        isTransform.value = false;
        if (!isSwiping.value) {
          swipeStartX.value = e.touches[0].clientX;
          isSwiping.value = true;
        }
        swipeX.value = e.touches[0].clientX;
      }
    };

    const swipeOff = () => {
      if (props.closable) {
        if (transformX.value > 100 || transformX.value < -100) {
          transformX.value > 100 ? swipeX.value = 1000 : swipeX.value = -1000;
          setTimeout(() => {
            emit('pause');
            store.dispatch('player/closePlayer');
          }, 350);
        } else {
          swipeX.value = swipeStartX.value;
        }
        isTransform.value = true;
        isSwiping.value = false;
      }
    };

    return {
      playCircle,
      pauseCircle,
      close,
      swipeX,
      swipeStartX,
      swipeOff,
      transformX,
      swipeChange,
      isTransform,
      banner,
      platform,
    };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  background: #2a0303;
  padding: 8px;
}
.transform {
  transition: transform .35s;
}

ion-fab {
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
}

ion-fab-button {
  width: 26px;
  height: 26px;
}
</style>
