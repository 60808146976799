<template>
  <div
    v-if="isOpen"
    class="toast-wrap"
  >
    <div class="toast">
      <div class="is-flex">
        <div
          v-if="notifications.data.image"
          class="img-wrap"
        >
          <AppImg
            :img-src="notifications.data.image"
            border-radius="6px"
          />
        </div>
        <div class="description pl-3">
          <p class="title">{{ notifications.title }}</p>
          <p class="body">{{ notifications.body }}</p>
        </div>
      </div>

      <div class="buttons-wrap is-flex ion-justify-content-between">
        <ion-button
          size="small"
          fill="clear"
          @click="open"
        >
          {{ $t('commonKeys.open') }}
        </ion-button>
        <ion-button
          size="small"
          fill="clear"
          color="light"
          @click="close"
        >
          {{ $t('commonKeys.close') }}
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, IonContent } from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import { useStore } from 'vuex';
import { computed, ref } from '@vue/reactivity';
import usePushNotifications from '@/composables/usePushNotifications';
import { watch } from '@vue/runtime-core';

export default {
  name: 'AppNotification',
  components: {
    IonButton,
    IonContent,
    AppImg,
  },
  setup() {
    const store = useStore();
    const { redirectOnPushAction } = usePushNotifications();

    const notifications = computed(() => store.state.app.pushNotification);
    const isOpen = computed(() => store.state.app.isNotificationOpen);

    const close = () => store.commit('app/closeNotification');
    const open = () => {
      redirectOnPushAction(notifications.value);
      close();
    };

    return {
      notifications,
      isOpen,
      redirectOnPushAction,
      close,
      open,
    };
  },
};
</script>

<style lang="scss" scoped>
.toast-wrap {
  position: fixed;
  top: 0;
  padding: 5px;
  width: 100%;
  z-index: 100;
  color: var(--white);

  .toast {
    background: var(--ion-color-medium);
    padding: 10px 16px 0;
    border-radius: 6px;
  }
  .description {
    width: calc(100% - 34px);

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .img-wrap {
    width: 34px;
    height: 34px;
  }
  ion-button {
    --padding-start: 0;
    --padding-end: 0;
  }
}
</style>
