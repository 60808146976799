<template>
  <div>
    <ion-label position="stacked">{{ label }}</ion-label>
    <ion-select
      class="ion-text-capitalize"
      mode="ios"
      interface="action-sheet"
      :placeholder="placeholder"
      :value="modelValue"
      color="medium"
      :style="[appSelectStyle]"
      @ionChange="onChange"
    >
      <ion-select-option
        class="ion-text-capitalize"
        :class="{'app-option-disabled': disabledIds.includes(item.id)}"
        v-for="item in items"
        :key="item.id"
        :value="item.id"
      >
        {{ item[itemName] }}
      </ion-select-option>
    </ion-select>
    <AppErrorField :errors="errors" />
  </div>
</template>

<script>
import { IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import AppErrorField from "@/components/common/AppErrorField";

export default defineComponent ({
  name: "AppSelect",
  components: {
    AppErrorField,
    IonLabel,
    IonSelect,
    IonSelectOption,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    disabledIds: {
      type: Array,
      default: () => ([]),
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    itemName: {
      type: String,
      default: 'name',
    },
    background: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: String,
      default: '',
    },
    appSelectStyle: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['update:modelValue'],
  setup (_, { emit }) {
    const onChange = (e) => {
      emit('update:modelValue', e.target.value);
    };

    return {
      onChange,
    };
  },

});
</script>

<style lang="scss">
  .app-option-disabled {
    user-select: none;
    pointer-events: none;
    color: var(--ion-color-medium-shade) !important;
  }
  ion-label {
    white-space: normal !important;
    text-overflow: unset !important;
  }
</style>
