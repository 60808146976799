<template>
  <section v-if="popular.length">
    <h1>
      {{ $t('home.topCoursesTitle') }}
    </h1>
    <ion-slides
      :options="slideOptions"
      :key="sliderKey"
    >
      <ion-slide
        v-for="data in popular"
        :key="data.id"
      >
        <RegularCourseCard
          :course-data="data"
          :key="`${data.id}-popular`"
          @click="$router.push(`/course/${data.id}`)"
        />
      </ion-slide>
    </ion-slides>
  </section>
</template>

<script>
import { IonSlides, IonSlide } from '@ionic/vue';
import RegularCourseCard from '@/components/common/RegularCourseCard';
import { ref, computed } from '@vue/reactivity';
import { watch } from "@vue/runtime-core";
import {useStore} from "vuex";


export default {
  name: 'PopularCoursesCarousel',
  components: {
    IonSlides,
    IonSlide,
    RegularCourseCard,
  },

  setup() {
    const sliderKey = ref(Math.random());
    const store = useStore();

    const slideOptions = {
      slidesPerView: 'auto',
    };

    const popular = computed(() => store.getters['library/getPopularCourses'].slice(0, 10));

    watch(() => {
      const watchValue = Array.isArray(popular.value) ? popular.value : [];
      return [...watchValue];
    }, () => {
      sliderKey.value = Math.random();
    } );

    return { slideOptions, sliderKey, popular };
  },
};
</script>

<style scoped lang="scss">
  ion-slide {
    max-width: 160px !important;
    text-align: left;
  }
</style>
