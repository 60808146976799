// import { initializeApp } from "firebase/app";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID || '',
  appId: process.env.VUE_APP_FIREBASE_APP_ID || '',
};


const createFirebaseApp = () => {
  return firebase.initializeApp(firebaseConfig);
};

export const getDBServerValue = () => {
  return firebase.database.ServerValue.TIMESTAMP;
};


export default createFirebaseApp;
