import { Network } from '@capacitor/network';

import { toastController } from "@ionic/vue";
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n/index';


import auth from "@/plugins/auth";
import { useStore } from "vuex";
import {INIT_APP_NETWORK_CONNECTED, INIT_APP_NETWORK_WAITING} from "@/config/constants";

export default () => {
  const { t } = useI18n();
  const store = useStore();

  const toast = ref(null);


  const subscribeOnChange = (callback) => {
    Network.addListener('networkStatusChange', callback);
  };

  const getStatus = async () => {
    return await Network.getStatus();
  };

  const isConnected = () => {
    return getStatus?.connected;
  };


  const createToast = async () => {
    return await toastController.create({
      message: t('commonKeys.noConnection'),
      position: 'top',
      color: 'warning',
      buttons: [
        {
          text: 'Close',
          role: 'Cancel',
        },
      ],
    });
  };

  const networkChangedHandler = (status) => {
    const connected = status?.connected;

    if (toast.value) {
      toast.value.dismiss();
    }
    // toastController.dismiss();

    const initNetworkState = store.state.app.network.appInitState;


    // console.log('initNetworkState', initNetworkState);
    // console.log('connected');

    if (connected && initNetworkState === INIT_APP_NETWORK_WAITING) {
      auth.fetch();
      // this commit will trigger home page to fetch all data;
      store.commit('app/setAppInitNetworkState', INIT_APP_NETWORK_CONNECTED);
    }

    if (!connected) {
      setTimeout(async () => {
        if (!isConnected()) {
          toast.value = await createToast();
          toast.value.present();
        }
      }, 1000);
    }
  };



  const initAlerts = async () => {
    // load toast bundle in cache
    toast.value = await createToast();
    const status = await getStatus();
    if (!status?.connected) {
      // console.log('INIT_APP_NETWORK_WAITING');
      store.commit('app/setAppInitNetworkState', INIT_APP_NETWORK_WAITING);
      toast.value.present();
    }

    subscribeOnChange(networkChangedHandler);
  };


  return {
    initAlerts,
    getStatus,
    isConnected,
  };
};
