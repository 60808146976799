<template>
  <div
    v-if="isOpen && !isClosed"
    class="invite-card ion-margin-vertical"
  >
    <div class="title is-flex is-align-items-start ion-align-items-center">
      <div class="item-icon mr-5">
        <IonIcon :icon="giftOutline" />
      </div>
      <h2>{{ $t('invitationCard.title') }}</h2>
      <div
        class="close-icon pointer"
        @click="closeModal"
      >
        <ion-icon :icon="close" />
      </div>
    </div>

    <p class="descr mt-1">{{ $t('invitationCard.text') }}</p>

    <div class="buttons">
      <ion-button
        color="light"
        fill="outline"
        mode="ios"
        class="uppercase"
        @click="openModal"
      >
        {{ $t('invitationCard.button') }}
      </ion-button>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon, modalController } from '@ionic/vue';
import { giftOutline, close } from 'ionicons/icons';
import { computed, ref } from '@vue/reactivity';
import ShareModal from '@/components/common/ShareModal';
import axios from '@/http';
import { GET_INVITE_TOKEN } from '@/config/api';
import { useI18n } from 'vue-i18n/index';
import useAppStorage from '@/composables/useAppStorage';
import {
  IS_INVITE_CARD_CLOSED_FIRST,
  INVITE_CLOSED_COUNTER,
} from '@/config/constants';
import { onMounted } from '@vue/runtime-core';

export default {
  name: 'InviteCard',
  components: {
    IonButton,
    IonIcon,
  },

  setup() {
    const { t } = useI18n();
    const { setKey, getKey } = useAppStorage();

    const isOpen = computed(() => {
      if (keyTime.value) {
        const getTimePassInDays =
          (Date.now() - keyTime.value) / 1000 / 60 / 60 / 24;
        switch (+inviteCounter.value) {
        case 1:
          return getTimePassInDays < 3;
        case 2:
          return getTimePassInDays > 10;
        case 3:
          return getTimePassInDays > 40;
        default:
          return false;
        }
      }
      return true;
    });
    const keyTime = ref(null);
    const inviteCounter = ref(null);
    const isClosed = ref(false);

    const openModal = () => {
      getInviteToken().then((token) => {
        openShareModal(token);
      });
    };

    const closeModal = () => {
      if (!keyTime.value) {
        setKey(IS_INVITE_CARD_CLOSED_FIRST, Date.now().toString());
        setKey(INVITE_CLOSED_COUNTER, 1);
      }
      if (inviteCounter.value) {
        setKey(INVITE_CLOSED_COUNTER, (+inviteCounter.value + 1).toString());
      }
      isClosed.value = true;
    };

    const openShareModal = async (token) => {
      const modal = await modalController.create({
        component: ShareModal,
        componentProps: {
          shareLink: `${process.env.VUE_APP_SHARE_URL}/invite/${token}`,
          shareText:  t('invitationCard.shareText', { token }),
          showLink: false,
          modalTitle: t('invitationCard.shareInvite'),
        },
        cssClass: 'share-modal',
      });
      await modal.present();
    };

    const getInviteToken = () => {
      return new Promise((resolve, reject) => {
        axios
          .get(GET_INVITE_TOKEN)
          .then(({ data }) => {
            resolve(data.invite_token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    onMounted(async () => {
      keyTime.value = await getKey(IS_INVITE_CARD_CLOSED_FIRST);
      inviteCounter.value = await getKey(INVITE_CLOSED_COUNTER);
    });

    return {
      openModal,
      giftOutline,
      close,
      isOpen,
      getInviteToken,
      closeModal,
      keyTime,
      inviteCounter,
      isClosed,
    };
  },
};
</script>

<style scoped lang="scss">
.invite-card {
  background-color: var(--ion-color-primary);
  padding: 10px;
  border-radius: 6px;
  position: relative;
  color: var(--white);
}

.title {
  color: var(--white);
  margin: 10px 0;

  .item-icon {
    font-size: 30px;
    height: 30px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1.2px;
    margin: 0;
    color: var(--white);
  }
}

.descr {
  font-size: 12px;
  line-height: 18px;
  color: var(--white);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  ion-button {
    font-size: 14px;
    font-weight: 700;
    --border-width: 2px;
  }
}
.close-icon {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  font-size: 30px;
}
</style>
