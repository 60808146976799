import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n/index';

import nl from '@/lang/nl';

export function setupI18n(options) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  document.querySelector('html').setAttribute('lang', locale);
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/lang/${locale}.js`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

export async function changeLocale(i18n, locale) {
  await loadLocaleMessages(i18n, locale);
  await setI18nLanguage(i18n, locale);
}


const i18n = setupI18n({
  legacy: false,
  locale: process.env.VUE_APP_DEFAULT_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE || 'en',
  messages: { nl },
});

export default i18n;
