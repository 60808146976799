<template>
  <div class="series-podcast">
    <ion-card
      color="dark"
      class="series-podcast-card ion-padding-vertical"
    >
      <div class="card-content ion-margin-bottom">
        <ion-card-header class="series-podcast-card-header">
          <p class="title fz-24 fw-500 mb-2">{{ podcastInfo.title || podcastInfo.course_title }}</p>
        </ion-card-header>
        <ion-card-content class="series-podcast-card-content is-flex">
          <div
            v-if="podcastInfo.episodes"
            class="series-quantity card-info"
          >
            <p class="fw-300 fz-12">
              <span>{{ $t('podcast.series') }} </span><span class="bullet">&bull;</span>{{ podcastInfo.episodes }} {{ $t('podcast.episodes') }}
            </p>
          </div>
          <div
            v-else
            class="single card-info"
          >
            <p class="fw-300 fz-12">
              <span v-if="formatDate">
                <span>{{ formatDate }} </span>
                <span class="bullet">&bull;</span>
              </span>
              <span v-if="currentStreamDuration">
                <span>{{ Math.round(currentStreamDuration / 60) }} {{ $t('podcast.min') }}</span>
              </span>
              <span v-else>--:--</span>
              <span v-if="podcastInfo.favourites_count">
                <span class="bullet">&bull;</span>
                {{ podcastInfo.favourites_count }} {{ $t('podcast.favorites') }}
              </span>
            </p>
          </div>
          <div class="podcast-social is-flex">
            <ShareButton
              :text="podcastInfo.title"
              :course-id="courseId"
              :stream-id="streamId"
            />
            <IonIcon
              v-if="podcastInfo.isSeries"
              class='pointer'
              :icon="notificationIsOn ? notifications : notificationsOutline"
              @click="notificationIsOn = !notificationIsOn"
            />
            <AppTooltip
              :label="likeBtnTooltipText"
              position="is-left"
              :active="likeBtnTooltip"
              :always="likeBtnTooltip"
              wrapper-class="is-size is-multiline"
              @click:label="$emit('click:tooltip:label')"
            >
              <FavouriteButton
                :course-id="courseId"
                :stream-id="streamId"
              />
            </AppTooltip>
          </div>
        </ion-card-content>
      </div>
      <div class="img-wrap tablet-banner-img-square mx-auto">
        <AppImg
          :img-src="banner"
          img-alt="banner"
          border-radius="20px"
        />
      </div>
      <div
        class="podcast-text-wrap ion-margin-top"
        :class="{ 'h-100': !isReadMore }"
      >
        <p
          v-if="isReadMore"
          ref="shortPodcastDescription"
          class="fz-12 fw-300 test"
        >{{ shortPodcastDescription }}
          <span v-if="textLength > maxLengthText">...</span>
        </p>
        <p
          v-else
          ref="shortPodcastDescription"
          class="fz-12 fw-300 test"
        >{{ description }}</p>
      </div>
      <p
        v-if="textLength > maxLengthText"
        class="fz-12 fw-400 ion-text-right pointer"
        @click="isReadMore = !isReadMore"
      >
        {{ readMore }}
      </p>
    </ion-card>
  </div>
</template>

<script>
import {
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
} from '@ionic/vue';
import {
  notifications,
  notificationsOutline,
} from 'ionicons/icons';

import { computed, ref, toRefs } from '@vue/reactivity';
import AppImg from '@/components/common/AppImg.vue';
import { getSmallestSizeImage } from "@/helpers/functions";
import ShareButton from '@/components/common/ShareButton.vue';
import FavouriteButton from '@/components/common/FavouriteButton.vue';
import {CATEGORY_PODCAST_AUDIO} from "@/config/constants";
import { useI18n } from 'vue-i18n/index';
import AppTooltip from "@/components/common/AppTooltip";

export default {
  name: 'PodcastInfo',
  components: {
    AppTooltip,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardContent,
    AppImg,
    ShareButton,
    FavouriteButton,
  },
  props: {
    podcastInfo: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },

    courseMedia: {
      type: Array,
      default: () => ([]),
    },

    streamDuration: {
      type: [Number, String],
      default: 0,
    },

    likeBtnTooltip: {
      type: Boolean,
      default: false,
    },
    likeBtnTooltipText: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const { podcastInfo } = toRefs(props);
    const { t } = useI18n();
    const maxLengthText = 234;

    const notificationIsOn = ref(podcastInfo.value?.notificationIsOn);
    const description = computed(() => podcastInfo.value.course_introduction ??podcastInfo.value.description);
    const isReadMore = ref(true);
    const readMore = computed(() =>
      isReadMore.value ? t('podcast.readMore') : t('podcast.readLess'),
    );
    const textLength = computed(() => description.value?.length);
    const shortPodcastDescription = computed(() => description.value?.substr(0, maxLengthText));

    const banner = computed(() => {
      const media = getSmallestSizeImage(podcastInfo?.value?.media?.filter(m => m.category !== CATEGORY_PODCAST_AUDIO), 1)?.url ?? null;
      const mediaFallback = getSmallestSizeImage(props?.courseMedia, 1)?.url ?? null;
      return media || mediaFallback;
    });

    const streamId = computed(() => {
      return props.podcastInfo.streams ? null : props.podcastInfo.id;
    });
    const courseId = computed(() => {
      return props.podcastInfo.course_id ? props.podcastInfo.course_id : props.podcastInfo.id;
    });
    const formatDate = computed(() => {
      if (props.podcastInfo.rss_feed_publish_date) {
        const date = new Date(props.podcastInfo.rss_feed_publish_date);
        return new Intl.DateTimeFormat(t('podcast.local'), { dateStyle: 'long' }).format(date);
      } else if (props.podcastInfo.created_at) {
        const date = new Date(props.podcastInfo.created_at);
        return new Intl.DateTimeFormat(t('podcast.local'), { dateStyle: 'long' }).format(date);
      }
      return null;
    });
    const currentStreamDuration = computed(() => {
      return props.podcastInfo.duration ?? props.streamDuration;
    });


    return {
      notifications,
      notificationsOutline,
      notificationIsOn,
      isReadMore,
      readMore,
      shortPodcastDescription,
      textLength,
      maxLengthText,
      banner,
      streamId,
      courseId,
      formatDate,
      description,
      currentStreamDuration,
    };


  },
};
</script>

<style scoped lang="scss">
.series-podcast-card {
  margin-left: auto;
  margin-right: auto;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  --color: var(--white);
}

ion-card {
  --ion-color-base: transparent !important;
  margin: 0;
}

.series-podcast-card-header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
}
.series-podcast-card-content {
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0px;

  .series-quantity p {
    font-size: 12px;
  }
}
.podcast-social *:not(:last-child) {
  margin-right: 10px;
}



ion-icon {
  font-size: 1.5rem;
}

.bullet {
  padding: 0 10px;
}

.podcast-text-wrap {
  max-height: 75px;
  overflow: hidden;
  transition: max-height 0.3s;
  p {
    line-height: 1.5;
  }
}

.podcast-text-wrap.h-100 {
  max-height: 100%;
  transition: max-height 0.3s;
}

.card-info {
  p {
    font-size: 12px;
  }
}

.img-wrap {
  overflow: hidden;
  border-radius: 20px;
  height: calc(100vw - 32px);
}
</style>
