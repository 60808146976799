import useRequestToast from '@/composables/useRequestToast';
import { Clipboard } from '@capacitor/clipboard';
import { useI18n } from 'vue-i18n/index';

export default () => {
  const { openSuccessToast } = useRequestToast();
  const { t } = useI18n();

  const copy =  (string, successMessage) => {
    Clipboard.write({
      string: string,
    });
    openSuccessToast(successMessage || `${t('link')} ${t('userProfile.wasCopied')}`);
  };

  return { copy };
};
