import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import auth from './plugins/auth.js';
import i18n from './plugins/i18n';
import pwaElements from './plugins/pwa-elements';
import recaptcha from "./plugins/recaptcha";
import Vue3TouchEvents from 'vue3-touch-events';
import initSentry from "@/plugins/sentry";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/fonts.css';
import './theme/margin.scss';
import './theme/padding.scss';
import './theme/font-size.scss';
import './theme/font-weight.scss';
import './theme/custom.scss';
import './theme/transition-animations.scss';

const app = createApp(App)
  .use(IonicVue)
  .use(store)
  .use(router)
  .use(auth)
  .use(i18n)
  .use(recaptcha)
  .use(pwaElements)
  .use(Vue3TouchEvents);

initSentry(app, router);


router.isReady().then(() => {
  app.mount('#app');
});
