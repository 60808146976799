import auth from "@/plugins/auth";
import axios from "@/http";
import { changeLocale } from '@/plugins/i18n';
import i18n from '@/plugins/i18n';
import isEmpty from 'lodash/isEmpty';

import {
  REGISTER_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_EMAIL_URL,
} from '@/config/api';
import useFirebase from "@/composables/useFirebase8";
import useAppStorage from "@/composables/useAppStorage";
import {
  DEEP_LINK_REDIRECT_STORAGE_KEY,
  NOT_FOUNT_REDIRECT_STORAGE_KEY,
  NOTIFICATIONS_ASK_COUNT_KEY,
  RECENTLY_PLAYED_PROGRESS_STORAGE_KEY,
  RECENTLY_PLAYED_STORAGE_KEY,
} from "@/config/constants";


const { initDB: fireDB } = useFirebase();

const { setKey, removeKey } = useAppStorage();


const state = () => ({
  email: null,
  refreshAfterLogout: false,
  qrImageBase64: '',
  secretKey: '',
});

// getters
const getters = {
  getUser() {
    return auth.user() ?? {};
  },
  isLogged() {
    return auth.check() ?? false;
  },
  getEmailState: state => state.email,

  hasPermission(state, getters) {
    return function (permission) {
      const user = getters.getUser;
      if (isEmpty(user)) {
        return false;
      }

      if (user.roles.find(r => r.name === 'SuperAdmin')) {
        return true;
      }

      return !!user.userPermissions.find(p => p.name === permission);
    };

  },

  canEdit: (state, getters, rootState) => {
    const user = getters.getUser;
    const platform = rootState.app.platform;
    if (!user) {
      return false;
    }
    return !!user?.roles?.find(role => role.name === 'SuperAdmin') && platform === 'web';
  },
};

// actions
const actions = {
  login ({ commit },  data) {
    return new Promise((resolve, reject) => {
      auth
        .login( { data })
        .then((response) => {
          commit('setRefreshState', false);
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  register ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(REGISTER_URL, data)
        .then((response) => {
          commit('setQrImage', response.data.qr_image);
          commit('setSecretKey', response.data.secret_key);
          auth
            .login({ data })
            .then(response => resolve(response))
            .catch(error => reject(error));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchUser() {
    return auth.fetch();
  },

  resetOnLoginLogout({ dispatch }) {
    dispatch('player/closePlayer', null, {root: true});
    dispatch('player/resetRecentlyPlayedProgressStorage', null, { root: true });
    setKey(NOTIFICATIONS_ASK_COUNT_KEY, 0);
    removeKey(RECENTLY_PLAYED_STORAGE_KEY);
    removeKey(RECENTLY_PLAYED_PROGRESS_STORAGE_KEY);
    removeKey(RECENTLY_PLAYED_PROGRESS_STORAGE_KEY);
    removeKey(NOT_FOUNT_REDIRECT_STORAGE_KEY);
    removeKey(DEEP_LINK_REDIRECT_STORAGE_KEY);
  },

  logout ({ commit, dispatch }, reload = true) {
    return new Promise((resolve, reject) => {
      dispatch('resetOnLoginLogout');
      fireDB().goOffline();
      changeLocale(i18n, 'nl');
      commit('profile/changeLanguage', 'nl', {root: true});
      commit('setRefreshState', true);
      auth
        .logout()
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error))
        .finally(() => {
          if (reload) {
            document.location.reload();
          }
        });
    });
  },

  forgotPassword (context, data) {
    return new Promise((resolve, reject) => {
      axios.post(FORGOT_PASSWORD_URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  forgotEmail (context, data) {
    return new Promise((resolve, reject) => {
      axios.post(FORGOT_EMAIL_URL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setEmailState (state, email) {
    state.email = email;
  },
  setRefreshState (state, newState) {
    state.refreshAfterLogout = newState;
  },
  setQrImage (state, qrImageBase64) {
    state.qrImageBase64 = qrImageBase64;
  },
  setSecretKey (state, secretKey) {
    state.secretKey = secretKey;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
