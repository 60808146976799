<template>
  <p
    v-for="(error, i) in formattedErrors"
    :key="i"
  >
    <ion-text color="danger">
      <small> {{ error }}</small>
    </ion-text>
  </p>

</template>

<script>
import { IonText } from '@ionic/vue';

export default {
  name: "AppErrorField",
  props: {
    errors: {
      type: [Array, String, Object],
      default() {
        return [];
      },
    },
    errorsAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonText,
  },
  computed: {
    formattedErrors() {
      if (typeof this.errors === 'string') {
        return [this.errors];
      }
      let messages = [];
      if (Array.isArray(this.errors)) {
        this.errors.forEach((message) => {
          if (typeof message === 'string') {
            messages.push(message);
          } else {
            for (let key in message) {
              if (message[key]) {
                messages.push(key);
              }
            }
          }
        });
      } else {
        for (let key in this.errors) {
          if (this.errors[key]) {
            messages.push(key);
          }
        }
      }
      return messages.filter((m) => { if (m) return m; });
    },
  },
};
</script>
