<template>
  <div v-if="missedQuestions.length">
    <div class="alert is-flex ion-padding-horizontal pb-2">
      <div>
        <IonIcon :icon="alertCircleOutline"/>
      </div>
      <div class="pl-2">
        <p class="fz-12">{{ $t('accreditation.missedQuestionAlert') }}</p>
      </div>
    </div>
    <div class="buttons-container">
      <div
        v-for="question in missedQuestions"
        :key="question.id"
        class="button pb-1"
      >
        <ion-button
          expand="block"
          mode="ios"
          @click="playBackTrack(question)"
        >
          <div class="is-flex ion-align-items-center w-100">
            <IonIcon :icon="playBackOutline"/>
            <h6 class="pl-2">{{ $t('accreditation.goBack') }} {{ question.position }}</h6>
          </div>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { playBackOutline, alertCircleOutline } from 'ionicons/icons';
import {
  DISPLAY_TYPE_VIDEO,
  DISPLAY_ACTION_SHOW_QUESTION,
} from "@/config/constants";
import { convertStringHHMMSStoSec } from "@/helpers/functions";

import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { computed, nextTick } from '@vue/runtime-core';

export default {
  name: 'ReplayQuestionsContainer',
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    formId: {
      type: [Number, String],
      default: '',
    },
    streamId: {
      type: [Number, String],
      default: '',
    },
    missedQuestions: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    const store = useStore();
    const router = useRouter();

    const playerCurrentTime = computed(() => store.state.player.trackInfo.trackCurrentTime);

    const playBackTrack = (question) => {
      let maxValue = 0;
      let playerRanges = [];
      const moments = question.display_moments.filter(moment => moment.type === DISPLAY_TYPE_VIDEO && moment.action === DISPLAY_ACTION_SHOW_QUESTION);
      moments.forEach((setting) => {
        const secondsStart = convertStringHHMMSStoSec(setting.time_open);
        const secondsEnd = convertStringHHMMSStoSec(setting.time_close);
        if (playerCurrentTime.value > secondsEnd) {
          maxValue = Math.max(maxValue, secondsEnd);
          playerRanges.push({
            time_open: secondsStart,
            time_close: secondsEnd,
          });
        }
      });
      playerRanges = playerRanges.filter(range => range.time_close === maxValue).sort((a, b) => a.time_open - b.time_open);

      if (playerRanges.length) {
        store.commit('player/setTrackInfo', { trackCurrentTime: playerRanges[0].time_open});
        store.commit('player/setPlayerCollapse', true);
        store.commit('player/seekStream', null);
        nextTick(() => store.commit('player/seekStream', playerRanges[0].time_open));
      }
      router.push(`/accreditation/${props.streamId}/${props.formId}`);
    };

    return {
      playBackOutline,
      alertCircleOutline,
      playBackTrack,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
