import { useRouter } from "vue-router";
import { App } from '@capacitor/app';

import useAppStorage from "@/composables/useAppStorage";
import {useStore} from "vuex";
import {DEEP_LINK_REDIRECT_STORAGE_KEY, domainRegex, TRACKING_DEEPLINK_ACTION} from "@/config/constants";
import useTracking from "@/composables/useTracking";


export default () => {
  const store = useStore();
  const router = useRouter();
  const { setKey } = useAppStorage();
  const { trackThis } = useTracking();

  const subscribeLinkOpen = () =>  {
    // console.log('init subscribeLinkOpen listener');
    const urlOpenHandler = (data) => {
      // console.log('Link opened, link:', data);
      // Example url: https://app.medicast.nl/course/123?s=456
      // slug = /course/123?s=456

      trackThis(TRACKING_DEEPLINK_ACTION, { share_url: data.url });

      let slug = '';
      if (data.url.includes('app.')) {
        slug = data.url.split(domainRegex).pop();
      }

      // console.log('slug is:', slug);

      if (!slug) { return; }

      // We only push to the route if there is a slug present
      if (slug) {
        if (!store.getters['authentication/isLogged']) {
          setKey(DEEP_LINK_REDIRECT_STORAGE_KEY, slug);
        }

        router.push(slug);
      }
    };
    App.addListener('appUrlOpen', urlOpenHandler);
  };

  return { subscribeLinkOpen };
};
