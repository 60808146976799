import axios from "@/http";
import {CERTIFICATE_EMAIL_SEND, USER_SUBSCRIBED_COURSES} from "@/config/api";

const initializeStates = () => {
  return {
    isConfirmedProfile: false,

    streamsWithAccreditation: [],
  };
};

export const state = () => initializeStates();

// getters

// actions
export const actions = {
  setAccreditationStreams ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(USER_SUBSCRIBED_COURSES)
        .then(({ data }) => {
          commit('setAccreditationStreams', data.subscribed_course_streams);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendCertificateToUserEmail (_, formId) {
    const url = CERTIFICATE_EMAIL_SEND.replace('{form_id}', formId);
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};


// mutations
const mutations = {
  setConfirmedProfile (state, value) {
    state.isConfirmedProfile = value;
  },
  setAccreditationStreams(state, value) {
    state.streamsWithAccreditation = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
