<template>
  <div class="featured">
    <h1>{{ featuredSliderData[0].theme_name }}</h1>
    <div class="featured-wrap">
      <ion-slides
        :options="slideOptions"
        :key="Math.random()"
      >
        <ion-slide
          v-for="data in featuredSliderData"
          :key="data.id"
        >
          <FeaturedPodcast
            :featured-podcast="data"
            @click="openCourse(data)"
          />
        </ion-slide>
      </ion-slides>
    </div>
  </div>
</template>

<script>
import { IonSlides, IonSlide } from '@ionic/vue';
import FeaturedPodcast from '@/components/common/FeaturedPodcast.vue';
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import useTracking from "@/composables/useTracking";
import {TRACKING_FEATURED_COURSE_VIEW} from "@/config/constants";


export default {
  name: 'FeturedSlider',
  components: {
    IonSlides,
    IonSlide,
    FeaturedPodcast,
  },
  props: {
    featuredSliderData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const slideOptions = {
      slidesPerView: 'auto',
    };

    const router = useRouter();
    const store = useStore();
    const { trackThis } = useTracking();

    const openCourse = (featuredData) => {
      trackThis(TRACKING_FEATURED_COURSE_VIEW, {}, featuredData.id);
      store.commit('course/setCourseTrackingState', false);
      router.push(`/course/${featuredData.course_id}`);
    };

    return { slideOptions, openCourse };
  },
};
</script>

<style scoped lang="scss">
.featured-wrap ion-slide {
  width: auto;
  text-align: left;
}
</style>
