<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <ion-header class="is-flex ion-align-items-center ion-padding-start ion-padding-vertical">
      <h3>
        <b> {{ $t('profile.changeEmail') }} </b>
      </h3>

      <ion-buttons class="ml-auto">
        <ion-button
          class="modal-close-button"
          mode="ios"
          @click="close"
        >
          <ion-icon
            :icon="closeIcon"
            class="ion-margin-start"
          />
        </ion-button>
      </ion-buttons>
    </ion-header>

    <section class="ion-padding-vertical ion-padding-horizontal">
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="!passwordVerified">
          <AppInput
            :value="password"
            class="w-100"
            :label="$t('login.passwordLabel')"
            type="password"
            :placeholder="$t('login.passwordLabel')"
            @input:update="password = $event"
            @input:enter="verifyPassword"
          />
          <ion-button
            mode="ios"
            expand="block"
            class="ion-margin-top"
            @click="verifyPassword"
          >{{ $t('profile.verifyPassword') }}</ion-button>
        </div>

        <div v-else>
          <AppInput
            :value="currentEmail"
            class="w-100 ion-margin-bottom"
            :label="$t('profile.currentEmail')"
            readonly
            disabled
          />
          <AppInput
            :value="newEmail"
            class="w-100"
            :label="$t('profile.newEmail')"
            type="email"
            :placeholder="$t('profile.enterNewEmail')"
            @input:update="newEmail = $event"
            @input:enter="changeEmail"
          />
          <ion-button
            mode="ios"
            expand="block"
            class="ion-margin-top"
            @click="changeEmail"
          >{{ $t('profile.changeEmail') }}</ion-button>
        </div>
      </transition>
    </section>
  </ion-content>
</template>

<script>
import { modalController, loadingController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';
import { close as closeIcon } from 'ionicons/icons';

import AppInput from '@/components/common/AppInput.vue';
import {computed, ref} from '@vue/reactivity';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useRequestToast from "@/composables/useRequestToast";

export default {
  name: "ProfileEmailChangeModal",
  components: {
    AppInput,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
  },


  setup (props) {
    const store = useStore();
    const router = useRouter();

    const password = ref(null);
    const passwordVerified = ref(false);
    const newEmail = ref(null);

    const currentEmail = computed( () => store.getters['authentication/getUser'].email);
    const userId = computed( () => store.getters['authentication/getUser'].id);

    const { openErrorToast } = useRequestToast();

    const verifyPassword = async () => {
      const loading = await loadingController.create();
      await loading.present();

      store.dispatch('profile/verifyPassword', { password: password.value })
        .then(() => {
          passwordVerified.value = true;
        })
        .catch(openErrorToast)
        .finally(async () => {
          await loading.dismiss();
        });
    };

    const changeEmail = async () => {
      const loading = await loadingController.create();
      await loading.present();
      store.dispatch('profile/updateAuthUserEmail', {
        user_id: userId.value,
        password: password.value,
        email: newEmail.value,
        old_email: currentEmail.value,
      })
        .then(() => {
          store.dispatch('authentication/logout')
            .finally( async () => {
              store.commit('authentication/setEmailState', newEmail.value);
              await router.push('/login');
              props.close();
            });
        })
        .catch(openErrorToast)
        .finally(async () => {
          await loading.dismiss();
        });
    };

    return {
      password,
      passwordVerified,
      newEmail,
      currentEmail,
      changeEmail,
      verifyPassword,

      closeIcon,
    };
  },
};
</script>
