<template>
  <div v-if="skippedTracks.length">
    <div class="alert is-flex ion-padding pb-1">
      <div>
        <IonIcon :icon="alertCircleOutline" />
      </div>
      <div class="pl-2">
        <p class="fz-12">{{ $t('accreditation.skippedTrack') }}</p>
      </div>
    </div>
    <div class="buttons-container">
      <div
        v-for="(progress, i) in skippedTracks"
        :key="`skipped-${i}`"
        class="pb-1"
      >
        <ion-button
          expand="block"
          mode="ios"
          @click="playBack(progress.start)"
        >
          <div
            class="
              is-flex
              ion-align-items-center ion-justify-content-between
              w-100
            "
          >
            <div class="is-flex uppercase">
              <IonIcon :icon="playCircleOutline" />
              <h6 class="pl-2">{{ $t('streams.chapters.play') }}</h6>
            </div>
            <p>
              {{ convertSecondsToTimeString(progress.start) }} -
              {{ convertSecondsToTimeString(progress.end) }}
            </p>
          </div>
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { playCircleOutline, alertCircleOutline } from 'ionicons/icons';
import { convertSecondsToTimeString } from '@/helpers/functions';

import { useStore } from 'vuex';
import { computed, nextTick } from '@vue/runtime-core';
import cloneDeep from "lodash/cloneDeep";

import uniqWith from 'lodash/uniqWith';

export default {
  name: 'SkippedPlayerTrackContainer',
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    const store = useStore();

    const playerCurrentTime = computed(() => store.state.player.trackInfo.trackCurrentTime);
    const progressHistory = computed(() => store.state.player.progressHistory);

    const playedTracks = computed(() => {
      const filteredProgressHistory = uniqueProgress(
        cloneDeep(progressHistory.value)
          .filter((progress) => progress.start !== progress.end)
          .sort((a, b) => a.start - b.start),
      );
      const played = [];
      let result = filteredProgressHistory[0];
      for (let i = 1; i < filteredProgressHistory.length; i++) {
        const filteredValue = filteredProgressHistory[i];
        const start = filteredValue.start;
        const end = filteredValue.end;

        if (start <= result.end + 1 && end > result.end) {
          result.end = end;
        } else if (start > result.end) {
          played.push(result);
          result = {start, end};
        }
      }
      played.push(result);
      return played;
    });

    const skippedTracks = computed(() => {
      const skipped = [];
      playedTracks.value.forEach((progress, i) => {
        if (playedTracks.value[i + 1]) {
          skipped.push({
            start: progress.end,
            end: playedTracks.value[i + 1].start,
          });
        }
      });
      return skipped.filter(progress => (playerCurrentTime.value > progress.end) && (progress.end - progress.start) > 15);
    });

    const uniqueProgress = (progress) => {
      return uniqWith(
        progress,
        (a, b) => a.start === b.start && a.end === b.end,
      );
    };

    const playBack = (start) => {
      store.commit('player/seekStream', null);
      nextTick(() => store.commit('player/seekStream', start));
      store.commit('player/setTrackInfo', { trackCurrentTime: start });
    };

    return {
      playCircleOutline,
      alertCircleOutline,
      uniqueProgress,
      skippedTracks,
      convertSecondsToTimeString,
      playBack,
      playedTracks,
      progressHistory,
    };
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  max-height: 290px;
  overflow-y: auto;
}
</style>
