<template>
  <div>
    <div
      class="track"
      :class="{ 'none-events': !playlist.length }"

      @mouseup="seekingWrapper"
    >
      <div class="track__title">
        {{ soundTitle }}
      </div>
      <div
        ref="track__progress-container"
        class="track__progress-container"
        :class="{ 'none-events': playerLoading || playerBusy || !playerReady || scrubbingDisabled }"
        @mousedown="trackProgressClicked"
        @mousemove.prevent="trackProgressMoving"
        @mouseleave="seekingWrapper"
        @touchstart.prevent="trackProgressTouched"
        @touchmove="trackProgressTouching"
        @touchend="trackProgressStoppedTouching"
      >
        <div
          ref="track__progress-container--wrapper"
          class="track__progress-container--wrapper"
        >
          <div
            ref="track__progress-bar"
            class="track__progress-bar"
            :class="{'track__progress-bar--active': progressActive}"
          ></div>
        </div>
      </div>

      <div class="track__progress-timers">
        <div> {{ timer }} </div>
        <div> {{ duration }} </div>
      </div>

      <div class="track__progress-controls">
        <div class="track__progress-controls__seek">
          <span
            class="mr-6"
            :class="{'has-text-dark': playerBusy || !canSkipBack}"
          >
            <IonIcon
              class="pointer"
              :icon="skipBackIcon"
              size="large"
              @click="skipHandler(-1, true)"
            />
          </span>


          <div class="is-relative">
            <span
              class="play-icon"
              :class="{'has-text-dark': playerBusy}"
            >
              <IonIcon
                class="pointer"
                :icon="isPlaying ? pauseIcon : playIcon"
                @click="togglePlay"
              />
            </span>

            <div
              v-if="playerLoading || playerBusy || !playerReady"
              class="play-loading"
            ></div>
          </div>

          <span
            class="ml-6"
            :class="{'has-text-dark': playerBusy || !canSkipNext}"
          >
            <IonIcon
              class="pointer"
              :icon="skipForwardIcon"
              size="large"
              @click="skipHandler(1, true)"
            />
          </span>
        </div>


        <div
          v-if="!scrubbingDisabled"
          class="seek-buttons"
        >
          <span
            class="skip-icon"
            :class="{'has-text-dark': playerBusy}"
          >
            <IonIcon
              class="pointer"
              :icon="undoIcon"
              @click="skipBy(-15, false, true)"
            />
            <span class="skip-icon-label">15</span>
          </span>

          <span
            class="skip-icon"
            :class="{'has-text-dark': playerBusy}"
          >
            <IonIcon
              class="pointer"
              :icon="redoIcon"
              @click="skipBy(15, false, true)"
            />
            <span class="skip-icon-label">15</span>
          </span>
        </div>


        <div
          v-if="volumeControls"
          class="track__volume-container"
        >
          <span
            class="volume-icon"
            :class="{'has-text-dark': playerBusy}"
          >
            <IonIcon
              class="pointer"
              :icon="isMuted ? volumeMuteIcon : volumeOnIcon"
              @click="toggleVolume"
            />
          </span>
          <div
            ref="track__volume-container--wrapper"
            class="track__volume-container--wrapper"
            @mousedown="trackVolumeClicked"
            @mousemove="trackVolumeMoving"
            @touchstart="trackVolumeTouched"
            @touchmove="trackVolumeTouching"
            @touchend="trackVolumeStoppedTouching"
          >
            <div
              ref="track__volume"
              class="track__volume"
            >
              <div
                ref="track__volume__progress-bar"
                class="track__volume__progress-bar"
                :class="{'track__volume__progress-bar--active': volumeProgressActive}"
              ></div>
            </div>
          </div>
        </div>
        <div
          v-if="chapters.length"
          class="track__chapters-container"
          :class="{ 'none-events': playerLoading || playerBusy || !playerReady, 'has-text-dark': playerBusy }"
        >
          <IonIcon
            class="pointer"
            :icon="listIcon"
            size="large"
            @click="showChapters = !showChapters"
          />
        </div>
      </div>

      <transition name="slide-player">
        <!--      Replace with real data later-->
        <div
          v-if="showChapters"
          class="track__chapters__list"
        >
          <div class="is-flex ion-justify-content-between ion-align-items-center">
            <p> {{ $t('player.chapters') }} </p>
            <IonIcon
              class="pointer"
              :icon="chevronDownIcon"
              size="large"
              @click="showChapters = !showChapters"
            />
          </div>
          <ul class="fw-600">
            <li
              v-for="chapter in chapters"
              :key="chapter.id"
              class="track__chapter fz-14 pointer"
              :class="{'ion-text fw-300': chapterPlayed(chapter), 'has-text-primary': chapterPlaying(chapter)}"
              @click="seekToChapter(chapter)"
            >
              <div class="track__chapter__time">
                {{ chapter.time_begin }}
              </div>
              <div class="track__chapter__title">
                {{ chapter.title }}
              </div>
              <div class="track__chapter__play">
                <IonIcon
                  :icon="playIcon"
                  size="large"
                />
              </div>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { Howl } from 'howler';
import debounce from 'lodash/debounce';

import axios from '@/http';

import {IonIcon, toastController} from '@ionic/vue';
import {
  playCircle as playIcon,
  pauseCircle as pauseIcon,
  arrowUndo as undoIcon,
  arrowRedo as redoIcon,
  volumeHigh as volumeOnIcon,
  volumeMute as volumeMuteIcon,
  chevronDown as chevronDownIcon,
  list as listIcon,
  playSkipBack as skipBackIcon,
  playSkipForward as skipForwardIcon,

} from 'ionicons/icons';

// import { MusicControls } from '@ionic-native/music-controls';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls';
const MusicControls = {};
// import { Device } from "@capacitor/device";
import {
  CATEGORY_GENERAL_MEDIA,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_STREAM_STILL,
  CATEGORY_PODCAST_IMG,
  CATEGORY_PODCAST_AUDIO,
  TRACKING_PODCAST_PLAY,
  TRACKING_PODCAST_PAUSE,
  TRACKING_PODCAST_SKIP_SEC_FORWARD,
  TRACKING_PODCAST_SKIP_SEC_BACKWARD,
  TRACKING_PODCAST_SKIP_STREAM_FORWARD,
  TRACKING_PODCAST_SKIP_STREAM_BACKWARD,
  TRACKING_PODCAST_SCRUBBING,
} from '@/config/constants';

import useTracking from "@/composables/useTracking";
const { trackThis } = useTracking();

import { captureException, captureMessage, withScope } from '@sentry/vue';


const PRIOR_IMAGES = [
  CATEGORY_PODCAST_IMG,
  CATEGORY_STREAM_STILL,
  CATEGORY_BANNER_MEDIA,
  CATEGORY_GENERAL_MEDIA,
];


import isEqual from 'lodash/isEqual';
import {getSecondsFromHMSString, getSmallestSizeImage} from '@/helpers/functions';

const CapacitorMusicControls = {};
export default {
  name: 'AudioPlayer',

  components: {
    IonIcon,
  },

  props: {
    soundTitle: {
      type: String,
      default: '',
    },

    soundScr: {
      type: String,
      default: null,
      required: false,
    },

    playlist: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },

    playlistStartIndex: {
      type: Number,
      default: 0,
    },

    seekPosition: {
      type: Number,
      default: null,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    autoplayDelay: {
      type: Number,
      default: 0,
    },

    volumeControls: {
      type: Boolean,
      default: false,
    },

    skipAutoplayId: {
      type: Number,
      default: null,
    },

    course: {
      type: Object,
      default: () => ({media: []}),
    },

    scrubbingDisabled: {
      type: Boolean,
      default: false,
    },

    scrubbingForwardDisabled: {
      type: Boolean,
      default: false,
    },

    progress: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      playerReady: false,
      track: null,
      isPlaying: false,
      timer: '0:00',
      trackCurrentTime: 0,
      duration: '-:--',
      progressActive: false,
      volumeProgressActive: false,
      seeking: false,
      seekingVolume: false,
      savedVolume: 0,
      seekValue: 0,
      showChapters: false,
      playedBeforePause: false,
      playerBusy: false,
      playerLoading: false,

      currentTrackIndex: 0,

      queueAfterPause: [],
      queueAfterPlay: [],
      queueAfterOnload: [],

      waitingForQueue: false,
      waitingForPlayQueue: false,

      startPlayPending: false,
      pausePending: false,
      drawProgress: false,

      playedBeforePauseResumed: true,
      pausedByToggle: false,

      intervalId: null,

      trackLoaded: false,

      destroying: false,

      musicControlsCreated: false,

      internalAutoPlay: false,

      defaultCoverPath: 'assets/logo.png',

      initTrackOnPlay: false,

      progressLocalPart: {
        start: 0,
        end: 0,
      },
      emittedTimeBeforeSeek: 0,

      howlSoundId: null,

      fallbackSetTimeoutIds: [],

      playIcon,
      undoIcon,
      redoIcon,
      pauseIcon,
      volumeOnIcon,
      volumeMuteIcon,
      chevronDownIcon,
      skipBackIcon,
      skipForwardIcon,
      listIcon,
    };
  },


  computed: {
    isMuted () {
      if (this.track) {
        return this.track.volume() === 0;
      }

      return false;
    },

    skipAutoplay () {
      if (!this.skipAutoplayId || !this.currentTrack?.id) {
        return false;
      }
      return this.currentTrack?.id === this.skipAutoplayId;
    },

    canSkipNext() {
      return this.currentTrackIndex < this.playlist.length - 1;
    },

    canSkipBack() {
      return this.currentTrackIndex > 0;
    },

    currentTrack () {
      return this.playlist[this.currentTrackIndex] ?? {};
    },

    currentCover () {
      const courseMedia = this.course?.media ?? [];
      const streamMedia = this.currentTrack?.media ?? [];
      const validStreamImages = streamMedia.filter(media => PRIOR_IMAGES.includes(media.category));
      validStreamImages.sort((a,b) => b.category - a.category);
      const streamImg = getSmallestSizeImage(validStreamImages)?.url ?? '';
      const courseImg = getSmallestSizeImage(courseMedia)?.url ?? '';
      return streamImg || courseImg;
    },

    currentCompanyTitle () {
      return this.course?.company?.name ?? this.$t('commonKeys.unknown');
    },

    chapters () {
      return this.currentTrack?.chapters ?? [];
    },
  },

  watch: {
    seekPosition: {
      immediate: true,
      handler (seconds) {
        const validSeconds = Boolean(seconds || seconds === 0);
        if (validSeconds) {
          if (this.playerReady) {
          //  console.log('skip by!', seconds);
            this.skipBy(seconds, true);
          }
        }
      },
    },

    playlist: {
      immediate: true,
      async handler (newPlaylist, oldPlaylist) {
        if (isEqual(newPlaylist, oldPlaylist)) { return; }

        this.stop();
        await this.clearPlayerInterval();
        this.track = null;
        this.playerReady = false;

        if (this.playlist.length) {
          const trackIndex = this.playlist[this.playlistStartIndex] ? this.playlistStartIndex : 0;
          this.initTrack(trackIndex);
        }
      },
    },

    playlistStartIndex () {
      setTimeout(async () => {
        if (this.playlistStartIndex !== this.currentTrackIndex) {
          this.stop();
          await this.clearPlayerInterval();
          this.track = null;
          this.playerReady = false;

          if (this.playlist.length) {
            const trackIndex = this.playlist[this.playlistStartIndex] ? this.playlistStartIndex : 0;
            this.initTrack(trackIndex);
          }
        }
      }, 100);
    },
  },

  beforeUnmount () {
    this.destroying = true;
    this.clearPlayerInterval();
    this.destroyMusicControls();
    this.track = null;
  },

  methods: {
    getAudioSrc (index) {
      const embedLink = this.playlist[index].embed_url;
      const s3BucketLink = this.playlist[index]?.media?.find(media => media.category === CATEGORY_PODCAST_AUDIO)?.url;

      return s3BucketLink ?? embedLink;
    },

    startInterval () {
      this.clearPlayerInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.step();
      }, 250);
    },

    async clearPlayerInterval () {
      clearInterval(this.intervalId);
      if (this.track) {
        await this.track.unload();
      }
    },


    resetState(destroyTrack = true) {
      this.clearFallbackTimeouts();
      this.clearPlayerInterval();
      this.destroyMusicControls();
      this.stop();

      this.track?.unload();

      if (destroyTrack) {
        this.track = null;
      }

      this.howlSoundId = null;
      this.playerBusy = false;
      this.playerLoading = false;
      this.startPlayPending = false;
      this.pausePending = false;
      this.initTrackOnPlay = false;
      this.playerReady = true; // so we can skip to next
    },

    initTrack (index) {
      if (this.track) {
        return;
      }
      if (this.autoplay) {
        this.playerBusy = true;
      }

      this.trackLoaded = false;

      this.duration = '-:--';

      const src = this.getAudioSrc(index);

      if (!src) {
        this.resetStateAndClosePlayer('Audio url missing');
        return;
      }

      const vm = this;
      this.track = new Howl({
        src,
        html5: true,
        onload () {
          console.log('[HOWL] track loaded');
          vm.onLoadHandler();
        },

        onunload () {
          console.log('[HOWL] track onunload');
          vm.clearInterval();
        },

        onplay (el) {
          console.log('[HOWL] track onplay', el);
          vm.onPlayHandler();
        },

        onpause (el) {
          console.log('[HOWL] track onpause', el);
          vm.onPauseHandler();
        },

        onstop () {
          console.log('[HOWL] track onstop');
          vm.isPlaying = false;
        },

        onend  () {
          console.log('[HOWL] track onend ');
          vm.onPauseHandler();
        },

        onunlock  () {
          console.log('[HOWL] track onunlock ');
        },

        onseek (el) {
          console.log('[HOWL] track onseek ', el);
        },

        onloaderror (e1, e2) {
          console.log('[HOWL] onloaderror', e1, e2, vm.track);
          const reInitPlayerCodes = [2,4];
          if (!reInitPlayerCodes.includes(e2)) {
            vm.resetStateAndClosePlayer(`[HOWL] onloaderror, ${e1}, ${e2}`);
          }
          vm.resetState(false);
          vm.initTrackOnPlay = true;
        },
        onplayerror  (e1, e2) {
          vm.resetState(false);
          vm.initTrackOnPlay = true;
          console.log('[HOWL] onplayerror', e1, e2);
        },
      });
      this.playerReady = true;

      this.currentTrackIndex = index;

      this.volume(this.track.volume(), true);

      this.startInterval();

      //  console.log('track ready!');
      this.$emit('track-ready');


      if (this.skipAutoplay) {
        this.playerBusy = false;
      }

      if (this.autoplay && !this.skipAutoplay) {
        setTimeout(() => {
          if (!this.isPlaying) {
            this.play();
          }
        }, this.autoplayDelay);
      }
    },

    // sound methods

    play (trackable) {
      if (trackable) {
        trackThis(TRACKING_PODCAST_PLAY, {}, this.currentTrack.id);
      }
      if (this.destroying || !this.track) {
        return;
      }

      if (this.initTrackOnPlay) {
        this.resetState();
        this.initTrack(this.currentTrackIndex);
        return;
      }

      if (this.startPlayPending) {
        // prevent multiple tracks playing
        // console.log('revent multiple tracks playing');
        return;
      }

      if (!this.track?.playing() && !this.isPlaying) {
        this.startPlayPending = true;

        if (this.howlSoundId) {
          const playId = this.track.play(this.howlSoundId);
          // console.log('playId', playId);
          if (!playId) {
            this.track.unload();
            this.howlSoundId = null;
            this.startPlayPending = false;
            this.play();
          }
        } else {
          const soundId = this.track.play();
          if (soundId) {
            this.howlSoundId = soundId;
          }
        }

        this.playerBusy = true;


        if (this.skipAutoplay) {

          this.$emit('autoplay-skipped');
        }

        // check player state after, in case of Howler lose callback
        const st1 = setTimeout(() => {
          if (this.track.playing() && !this.isPlaying) {
            this.onPlayHandler();
          }
        }, 500);

        const st2 = setTimeout(() => {
          if (this.startPlayPending) {
            this.startPlayPending = false;
            this.playerBusy = false;
            this.playerLoading = false;

            this.playedBeforePause = true;
          }
        }, 30000);

        this.fallbackSetTimeoutIds.push(st1, st2);
      }
    },

    volume (val, onlyProgressUpdate) {
      if (!onlyProgressUpdate) {
        this.track.volume(val);
      }

      const bar = this.$refs['track__volume__progress-bar'];

      if (bar) {
        bar.style.width = ((val * 100) || 0) + '%';
      }

    },

    seek (per) {
      if (this.destroying) {
        return;
      }

      if (this.isPlaying || this.track?.playing()) {
        const seek = this.track.seek() || 0;

        const bar = this.$refs['track__progress-bar'];
        bar.style.width = (((seek / this.track.duration()) * 100) || 0) + '%';
        this.timer = this.formatTime(Math.round(seek));
        this.emitCurrentTime(seek);
        return;
      }

      if (!this.trackLoaded) {
        this.track.load();
        this.queueAfterOnload.push({ arguments: [per], fn: this.seek });
        this.queueAfterOnload.push({ arguments: [], fn: this.play });
        return;
      }

      this.track.seek(this.track.duration() * per);

      const emitTime = this.track.seek();

      if (this.scrubbingForwardDisabled) {
        const partWatched = this.wasWatched(emitTime);

        if (!partWatched) {
          this.track.seek(this.progressLocalPart.end);
          return;
        }
      }

      this.emitCurrentTime(emitTime);
      this.updateCurrentProgress(true);
      this.$emit('seeked', emitTime, this.emittedTimeBeforeSeek, this.currentTrack?.id);

      this.emittedTimeBeforeSeek = emitTime;
    },

    stop () {
      if (this.track) {
        this.track.stop();
      }

      this.emitPlayState(false);
    },

    pause (trackable) {
      if (trackable) {
        trackThis(TRACKING_PODCAST_PAUSE, {}, this.currentTrack.id);
      }

      this.track.pause();

      this.pausePending = true;


      // fallback for bad network connection (reset busy state)
      const st1 = setTimeout(() => {
        if (!this.track?.playing() && this.isPlaying) {
          this.onPauseHandler();
        }
      }, 500);


      const st2 = setTimeout(() => {
        if (this.pausePending && !this.track?.playing() && this.isPlaying) {
          this.onPauseHandler();
        }
      }, 30000);

      this.fallbackSetTimeoutIds.push(st1, st2);
    },

    togglePlay () {
      if (this.playerLoading || this.playerBusy || !this.track) {
        return;
      }
      if (!this.track?.playing() && !this.isPlaying) {

        this.play(true);
        this.playerBusy = true;
        this.playerLoading = true;
        this.pausedByToggle = false;
      } else {
        this.playedBeforePause = true;
        this.pausedByToggle = true;
        this.playerBusy = true;
        //  console.log('pause in toggle play pause');
        this.pause(true);
      }
    },

    toggleVolume () {
      const currentVolume = this.track.volume();

      if (this.waitingForQueue) {
        return;
      }
      if (!this.trackLoaded) {
        this.track.load();
        this.queueAfterOnload.push({ arguments: [], fn: this.toggleVolume});
        this.waitingForQueue = true;
        return;
      }


      if (currentVolume) {
        this.volume(0);
      } else {
        this.volume(1);
      }
    },

    setProgressWidth (event, containerEl, progressEl, touchEvent) {
      let position = event.offsetX ?? undefined;

      if (touchEvent) {
        const rect = event.target.getBoundingClientRect();
        const x = event.targetTouches[0].pageX - rect.left;
        position = x < 0
          ? 0 : x > containerEl.offsetWidth
            ? containerEl.offsetWidth : x;
      }

      progressEl.style.width = (((position / containerEl.offsetWidth) * 100) || 0).toFixed(2) + '%';
      return position / containerEl.offsetWidth;
    },

    checkPlayerPausedState () {
      if (this.playedBeforePauseResumed) {
        this.playedBeforePause = this.isPlaying;
        this.playedBeforePauseResumed = false;
      }
    },

    // mouse events track

    trackProgressClicked (event, fromQueue) {
      if (this.scrubbingDisabled) return;

      this.progressActive = true;
      this.seeking = true;
      this.drawProgress = true;

      this.checkPlayerPausedState();

      if (this.isPlaying) {
        this.waitingForQueue = true;
        this.queueAfterPause.push({ arguments: [event, true], fn: this.trackProgressClicked });
        this.playerBusy = true;
        this.pausedByToggle = false;
        //  console.log('pause track progress clicked');
        this.pause();
      }

      if (this.startPlayPending) {
        this.waitingForPlayQueue = true;
        this.queueAfterPlay.push({ arguments: [event, true], fn: this.trackProgressClicked });
      }

      if (!fromQueue) {
        this.scrubbingTracking();
      }

      this.trackProgressMoving(event);
    },
    trackProgressMoving (event) {
      if (this.scrubbingDisabled) return;

      if (this.drawProgress) {
        const container = this.$refs['track__progress-container--wrapper'];
        const bar = this.$refs['track__progress-bar'];
        this.seekValue = this.setProgressWidth(event, container, bar);
      }
    },

    // touch events track
    trackProgressTouched (event, fromQueue) {
      if (this.scrubbingDisabled) return;

      this.progressActive = true;
      this.seeking = true;
      this.drawProgress = true;

      this.checkPlayerPausedState();

      if (this.isPlaying) {
        this.waitingForQueue = true;
        this.queueAfterPause.push({ arguments: [event, true], fn: this.trackProgressTouched });
        this.playerBusy = true;
        this.pausedByToggle = false;
        // console.log('pause track progress touched');
        this.pause();
      }

      if (this.startPlayPending) {
        this.waitingForPlayQueue = true;
        this.queueAfterPlay.push({ arguments: [event, true], fn: this.trackProgressTouched });
      }

      if (!fromQueue) {
        this.scrubbingTracking();
      }


      this.trackProgressTouching(event);
    },

    trackProgressTouching (event) {
      if (this.scrubbingDisabled) return;

      if (this.drawProgress) {
        const container = this.$refs['track__progress-container--wrapper'];
        const bar = this.$refs['track__progress-bar'];
        this.seekValue = this.setProgressWidth(event, container, bar, true);
      }
    },

    trackProgressStoppedTouching () {
      if (this.scrubbingDisabled) return;

      this.stopSeeking();
    },

    // mouse events volume

    trackVolumeClicked (event) {
      this.volumeProgressActive = true;
      this.seekingVolume = true;
      const bar = this.$refs.track__volume;
      const per = Math.min(1, Math.max(0, event.offsetX / bar.offsetWidth));
      this.volume(per);
    },

    trackVolumeMoving (event) {
      if (this.seekingVolume) {
        this.trackVolumeClicked(event);
      }
    },

    trackVolumeTouched (event) {
      this.volumeProgressActive = true;
      this.seekingVolume = true;
      const trackProgress = this.$refs.track__volume;
      const rect = event.target.getBoundingClientRect();
      const x = event.targetTouches[0].pageX - rect.left;
      const position = x < 0
        ? 0 : x > trackProgress.offsetWidth
          ? trackProgress.offsetWidth : x;

      const per = Math.min(1, Math.max(0, position / trackProgress.offsetWidth));
      this.volume(per);
    },
    trackVolumeTouching (event) {
      if (this.seekingVolume) {
        this.trackVolumeTouched(event);
      }
    },
    trackVolumeStoppedTouching () {
      this.stopSeeking();
    },

    stopSeeking: debounce(function () {
      if (this.waitingForQueue || this.waitingForPlayQueue) {
        return;
      }

      if (this.seeking) {
        this.seek(this.seekValue);
      }

      this.progressActive = false;
      this.volumeProgressActive = false;
      this.drawProgress = false;
      this.seeking = false;
      this.seekingVolume = false;

      if (this.playedBeforePause && !this.playedBeforePauseResumed && !this.pausedByToggle) {
        this.play();
      }
    }, 250, { trailing: true, leading: false }),

    // wrapper required to stop drawing on mouseup event
    seekingWrapper () {
      if (this.scrubbingDisabled) return;

      this.progressActive = false;
      this.volumeProgressActive = false;
      this.drawProgress = false;
      this.seekingVolume = false;

      this.stopSeeking();
    },

    onPlayHandler () {
      this.isPlaying = true;

      this.duration = this.formatTime(Math.round(this.track.duration()));
      this.emitDuration(this.track.duration());
      this.emitPlayState(true);

      this.startPlayPending = false;
      this.playerBusy = false;
      this.playerLoading = false;

      this.playedBeforePause = true;

      if (this.playedBeforePause) {
        this.playedBeforePauseResumed = true;
      }

      // read queue
      const uniqueCallbacks = [...new Set(this.queueAfterPlay.map(item => item.fn))];

      uniqueCallbacks.forEach((uniqueCallback) => {
        const lastCallback = this.queueAfterPlay.filter(item => item.fn === uniqueCallback).pop();
        if (lastCallback) {
          lastCallback.fn(...lastCallback.arguments);
          if (lastCallback.clearQueueBlock) {
            this.waitingForPlayQueue = false;
          }
        }
      });

      this.queueAfterPlay = [];
      this.waitingForPlayQueue = false;

      this.syncMusicControls({ isPlaying: true }, true);
    },

    onPauseHandler () {
      this.isPlaying = false;
      this.playerBusy = false;
      this.pausePending = false;

      this.emitPlayState(false);

      this.syncMusicControls({ isPlaying: false });

      const uniqueCallbacks = [...new Set(this.queueAfterPause.map(item => item.fn))];


      uniqueCallbacks.forEach((uniqueCallback) => {
        const lastCallback = this.queueAfterPause.filter(item => item.fn === uniqueCallback).pop();
        if (lastCallback) {
          // console.log(lastCallback.fn);
          lastCallback.fn(...lastCallback.arguments);
          if (lastCallback.clearQueueBlock) {
            this.waitingForQueue = false;
          }
        }
      });

      this.queueAfterPause = [];
      this.waitingForQueue = false;
    },

    onLoadHandler () {
      this.trackLoaded = true;

      const uniqueCallbacks = [...new Set(this.queueAfterOnload.map(item => item.fn))];

      uniqueCallbacks.forEach((uniqueCallback) => {
        const lastCallback = this.queueAfterOnload.filter(item => item.fn === uniqueCallback).pop();
        if (lastCallback) {
          lastCallback.fn(...lastCallback.arguments);
          if (lastCallback.clearQueueBlock) {
            this.waitingForQueue = false;
          }
        }
      });

      this.queueAfterOnload = [];
      this.waitingForQueue = false;
    },

    skipBy (val, absolute = false, trackable) {
      if (this.destroying) {
        return;
      }

      if (trackable) {
        const trackAction = val > 0 ? TRACKING_PODCAST_SKIP_SEC_FORWARD : TRACKING_PODCAST_SKIP_SEC_BACKWARD;
        trackThis(trackAction, {}, this.currentTrack.id);
      }

      this.checkPlayerPausedState();

      if (this.isPlaying) {
        this.waitingForQueue = true;

        // mouse up event came before this line fire, so we need to emulate it later
        this.queueAfterPause.push({ arguments: [val, absolute], fn: this.skipBy, clearQueueBlock: true });
        this.queueAfterPause.push({ arguments: [], fn: this.stopSeeking });
        this.pausedByToggle = false;
        this.playerBusy = true;
        this.pause();
        return;
      }

      if (this.startPlayPending) {
        this.waitingForPlayQueue = true;
        this.queueAfterPlay.push({ arguments: [val, absolute], fn: this.skipBy, clearQueueBlock: true });
        this.queueAfterPlay.push({ arguments: [], fn: this.stopSeeking });
        return;
      }

      const newPosition = absolute ? val : this.track.seek() + val;

      if (!this.trackLoaded) {
        // console.log('track load()');
        this.track.load();
        this.queueAfterOnload.push({ arguments: [val, absolute], fn: this.skipBy });
        if (!this.skipAutoplay) {
          this.queueAfterOnload.push({ arguments: [], fn: this.play });
        }

        return;
      }

      // console.log('seek to new position', newPosition);

      this.track.seek(newPosition);

      const seek = this.track.seek() || 0;



      const bar = this.$refs['track__progress-bar'];
      bar.style.width = (((seek / this.track.duration()) * 100) || 0) + '%';
      this.timer = this.formatTime(Math.round(seek));

      if (this.scrubbingForwardDisabled) {
        const partWatched = this.wasWatched(seek);

        if (!partWatched) {
          this.track.seek(this.progressLocalPart.end);
          // this.stopSeeking();
          return;
        }
      }

      if (newPosition.toFixed(2) === seek.toFixed(2)) {
        this.emitCurrentTime(seek);
        this.updateCurrentProgress(true);

        this.$emit('seeked', seek, this.emittedTimeBeforeSeek, this.currentTrack?.id);
        this.emittedTimeBeforeSeek = seek;
      }

      this.stopSeeking();
    },

    skipHandler (direction, trackable) {
      if (direction === - 1 && !this.canSkipBack) {
        return;
      }
      if (direction === 1 && !this.canSkipNext) {
        return;
      }

      this.skip(direction, trackable);
    },

    async skip (direction, trackable) {
      if (!this.playerReady) { return; }

      let newTrackIndex = 0;

      if (direction === - 1) {
        newTrackIndex = this.currentTrackIndex - 1;
        if (newTrackIndex < 0) {
          newTrackIndex = this.playlist.length - 1;
        }
      } else {
        newTrackIndex = this.currentTrackIndex + 1;
        if (newTrackIndex >= this.playlist.length) {
          newTrackIndex = 0;
        }
      }

      if (trackable) {
        const trackAction = direction > 0 ? TRACKING_PODCAST_SKIP_STREAM_FORWARD : TRACKING_PODCAST_SKIP_STREAM_BACKWARD;
        trackThis(trackAction, {
          moved_to_entity: this.playlist[newTrackIndex]?.id,
        }, this.currentTrack.id);
      }

      this.stop();
      await this.clearPlayerInterval();
      this.clearFallbackTimeouts();
      this.track?.unload();
      this.track = null;
      this.playerReady = false;
      this.playedBeforePause = false;
      this.howlSoundId = null;
      this.playedBeforePauseResumed = true; // check it
      this.emitNextTrack(newTrackIndex);

      this.initTrack(newTrackIndex);
    },

    step () {
      if (this.seeking) {
        return;
      }

      const seek = this.track.seek() || 0;
      const bar = this.$refs['track__progress-bar'];

      if (!bar) { return; }

      bar.style.width = (((seek / this.track.duration()) * 100) || 0).toFixed(2) + '%';
      this.timer = this.formatTime(Math.round(seek));

      if (this.isPlaying) {
        this.emitCurrentTime(seek);
      }

      this.emittedTimeBeforeSeek = seek;
      this.updateCurrentProgress();
    },

    formatTime (sec) {
      const minutes = Math.floor(sec / 60) || 0;
      const seconds = (sec - minutes * 60) || 0;

      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    },

    async createMusicControls () {
      return;
      const info = await Device.getInfo();
      const platform = info.platform;
      if (platform === 'web') { return; }



      const vm = this;

      CapacitorMusicControls.create({
        track       : this.currentTrack.title,        // optional, default : ''
        artist      : this.currentCompanyTitle,                       // optional, default : ''
        // cover       : 'https://s3.eu-central-1.amazonaws.com/s3.medischescholing.nl/course_images/39861/images/529fee51-1f23-4248-aa33-fd17db2ff3a7_small.jpeg',      // optional, default : nothing
        cover       : this.currentCover || this.defaultCoverPath || '',      // optional, default : nothing
        // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
        //           or a remote url ('http://...', 'https://...', 'ftp://...')
        isPlaying   : vm.isPlaying,                         // optional, default : true
        dismissable : true,                         // optional, default : false

        // hide previous/next/close buttons:
        hasPrev   : vm.canSkipBack,      // show previous button, optional, default: true
        hasNext   : vm.canSkipNext,      // show next button, optional, default: true
        hasClose  : false,       // show close button, optional, default: false

        // iOS only, optional
        //  album       : 'Absolution',     // optional, default: ''
        //  duration : 60, // optional, default: 0
        //  elapsed : 10, // optional, default: 0
        hasSkipForward :  vm.canSkipNext,  // show skip forward button, optional, default: false
        hasSkipBackward : vm.canSkipBack, // show skip backward button, optional, default: false
        skipForwardInterval: 15, // display number for skip forward, optional, default: 0
        skipBackwardInterval: 15, // display number for skip backward, optional, default: 0
        hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional

        // Android only, optional
        // text displayed in the status bar when the notification (and the ticker) are updated, optional
        ticker    : `Now playing ${this.soundTitle}`,
        // All icons default to their built-in android equivalents
        playIcon: 'media_play',
        pauseIcon: 'media_pause',
        prevIcon: 'media_prev',
        nextIcon: 'media_next',
        closeIcon: 'media_close',
        notificationIcon: 'notification',
      });

      if (!this.musicControlsCreated) {
        CapacitorMusicControls.addListener('controlsNotification', (info) => {
          this.handleControlsEvent(info);
        });
      }


      // Start listening for events
      // The plugin will run the events function each time an event is fired
      // CapacitorMusicControls.listen();

      this.musicControlsCreated = true;
    },

    handleControlsEvent(action){

      // console.log("hello from handleControlsEvent");
      const message = action.message;

      // console.log("message: " + message);

      switch(message) {
      case 'music-controls-next':
        this.skipHandler(1, true);
        // Do something
        break;
      case 'music-controls-previous':
        this.skipHandler(-1, true);
        // Do something
        break;
      case 'music-controls-pause':
        this.pause(true);
        // Do something
        break;
      case 'music-controls-play':
        this.play(true);
        // Do something
        break;

      case 'music-controls-destroy':
        // controls were destroyed
        break;

        // External controls (iOS only)
      case 'music-controls-toggle-play-pause' :
        // do something
        break;
      case 'music-controls-seek-to':
        // do something
        break;
      case 'music-controls-skip-forward':
        this.skipHandler(1, true);
        // Do something
        break;
      case 'music-controls-skip-backward':
        this.skipHandler(-1, true);
        // Do something
        break;

        // Headset events (Android only)
        // All media button events are listed below
      case 'music-controls-media-button' :
        // Do something
        break;
      case 'music-controls-headset-unplugged':
        // Do something
        break;
      case 'music-controls-headset-plugged':
        // Do something
        break;
      default:
        break;
      }
    },

    async syncMusicControls ({ isPlaying }, initControls) {
      return;
      if (initControls) {
        await this.createMusicControls();
      }

      if(this.musicControlsCreated && isPlaying !== null) {
        CapacitorMusicControls.updateIsPlaying({
          isPlaying,
        });
      }
    },

    seekToChapter(chapter) {
      const seconds = getSecondsFromHMSString(chapter.time_begin);
      if (seconds || seconds === 0) {
        this.skipBy(seconds, true);
      }
      this.showChapters = false;
    },

    chapterPlayed (chapter) {
      if (!chapter.time_end) {
        return false;
      }
      return getSecondsFromHMSString(chapter.time_end) < this.trackCurrentTime;
    },

    chapterPlaying (chapter) {
      if (getSecondsFromHMSString(chapter.time_begin) < this.trackCurrentTime && !chapter.time_end) {
        return true;
      }
      return getSecondsFromHMSString(chapter.time_begin) < this.trackCurrentTime && getSecondsFromHMSString(chapter.time_end) > this.trackCurrentTime;
    },

    destroyMusicControls () {
      return;
      if (this.musicControlsCreated) {
        CapacitorMusicControls.destroy();
      }
      this.musicControlsCreated = false;
    },

    emitDuration (trackDuration) {
      this.$emit('duration', trackDuration);
      this.$store.commit('player/setTrackInfo', { trackDuration });
    },

    emitCurrentTime (trackCurrentTime) {
    //  console.log('current time is', trackCurrentTime);
      this.trackCurrentTime = trackCurrentTime;
      this.$emit('time-update', trackCurrentTime);
      this.$store.commit('player/setTrackInfo', { trackCurrentTime });
    },

    emitPlayState (trackPlaying) {
      this.$emit('play-state', trackPlaying);
      this.$store.commit('player/setTrackInfo', { trackPlaying });
    },

    emitNextTrack (index) {
      this.$emit('new-track-index', index);
      this.$store.commit('player/seekStream', null);
      this.$store.commit('player/setPlaylistCurrentIndex', index);
      this.$store.dispatch('player/addRecentlyPlayed', { course: this.course, startAt: index });
    },

    emitRemoveFromRecently (streamId) {
      if (streamId) {
        this.$store.dispatch('player/removeFromRecentlyPlayed', streamId);
      }
    },

    resetStateAndClosePlayer (reason = ''){
      withScope(scope => {
        scope.setExtras({
          reason,
          data: this.$data,
          props: this.$props,
          currentTrack: this.currentTrack,
          currentTrackIndex: this.currentTrackIndex,
        });
        captureMessage('[ALERT] failed to load audio');
      });

      this.audioNotAvailableToast();
      this.resetState();
      this.emitRemoveFromRecently(this.currentTrack?.id);
      this.$store.dispatch('player/closePlayer');
    },

    async audioNotAvailableToast () {
      const toast =  await toastController.create({
        message: this.$t('player.audioNotAvailableAlert'),
        position: 'top',
        color: 'warning',
        buttons: [
          { text: this.$t('commonKeys.ok') },
        ],
      });
      return toast.present();
    },

    // scrubbing methods
    updateCurrentProgress (updateStart) {
      if (updateStart) {
        this.progressLocalPart.start = this.trackCurrentTime;
      }
      this.progressLocalPart.end = this.trackCurrentTime;
    },
    wasWatched (seconds) {
      const partWatchedInProgress = this.progress.find(chapter => chapter.start <= seconds && seconds <= chapter.end);
      const userContinuouslyWatchingCurrentPart = (this.progressLocalPart.start <= seconds && (seconds - 1) <= this.progressLocalPart.end);
      const startVideo = seconds >= 0 && seconds <= 1;
      return Boolean(partWatchedInProgress || userContinuouslyWatchingCurrentPart || startVideo);
    },

    clearFallbackTimeouts() {
      this.fallbackSetTimeoutIds.forEach(id => clearTimeout(id));
      this.fallbackSetTimeoutIds = [];
    },


    scrubbingTracking: debounce(function () {
      trackThis(TRACKING_PODCAST_SCRUBBING, {}, this.currentTrack.id);
    }, 250),
  },
};
</script>

<style lang="scss" scoped>
    $primary: #f37c82;
    $barBG: #ddd;
    $barSize: 8px;
    $chaptersBG: black;
    $chapterBG: #2E2E2E;
    $chaptersColor: #fff;
    $primaryColor: $primary;
    $progressBG: $primary;

    .track {
        position: relative;
        width: 100%;
        align-self: flex-start;
        padding: 5px 0 0;
        user-select: none;

        &__title {
            position: absolute;
            width: 100%;
            overflow: hidden;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__progress-timers {
            display: flex;
            justify-content: space-between;
            font-size: 0.8em;
        }

        &__progress-controls {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            /*margin-bottom: 1.5rem;*/

            &__seek {
                display: flex;
                align-items: center;
            }
        }

        &__progress-container {
            position: relative;
            padding: 7px 0;
            margin-top: 25px;
            overflow: hidden;
            cursor: pointer;
            &:hover .track__progress-bar::after {
                opacity: 1;
            };
            &:hover .track__progress-container--wrapper {
                overflow: visible;
            };
            &--wrapper {
                overflow: hidden;
                position: relative;
                height: $barSize;
                border-radius: $barSize;
                background: $barBG;
            }
        }

        &__progress-bar {
            position: absolute;
            width: 0;
            height: $barSize;
            border-radius: $barSize;
            background: $progressBG;
            z-index: 1;
            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -$barSize / 2;
                width: $barSize + 3px;
                height: $barSize + 3px;
                content: '';
                border-radius: 6px;
                background: $primaryColor;
                opacity: 0;
                transition: opacity .3s ease;
            }
            &--active {
                &::after {
                    top: calc(50% * 1.4);
                    transform: scale(1.4) translateY(-50%);
                }
            }
        }

        &__volume-container {
            position: absolute;
            transition: all .3s cubic-bezier(0.17, 0.72, 0.26, 1.23);
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            right: 0;
            &:hover {
                .track__volume-container--wrapper {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(-50%) scale(1);
                }
            }

            @media screen and (max-width: 768px){
                position: relative;
                width: 100%;
                text-align: right;
            }

            &--wrapper {
                visibility: hidden;
                opacity: 0;
                width: 100px;
                padding-top: 5px;
                padding-bottom: 5px;
                position: absolute;
                top: 50%;
                right: 100%;
                cursor: pointer;
                transition: all 0.2s ease;
                transform: translateY(-50%) scale(0);

                &:hover .volume-progress__bar::after {
                    opacity: 1;
                }
                &::before {
                    content: "";
                    position: absolute;
                    background: transparent;
                    user-select: none;
                    top: 0;
                    left: -100%;
                    right: 0;
                    bottom: 0;
                }

                @media screen and (max-width: 768px){
                    right: 38px;
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(-50%) scale(1);
                }
            }
        }

        &__volume {
            height: $barSize;
            border-radius: $barSize;
            background: $barBG;

            &:hover {
                .track__volume__progress-bar::after {
                    opacity: 1;
                }
            }

            &__progress-bar {
                height: $barSize;
                border-radius: $barSize;
                background: $progressBG;
                position: relative;
                width: 0;
                z-index: 1;
                &::after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -$barSize / 2;
                    width: $barSize + 3px;
                    height: $barSize + 3px;
                    content: '';
                    border-radius: 6px;
                    background: $primaryColor;
                    opacity: 0;
                    transition: opacity .3s ease;
                }

                &--active {
                    .track__volume__progress-bar::after {
                        top: calc(50% * 1.4);
                        transform: scale(1.4) translateY(-50%);
                    }
                }
            }
        }

        &__chapters-container {
            position: absolute;
            left: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            @media screen and (max-width: 768px){
                left: unset;
                right: 0;
            }
        }

        &__chapter {
            display: flex;
            align-items: center;
            background: $chapterBG;
            border-radius: 6px;
            padding: 0.85rem;


            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &__time {
                flex: 0 70px;
                text-align: center;
            }

            &__play {
              flex: 0 50px;
              text-align: center;
            }

            &__title {
                overflow: hidden;
                text-align: left;
                flex: 1;
            }
        }

        &__chapters__list {
            position: absolute;
            width: 100%;
            z-index: 50;
            bottom: 0;
            background: $chaptersBG;
            border-radius: 6px;
            color: $chaptersColor;
            padding-top: 14px;
            max-height: 70vh;
            overflow: auto;

            ul {
              padding: 0;
            }
        }

    }

    .skip-label {
        position: relative;
        &::after {
            content: attr(data-label);
            font-size: 0.875em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
        }
    }

    .inactive.skip-label {
        &::after {
            color: #c4c4c4;
        }
    }

    .play-loading {
        position: absolute;
        left: 50%;
        top: 50%;
        pointer-events: none;
        margin: -40px -40px -40px -35px;
        width: 70px;
        height: 70px;
        background-color: #fff;
        border-radius: 100%;
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
    }

    .play-icon {
        display: inline-block;
        transition: color 0.2s ease;
        font-size: 48px;
    }

    .skip-icon {
        display: inline-block;
        position: relative;
        font-size: 24px;
        transition: color 0.2s ease;

        &-label {
            font-size: 10px;
            position: absolute;
            top: 75%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .volume-icon {
        display: inline-block;
        font-size: 24px;
        height: 24px;
    }


    .has-text-dark {
        color: var(--ion-color-dark-tint);
    }

    @-webkit-keyframes sk-scaleout {
        0% { -webkit-transform: scale(0) }
        100% {
            -webkit-transform: scale(1.0);
            opacity: 0;
        }
    }
    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        } 100% {
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
              opacity: 0;
          }
    }

    .none-events {
      pointer-events: none;
    }

    .seek-buttons {
      width: 200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

</style>
