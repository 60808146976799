<template>
  <ion-app>
    <div :class="{ 'player-active': paddingBottom }">
      <ion-router-outlet />
    </div>
    <PlayerOverlay />
    <AppNotification />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, useBackButton } from '@ionic/vue';
import debounce from 'lodash/debounce';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useNetworkConnectionAlerts from "@/composables/useNetworkConnectionAlerts";
import usePushNotifications from "@/composables/usePushNotifications";
import useDeepLink from "@/composables/useDeepLink";
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';

import PlayerOverlay from "@/components/player/PlayerOverlay";
import AppNotification from "@/components/app/AppNotification";
import useVersionCheker from "@/composables/useVersionCheker";
import { onMounted, watch } from '@vue/runtime-core';
import { changeLocale } from '@/plugins/i18n';
import i18n from '@/plugins/i18n';
import auth from '@/plugins/auth';
// import useFirebase from "@/composables/useFirebase";
import useFirebase8 from "@/composables/useFirebase8";
import { checkPlatform } from "@/helpers/functions";
import useAppStorage from "@/composables/useAppStorage";


export default {
  name: 'App',
  components: {
    PlayerOverlay,
    AppNotification,
    IonApp,
    IonRouterOutlet,
  },

  computed: {
    paddingBottom (){
      return this.$store.state.player.playerActive;
    },
  },

  setup() {
    const store = useStore();
    const { migrateFromCapacitor2 } = useAppStorage();
    migrateFromCapacitor2();

    const { initFirebase, initDB: fireDB } = useFirebase8();
    // checkPlatform

    checkPlatform().then((platform) => {
      store.commit('app/setAppPlatform', platform);
    });

    // get app config
    store.dispatch('app/getAppConfig');

    store.commit('app/setAppState', true);
    // console.log('APP.vue setup, app/setAppState');

    store.dispatch('player/loadRecentlyPlayedProgressStorage');

    const route = useRoute();
    // internet connection alerts
    const { initAlerts } = useNetworkConnectionAlerts();
    initAlerts();

    // push notifications
    const { initNotifications, checkNotificationsGranted } = usePushNotifications();
    const logged = store.getters['authentication/isLogged'];
    if (logged) {
      initNotifications();
    }

    // app version check
    const { checkCurrentVersion } = useVersionCheker();
    checkCurrentVersion(1000);

    // firebase
    initFirebase();

    // deeplink

    const { subscribeLinkOpen } = useDeepLink();
    subscribeLinkOpen();

    App.addListener('appStateChange', ({ isActive }) => {
      // console.log('appStateChange, active:', isActive);
      store.commit('app/setAppState', isActive);

      if (isActive) {
        if (store.getters['authentication/isLogged']) {
          checkNotificationsGranted();
        }

        fireDB().goOnline();
      } else {
        // fireDB().goOffline();
      }
    });

    // if (logged) {
    //   signAnonymously().then(data => console.log('data is', data));
    // }


    // quick router nav fix
    const router = useRouter();
    const debouncedRouterBack = debounce(() => {
    // player popup check
      const playerActive = store.state.player.playerActive;
      const playerCollapsed = store.state.player.playerCollapsed;

      if (playerActive && !playerCollapsed) {
        store.commit('player/setPlayerCollapse', true);
        return;
      }

      router.back();
    }, 250, { trailing: true, leading: true });


    watch(route, () => {
      if (!route?.meta?.player) {
        console.log('[Alert]: player is not available in this route');
        store.dispatch('player/closePlayer');
      }
    },  { immediate: true, deep: true });

    useBackButton(1, debouncedRouterBack);

    onMounted(() => {
      if (logged && auth.user()?.language) {
        changeLocale(i18n, auth.user().language);
        store.commit('profile/changeLanguage', auth.user().language);
      }

      setTimeout(() => {
        SplashScreen.hide();
      }, 0);
    });

    return {
      initNotifications,
    };
  },
};
</script>


<style lang="scss">
  .player-active {
    ion-content {
      --offset-bottom: -78px !important;
    }
  }
</style>
