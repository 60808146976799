<template>
  <div class="company-details is-flex is-flex-direction-column">
    <div class="is-flex">
      <div
        class="company-logo pointer"
        @click="openDetailsPage"
      >
        <AppImg
          :img-src="banner"
          img-alt="banner"
          border-radius="10px"
        />
      </div>
      <div class="company-details-description is-flex is-flex-direction-column">
        <p
          class="company-name fw-500 fz-16 mb-2 pointer"
          @click="openDetailsPage"
        >
          {{ companyDetails.name }}
        </p>
        <div class="company-statistic fz-12 fw-300">
          <span v-if="companyDetails.total_series">{{ companyDetails.total_series }} series</span>
          <span
            v-if="companyDetails.total_series"
            class="bullet"
          >&bull;</span>
          <span v-if="companyDetails.total_series">{{ companyDetails.total_episodes }} {{ $t('podcast.episodes') }}</span>
          <span
            v-if="followers && followers > 0"
            class="bullet"
          >&bull;</span>
          <span v-if="followers && followers > 0">{{ followers }} {{ $t('commonKeys.subscribers') }}</span>
        </div>
      </div>
    </div>
    <div class="company-details-footer is-flex">
      <AppTooltip
        :label="$t('onboardingTooltips.followCompanyBtn')"
        position="is-left"
        :active="followBtnTooltip"
        :always="followBtnTooltip"
        wrapper-class="is-size is-multiline"
        @click:label="$emit('click:tooltip:label')"
      >
        <FollowButton :company-id="companyDetails.id"/>
      </AppTooltip>
    </div>
  </div>
</template>

<script>
import { IonButton, IonImg } from '@ionic/vue';
import AppImg from '@/components/common/AppImg.vue';
import FollowButton from '@/components/common/FollowButton.vue';
import { computed, ref } from '@vue/reactivity';
import { getSmallestSizeImage } from "@/helpers/functions";
import {watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import AppTooltip from "@/components/common/AppTooltip";

export default {
  name: 'CompanyDetails',
  components: {
    AppTooltip,
    IonButton,
    IonImg,
    AppImg,
    FollowButton,
  },
  props: {
    companyDetails: {
      type: Object,
      required: true,
    },

    redirectOnClick: {
      type: Boolean,
      default: true,
    },

    followBtnTooltip: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const followTriggerState = ref(0);
    const followers = computed(() => (props.companyDetails.user_follow_count ?? 0) + followTriggerState.value);

    watch(
      () => store.state.favorites.latestFollowChanges,
      (lastCompanyFollow) => {
        if (lastCompanyFollow.companyId === props.companyDetails.id) {
          lastCompanyFollow.followed ? followTriggerState.value++ : followTriggerState.value--;
        }
      },
    );

    const openDetailsPage = () => {
      if (props.redirectOnClick) {
        store.commit('player/setPlayerCollapse', true);
        router.push({
          name: 'Company',
          params: {
            companyId: props.companyDetails.id,
          },
        });
      }
    };

    const banner = computed(() => getSmallestSizeImage(props?.companyDetails?.media)?.url ?? null );
    return { banner, followers, followTriggerState, openDetailsPage };
  },
};
</script>

<style scoped lang="scss">
.company-details {
  justify-content: flex-end;
  /*.company-details-main {*/
  /*  column-gap: 10px;*/
  /*}*/
  .company-logo {
    border-radius: 10px;
    overflow: hidden;
    width: 65px;
    height: 65px;
    margin-right: 10px;
  }
}

.company-details-description {
  width: calc(100% - 65px - 10px);
  justify-content: center;
  .bullet {
    padding: 0 10px;
  }
}
.company-details-footer {
  justify-content: flex-end;
}
.translate-x-30 .company-details-footer {
  transform: translate(30px, 0);
}
</style>
