import axios from 'axios';

import { Device } from '@capacitor/device';

import { ME_URL, LOGOUT_URL, PROFILE_DELETE_ACCOUNT, PROFILE_ACCOUNT_DELETE_REQUEST_PIN } from "@/config/api";
import useAppStorage from "@/composables/useAppStorage";
const AUTH_ERROR_CODES = [401, 403];
const SENTRY_STATUS_CODES_IGNORE = [400, 401, 422, 403];

import { captureException } from '@sentry/vue';

const { setKey, getKey, removeKey } = useAppStorage();

const STORAGE_KEY = 'cached-user';
const CLEAR_COOKIE_ON_REQUEST = [LOGOUT_URL, PROFILE_DELETE_ACCOUNT, PROFILE_ACCOUNT_DELETE_REQUEST_PIN];


axios.defaults.headers.common['App-Platform'] = 'medicast';
Device.getInfo().then((info) => {
  axios.defaults.headers.common['App-Platform'] = `medicast-${info.platform}`;
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(config => {
  const base = window?.location?.pathname ?? '';
  const search = window?.location?.search ?? '';
  const route = base + search;

  const appUrl = process.env.VUE_APP_BASE_URL ?? '';

  if (route) {
    config.headers['X-Origin-Url'] = appUrl + base + search;
  }

  return config;
});

axios.interceptors.response.use( function (response) {
  if (response.config.url === ME_URL) {
    setKey(STORAGE_KEY, JSON.stringify(response.data));
  }

  if (CLEAR_COOKIE_ON_REQUEST.includes(response.config.url)) {
    removeKey(STORAGE_KEY);
  }

  return response;
}, async function (error) {
  if (!SENTRY_STATUS_CODES_IGNORE.includes(error?.response?.status)) {
    captureException(error);
  }

  const isUserRequest = error?.config?.url === ME_URL;
  const authError = AUTH_ERROR_CODES.includes(error?.response?.status);

  if ((isUserRequest && !authError)) {
    const cachedUserString = await getKey(STORAGE_KEY);
    if (cachedUserString) {
      const cachedUser = JSON.parse(cachedUserString);
      if (cachedUser) {
        return Promise.resolve({ data: cachedUser });
      }
    }
  }

  return Promise.reject(error);
});

export default axios;
