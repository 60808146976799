<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <div
      class="is-flex is-flex-direction-column"
      style="height: 100%"
    >
      <ion-header class="ion-padding-horizontal ion-padding-vertical">
        <h3 class="ion-text-center">
          {{ $t('versionAlert.title') }}
        </h3>
      </ion-header>

      <section class="ion-padding-vertical ion-padding-horizontal">
        <p class="ion-no-margin ion-margin-top">
          {{ $t('versionAlert.hardUpdateSubtitle') }}
        </p>

        <ion-button
          mode="ios"
          expand="block"
          class="ion-margin-top"
          @click="submit"
        >  {{ $t('commonKeys.update') }} </ion-button>
      </section>
    </div>
  </ion-content>
</template>

<script>
import { modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';

export default {
  name: "HardUpdateModal",
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    submit: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
