<template>
  <div
    v-if="possibleProfessions.length"
    class="profession-wrap"
  >
    <div
      v-for="profession in possibleProfessions"
      :key="profession.key"
    >
      <p class="fz-12">
        <span>{{ profession.profession_name }}</span>
        <span class="bullet">&bull;</span>
        <span>{{ profession.commission_prefix }}</span>
        <span
          v-if="showProfessionPoints(profession)"
          class="bullet"
        >&bull;</span>
        <span v-if="showProfessionPoints(profession)">
          {{ showProfessionPoints(profession) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import isNil from 'lodash/isNil';
import { useI18n } from 'vue-i18n/index';
import { useStore } from 'vuex';
import { IonButton, IonIcon } from '@ionic/vue';

export default {
  name: 'PossibleProfessions',
  components: {
    IonButton,
    IonIcon,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const getProfessions = computed(() => store.getters['course/getCourseData'].professions);
    const userProfessions = computed(() => store.getters['profile/getUserProfessions'].map(profession => profession.profession_id));


    const possibleProfessions = computed(() => {
      let uniqueProfessions = uniqBy(getProfessions.value, 'profession_id');
      uniqueProfessions = uniqueProfessions.map((profession) => {
        const newProfession = {
          ...profession,
        };
        const professions = getProfessions.value.filter((originProfession) =>
          originProfession.profession_id === newProfession.profession_id);

        const professions_points = uniq(professions.map((e) => e.points));

        if (professions_points.length > 1) {
          const profession_prefixes_points = professions.map((element) => {
            return `${element.commission_prefix} - ${element.points} ${t(
              'accreditation.points')}`;
          });

          newProfession.points = null;
          newProfession.commission_prefix = profession_prefixes_points.join(', ');

          return newProfession;
        }

        const profession_prefixes = professions.map(
          (element) => element.commission_prefix,
        );
        newProfession.commission_prefix = profession_prefixes.join(', ');

        return newProfession;
      });

      return uniqueProfessions.filter(profession => userProfessions.value.includes(profession.profession_id));
    });


    const showProfessionPoints = (profession) => {
      const pointText = profession.points > 1 ? t('accreditation.points') : t('accreditation.point');
      if (isNil(profession.points)) {
        return '';
      }
      return `${profession.points} ${pointText}`;
    };

    return {
      possibleProfessions,
      showProfessionPoints,
      userProfessions,
    };
  },
};
</script>

<style lang="scss" scoped>
.bullet {
  padding: 0 10px;
}
.profession-wrap {
  p {
    line-height: 1.5;
  }
}
</style>
