import axios from '@/http';

import {
  GET_RECENTLY_PLAYED_PAGE_URL,
  GET_RECENTLY_PLAYED_URL, NEW_COURSE_RELEASES,
} from "@/config/api";

const initializeStates = () => {
  return {
    recentlyPlayedDetails: [],

    recentlyPlayedPageList: [],
    pagination: {
      offset: 0,
      limit: 10,
    },

    totalRecords: 0,
    originCurrentRecordsCount: 0,
  };
};

export const state = () => initializeStates();

// getters

// actions
const actions = {
  setRecentlyPlayedDetails({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_RECENTLY_PLAYED_URL)
        .then(({ data }) => {
          commit('setRecentlyPlayedDetails', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setRecentlyPlayedPageList({ state, commit }, append) {
    if (append) {
      commit('setPagination', { offset: state.pagination.offset + state.pagination.limit });
    } else {
      commit('setPagination', { offset: 0 });
    }


    return new Promise((resolve, reject) => {
      const url = GET_RECENTLY_PLAYED_PAGE_URL + `?limit=${state.pagination.limit}&offset=${state.pagination.offset}`;
      axios
        .get(url)
        .then(({ data }) => {
          commit('setRecentlyPlayedPageList', { data: data.recently_played, append });
          commit('setRecentlyPlayedPageListTotal', data.totalRecords);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  setPagination (state, data) {
    Object.assign(state.pagination, data);
  },

  setRecentlyPlayedDetails(state, data) {
    state.recentlyPlayedDetails = data;
  },
  setRecentlyPlayedPageList(state, { data , append}) {
    if (append) {
      state.recentlyPlayedPageList.push(...data);
    } else {
      state.recentlyPlayedPageList = data;
    }
  },
  setRecentlyPlayedPageListTotal(state, data) {
    state.totalRecords = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
