import axios from "@/http";
import {GET_POPULAR, GET_POPULAR_CHANNELS, LIBRARY, NEW_PODCAST_RELEASES, RECOMMENDED_PODCASTS} from "@/config/api";

const initializeStates = () => {
  return {
    library: [],
    libraryAll: [],
    visibleCount: 0,
    pagination: 5,
    totalRecords: 0,

    popularStreamsWeekly: [],
    popularCourses: [],
    newPodcastReleases: [],
    recommendedStreams: [],
  };
};

export const state = () => initializeStates();

export const actions = {
  setLibrary ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(LIBRARY)
        .then(({ data }) => {
        //  console.log('library response, data');
          commit('setVisibleCount', 0);
          commit('setLibrary', data.library);
          commit('loadLibraryRecords');
          commit('favorites/setFavoritesCoursesIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          commit('favorites/setfollowedCompaniesIds', data.following_company_ids, {root: true});
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setPopularStreamsWeekly ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(GET_POPULAR, {
        params: {
          filter: 'weekly',
        },
      })
        .then(({ data }) => {
          commit('setPopularStreamsWeekly', data.popular_podcasts);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  setNewPodcastStreamReleases ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(NEW_PODCAST_RELEASES)
        .then(({ data }) => {
          commit('setNewPodcastStreamReleases', data.new_podcasts);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setPopularCourses ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(GET_POPULAR_CHANNELS, {
        params: {
          limit: 10,
        },
      })
        .then(({ data }) => {
          commit('setPopularCourses', data.popular_channels);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setRecommendedStreams ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios.get(RECOMMENDED_PODCASTS, {
        params: {
          limit: 20,
        },
      })
        .then(({ data }) => {
          commit('setRecommendedStreams', data.recommended);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const mutations = {
  setLibrary (state, data) {
    const uniqueCompanies = [...new Set(data.map(course => course.company_id))];
    const result = [];

    uniqueCompanies.forEach((companyId) => {
      const companyData = data.find(course => course.company_id === companyId)?.company ?? {};
      const companyCourses = data.filter(course => course.company_id === companyId);

      companyCourses.sort((a, b) => b.subscribers - a.subscribers);

      result.push({
        company: companyData,
        courses: companyCourses,
      });
    });

    result.sort((a, b) => {
      return b.company?.user_follow_count - a.company?.user_follow_count;
    });

    state.libraryAll = result;
    state.library = [];
  },

  loadLibraryRecords (state) {
    const newRecords = state.libraryAll.slice(state.visibleCount, state.visibleCount + state.pagination);
    // console.log(state.visibleCount, state.pagination);
    // console.log('new records', newRecords);
    state.library.push(...newRecords);
    state.visibleCount = state.library.length;
  },

  setVisibleCount (state, count) {
    state.visibleCount = count;
  },

  setPopularStreamsWeekly (state, data) {
    state.popularStreamsWeekly = data;
  },

  setPopularCourses (state, data) {
    state.popularCourses = data;
  },

  setNewPodcastStreamReleases (state, data) {
    state.newPodcastReleases = data;
  },

  setRecommendedStreams (state, data) {
    state.recommendedStreams = data;
  },


  resetMobileState (state) {
    Object.assign(state, initializeStates());
  },
};

export const getters = {
  getLibrary: state => state.library,
  getVisibleLibrary: state => state.library,
  getPopularStreamsWeekly: state => state.popularStreamsWeekly,
  getPopularCourses: state => state.popularCourses,
  getNewPodcastReleases: state => state.newPodcastReleases,
  getRecommendedStreams: state => state.recommendedStreams,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
