import axios from '@/http';

import {
  FAVOURITES_LIST,
  FOLLOWING_LIST,
  FOLLOW_COMPANY,
  UNFOLLOW_COMPANY,
} from "@/config/api";
import useAppStorage from "@/composables/useAppStorage";
import {TOOLTIP_STORAGE_KEYS} from "@/config/constants";

import has from 'lodash/has';


const { setKey, getKey } = useAppStorage();

const initializeStates = () => {
  return {
    rawFavoritesList: [],

    favoritesList: [],
    followedList: [],

    favoritesCoursesIds: [],
    favoritesStreamsIds: [],
    followedCompaniesIds: [],

    favoritesUpdated: false,
    followersUpdated: false,

    latestFollowChanges: {
      companyId: null,
      followed: null,
    },

    dataFetched: false,

    tooltips: {
      tooltip_favorite_viewed: false,
      tooltip_followed_viewed: false,
    },
  };
};

export const state = () => initializeStates();

// actions
const actions = {
  setFavoritesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(FAVOURITES_LIST)
        .then(({ data }) => {
          commit('setFavoritesCoursesIds', { data: data.favourite_course_ids, flatArray: false });
          commit('setFavoritesStreamsIds', { data: data.favourite_course_ids, flatArray: false });
          commit('setFavoritesList', data.favourite_courses);
          commit('setRawFavoritesList', data.favourite_courses);
          commit('setfollowedCompaniesIds', data.following_company_ids);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setFollowingList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(FOLLOWING_LIST)
        .then(({ data }) => {
          commit('setFavoritesCoursesIds', { data: data.favourite_course_ids, flatArray: false });
          commit('setFollowingList', data.company_following_courses);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unFollowCompany({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(UNFOLLOW_COMPANY.replace('{company_id}', companyId))
        .then(({ data }) => {
          commit('deleteFollowedCompaniesIds', companyId);
          commit('setFollowingUpdated', true);
          commit('setFollowingUpdatedData', { companyId, followed: false });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  followCompany({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(FOLLOW_COMPANY.replace('{company_id}', companyId))
        .then(({ data }) => {
          commit('addFollowedCompaniesIds', companyId);
          commit('setFollowingUpdated', true);
          commit('setFollowingUpdatedData', { companyId, followed: true });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setTooltipStorage({ commit }, data) {
    if (has(data, 'tooltip_followed_viewed')) {
      setKey(TOOLTIP_STORAGE_KEYS.FOLLOW, String(data?.tooltip_followed_viewed));
    }

    if (has(data, 'tooltip_favorite_viewed')) {
      setKey(TOOLTIP_STORAGE_KEYS.FAVORITE, String(data?.tooltip_favorite_viewed));
    }

    commit('updateTooltipState', data);
  },

  importTooltipStorage({ commit }) {
    getKey(TOOLTIP_STORAGE_KEYS.FOLLOW).then((result) => {
      commit('updateTooltipState', { tooltip_followed_viewed: result === 'true' });
    });
    getKey(TOOLTIP_STORAGE_KEYS.FAVORITE).then((result) => {
      commit('updateTooltipState', { tooltip_favorite_viewed: result === 'true' });
    });
  },
};

// mutations
const mutations = {
  setFavoritesCoursesIds (state, { data, flatArray, append }) {
    if (!flatArray) {
      const onlyCourses = data.filter(item => item.course_id && !item.stream_id);
      state.favoritesCoursesIds = [...new Set(onlyCourses.map(el => el.course_id))];
    } else {
      state.favoritesCoursesIds = data;
    }
  },

  setFavoritesStreamsIds (state, { data, flatArray, append }) {
    if (!flatArray) {
      state.favoritesStreamsIds = [...new Set(data.map(el => el.stream_id))].filter(Boolean);
    } else {
      state.favoritesStreamsIds = data;
    }
  },

  setFavoritesList(state, data) {
    const uniqueCompanies = [...new Set(data.map(course => course.company_id))];
    const result = [];
    uniqueCompanies.forEach((companyId) => {
      const companyData = data.find(course => course.company_id === companyId)?.company ?? {};

      const favoriteCourses = data.filter(course => course.company_id === companyId).filter(course => state.favoritesCoursesIds.includes(course.id));

      const allStreams = [];
      data.filter(course => course.company_id === companyId).forEach(course => allStreams.push(...course.streams));
      const favoriteStreams = allStreams.filter(stream =>  state.favoritesStreamsIds.includes(stream.id));

      result.push({
        company: companyData,
        courses: favoriteCourses,
        streams: favoriteStreams,
      });
    });

    state.favoritesList = result;
    state.dataFetched = true;
  },

  setFollowingList(state, data) {
    const uniqueCompanies = [...new Set(data.map(company => company.company_id))];

    const result = [];

    uniqueCompanies.forEach((companyId) => {
      const companyData = data.find(course => course.company_id === companyId)?.company ?? {};

      const followingCourses = data.filter(course => course.company_id === companyId);

      result.push({
        company: companyData,
        courses: followingCourses,
      });
    });

    result.sort((a, b) => {
      return b.company?.user_follow_count - a.company?.user_follow_count;
    });

    state.followedList = result;
  },
  setfollowedCompaniesIds (state, data) {
    state.followedCompaniesIds = data;
  },


  addFavoritesCourseId(state, id) {
    if (!state.favoritesCoursesIds.includes(id)) {
      state.favoritesCoursesIds.push(id);
    }
  },
  deleteFavoritesCourseId(state, id) {
    const index = state.favoritesCoursesIds.findIndex(el => el === id);
    if (index > -1) {
      state.favoritesCoursesIds.splice(index, 1);
    }
  },

  addFavoriteStreamsId(state, id) {
    if (!state.favoritesStreamsIds.includes(id)) {
      state.favoritesStreamsIds.push(id);
    }
  },
  deleteFavoriteStreamsId(state, id) {
    const index = state.favoritesStreamsIds.findIndex(el => el === id);
    if (index > -1) {
      state.favoritesStreamsIds.splice(index, 1);
    }
  },

  addFollowedCompaniesIds(state, id) {
    if (!state.followedCompaniesIds.includes(id)) {
      state.followedCompaniesIds.push(id);
    }
  },
  deleteFollowedCompaniesIds(state, id) {
    const index = state.followedCompaniesIds.findIndex(el => el === id);
    if (index > -1) {
      state.followedCompaniesIds.splice(index, 1);
    }
  },

  setFavoritesUpdated(state, value) {
    state.favoritesUpdated = value;
  },

  setFollowingUpdated(state, value) {
    state.followersUpdated = value;
  },

  setFollowingUpdatedData(state, data) {
    state.latestFollowChanges = data;
  },

  setRawFavoritesList(state, data) {
    state.rawFavoritesList = data;
  },

  updateTooltipState(state, data) {
    state.tooltips = Object.assign(
      {},
      state.tooltips,
      data,
    );
  },
};

// getters
export const getters = {
  getFavoritesList: state => state.favoritesList,
  getFavoritesCoursesIds: state => state.favoritesCoursesIds,
  getFavoritesStreamsIds: state => state.favoritesStreamsIds,
  getFollowingList: state => state.followedList,
  getFollowedCompaniesIds: state => state.followedCompaniesIds,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
