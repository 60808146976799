<template>
  <div class="track">
    <div class="track__progress-container">
      <div class="track__progress-container--wrapper">
        <div
          class="track__progress-bar"
          :style="`width: ${progress}%`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppProgress",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
  $primary: #f37c82;
  $barBG: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.3);
  $barSize: 4px;
  $chaptersBG: black;
  $chapterBG: #2E2E2E;
  $chaptersColor: #fff;
  $primaryColor: $primary;
  $progressBG: $primary;

  .track {
    position: relative;
    width: 100%;
    align-self: flex-start;
    user-select: none;


    &__progress-container {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &--wrapper {
        overflow: hidden;
        position: relative;
        height: $barSize;
        border-radius: $barSize;
        background: $barBG;
      }
    }

    &__progress-bar {
      position: absolute;
      width: 0;
      height: $barSize;
      border-radius: $barSize;
      background: $progressBG;
      z-index: 1;
    }
  }
</style>
