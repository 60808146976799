import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

export default () => {
  const openAppDetails =  () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return { openAppDetails };
};
