<template>
  <div v-if="currentKey === -1">
    <IonButton
      class="px-6"
      color="primary"
      fill="outline"
      mode="ios"
      outlined
      expand="block"
      @click="currentKey = 1"
    > {{ $t('verifyProfile.splitSelection.addAddition') }} </IonButton>

    <IonButton
      class="px-6"
      color="primary"
      fill="outline"
      mode="ios"
      outlined
      expand="block"
      @click="currentKey = 2"
    > {{ $t('verifyProfile.splitSelection.addAnotherBig') }} </IonButton>

    <IonButton
      class="px-6"
      color="primary"
      fill="outline"
      expand="block"
      mode="ios"
      outlined
      @click="currentKey = 3"
    > {{ $t('verifyProfile.splitSelection.addNonBig') }} </IonButton>
  </div>
  <div v-else>
    <IonButton
      class="px-6 is-pulled-right"
      color="primary"
      fill="outline"
      mode="ios"
      outlined
      size="small"
      style="z-index: 1"
      @click="backToStart"
    > {{ $t('commonKeys.back') }}</IonButton>

    <div class="animation-content mx-0">
      <UserProfessionsSelect
        v-if="currentKey === 1"
        :title="$t('verifyProfile.splitSelection.addAddition')"
        :footer-text="$t('verifyProfile.splitSelection.addAdditionHint')"
        :has-quiz-option="false"
        :professions-list="professionsAdditionsList"
        :selected-profession-ids="selectedProfessionIds"
        :add-user-profession-fn="addBignumberProfession"
        :layout="SELECTION_PROFESSIONS_LAYOUT.ADDITION_ONLY"
        @close="close"
      />
      <UserProfessionsSelect
        v-if="currentKey === 2"
        :title="$t('verifyProfile.splitSelection.addAnotherBig')"
        :has-quiz-option="false"
        :professions-list="professionsList"
        :selected-profession-ids="selectedProfessionIds"
        :add-user-profession-fn="addBignumberProfession"
        :layout="SELECTION_PROFESSIONS_LAYOUT.BIG_ONLY"
        @update:bignumber-details="$emit('update:bignumber-details', $event)"
        @close="close"
      />
      <UserProfessionsSelect
        v-if="currentKey === 3"
        :title="$t('verifyProfile.splitSelection.addNonBig')"
        :has-quiz-option="false"
        :professions-list="professionsList"
        :selected-profession-ids="selectedProfessionIds"
        :add-user-profession-fn="addBignumberProfession"
        :layout="SELECTION_PROFESSIONS_LAYOUT.PROFESSION_ONLY"
        @close="close"
      />
    </div>
  </div>
</template>

<script>
import { modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';
import UserProfessionsSelect from "@/components/profile/professions/UserProfessionSelect";
import {SELECTION_PROFESSIONS_LAYOUT} from "@/config/constants";

export default {
  name: "UserProfessionsSplitSelection",
  components: {
    UserProfessionsSelect,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    professionsList: {
      type: Array,
      default () {
        return [];
      },
    },
    addUserProfessionFn: {
      type: Function,
      required: true,
    },
    selectedProfessionIds: {
      type: Array,
      default () {
        return [];
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    professionsAdditionsList: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      currentKey: -1,
      SELECTION_PROFESSIONS_LAYOUT,
    };
  },

  watch: {
    currentKey: {
      immediate: true,
      handler(value) {
        this.$emit('update:current-key', value);
      },
    },
  },

  methods: {
    backToStart () {
      this.currentKey = -1;
    },
    async addBignumberProfession (object) {
      await this.addUserProfessionFn(object).then(() => {
        this.$emit('added');
      });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
