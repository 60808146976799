<template>
  <div>
    <ion-button
      :router-link="`/accreditation/${streamId}/${formResult.id}`"
      class="uppercase ion-margin-bottom"
      :fill="btnFill"
      mode="ios"
      expand="block"
      @click="openQuestions"
    >
      <div
        class="is-flex ion-align-items-center ion-justify-content-between w-100"
      >
        <div class="is-flex ion-align-items-center">
          <IonIcon :icon="btnLeftIcon" />
          <p class="pl-2">{{ btnText }}</p>
        </div>
        <div v-if="formResult.results">
          <IonIcon
            v-if="isAccreditationPassed"
            :icon="checkmarkDone"
            color="success"
          />
          <IonIcon
            v-else-if="allQuestionsAnswered"
            :icon="closeOutline"
            color="danger"
          />
          <p v-else-if="countQuestionsAnswers.completedQuestions">
            {{countQuestionsAnswers.completedQuestions + '/' + countQuestionsAnswers.allQuestions}}
          </p>
        </div>
        <IonIcon
          v-else-if="allQuestionsAnswered"
          :icon="checkmarkDone"
          color="success"
        />
        <p v-else-if="countQuestionsAnswers.completedQuestions">
          {{countQuestionsAnswers.completedQuestions + '/' + countQuestionsAnswers.allQuestions}}
        </p>
      </div>
    </ion-button>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n/index';
import { IonIcon, IonButton } from '@ionic/vue';
import {
  checkmarkDone,
  starHalfOutline,
  readerOutline,
  closeOutline,
} from 'ionicons/icons';
import { useStore } from 'vuex';

export default {
  name: 'ExamButton',
  components: {
    IonIcon,
    IonButton,
  },
  props: {
    formResult: {
      type: Object,
      required: true,
    },
    isAccreditationPassed: {
      type: Boolean,
      default: false,
    },
    streamId: {
      type: [Number, String],
      default: '',
    },
  },
  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const countQuestionsAnswers = computed (() => {
      const answeredQuestions = props.formResult.questions.filter((question) => question.answers.length && (question.is_correct !== false || !question.attempts_left));
      return {
        completedQuestions: answeredQuestions.length,
        allQuestions: props.formResult.questions.length,
      };
    });
    const allQuestionsAnswered = computed(() => {
      return countQuestionsAnswers.value.completedQuestions === countQuestionsAnswers.value.allQuestions;
    });

    const isExamFinished = computed(() => props.isAccreditationPassed ||allQuestionsAnswered.value);

    const btnText = computed(() => {
      if (props.formResult.type === 'accreditation') {
        if (props.isAccreditationPassed) {
          return t('accreditation.examBtnPassed');
        }
        else if (allQuestionsAnswered.value) {
          return t('accreditation.examBtnFailed');
        }
        else {
          if (countQuestionsAnswers.value.completedQuestions) {
            return t('accreditation.examBtn2');
          }
        }
        return t('accreditation.examBtn');
      }
      else {
        if (allQuestionsAnswered.value) {
          return t('accreditation.reviewBtnPassed');
        }
        if (countQuestionsAnswers.value.completedQuestions) {
          return t('accreditation.reviewBtn2');
        }
        return t('accreditation.reviewBtn');
      }
    });

    const btnFill = computed(() => {
      if (props.formResult.type === 'accreditation') {
        return isExamFinished.value ? 'outline' : 'solid';
      }
      else if (!countQuestionsAnswers.value.completedQuestions) {
        return 'solid';
      }
      return 'outline';
    });
    const btnLeftIcon = computed(() => {
      return props.formResult.type === 'accreditation' ? readerOutline : starHalfOutline;
    });

    const openQuestions = () => {
      store.commit('player/setPlayerCollapse', true);
    };

    return {
      checkmarkDone,
      starHalfOutline,
      readerOutline,
      closeOutline,
      btnText,
      openQuestions,
      btnFill,
      btnLeftIcon,
      countQuestionsAnswers,
      allQuestionsAnswered,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>