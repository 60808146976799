<template>
  <div class="podcast-player is-flex">
    <div
      v-if="hasPlay"
      class="play-circle-wrap is-flex"
    >
      <IonIcon
        class="pointer"
        :icon="isPlaying ? pauseCircle: playCircle"
        size="large"
        @click="$emit('toggle-play', $event)"
      />
    </div>
    <div
      v-if="progressSpend !== null && !hideProgress"
      class="player-progress is-flex is-flex-direction-column"
    >
      <AppProgress :progress="progressSpend" />
      <p
        v-if="hasLeftHint && timeLeft"
        class="player-left-time fw-300"
      >{{ timeLeft }}</p>
    </div>
    <IonIcon
      v-if="hasClose"
      class="pointer"
      :icon="close"
      size="large"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import { IonIcon, IonProgressBar } from '@ionic/vue';
import { playCircle, pauseCircle, close } from 'ionicons/icons';
import { useI18n } from 'vue-i18n/index';

import { computed, toRefs } from '@vue/reactivity';
import AppProgress from "@/components/common/AppProgress";

export default {
  name: 'PlayerReproduction',
  components: {
    AppProgress,
    IonIcon,
    IonProgressBar,
  },
  props: {
    duration: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    hasPlay: {
      type: Boolean,
      default: true,
    },
    hasLeftHint: {
      type: Boolean,
      default: true,
    },
    hideProgress: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { currentTime, duration } = toRefs(props);
    const progressSpend = computed(
      () => {
        if ((!currentTime.value && currentTime.value !== 0) || !duration.value && duration.value !== 0) {
          return null;
        }
        const progress = currentTime.value / duration.value;
        return isNaN(progress) ? 0: progress * 100;
      },
    );

    const minutesLeft = computed(() => {
      const secondsLeft = duration.value - currentTime.value;
      return Math.round(secondsLeft / 60);
    });


    const { t } = useI18n();

    const timeLeft = computed(() =>
    {
      return minutesLeft.value <= 0
        ? t('podcast.played')
        : minutesLeft.value + ' ' + (currentTime.value > 0 ?  t('podcast.minLeft') :  t('podcast.min'));
    },
    );
    return {
      playCircle,
      pauseCircle,
      close,
      minutesLeft,
      progressSpend,
      timeLeft,
    };
  },
};
</script>

<style scoped lang="scss">
.podcast-player {
  /*column-gap: 10px;*/
  align-items: center;
}

.podcast-player > *:not(:last-child) {
  margin-right: 10px;
}

.player-progress {
  align-items: flex-end;
  align-self: flex-end;
  /*row-gap: 4px;*/

  .progress-bar {
    width: 100%;
    border-radius: 12px;
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
.podcast-player,
.player-progress {
  width: 100%;
}
.play-circle-wrap {
  width: 32px;
}

ion-progress-bar::part(progress) {
  border-radius: 12px;
}
.player-left-time {
  font-size: 10px;
  line-height: 1.8;
}

</style>

<style lang="scss" scoped>
  $primary: #f37c82;
  $barBG: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.3);
  $barSize: 4px;
  $chaptersBG: black;
  $chapterBG: #2E2E2E;
  $chaptersColor: #fff;
  $primaryColor: $primary;
  $progressBG: $primary;

  .track {
    position: relative;
    width: 100%;
    align-self: flex-start;
    user-select: none;


    &__progress-container {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      &--wrapper {
        overflow: hidden;
        position: relative;
        height: $barSize;
        border-radius: $barSize;
        background: $barBG;
      }
    }

    &__progress-bar {
      position: absolute;
      width: 0;
      height: $barSize;
      border-radius: $barSize;
      background: $progressBG;
      z-index: 1;
    }

    &__volume-container {
      position: absolute;
      transition: all .3s cubic-bezier(0.17, 0.72, 0.26, 1.23);
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      right: 0;
      &:hover {
        .track__volume-container--wrapper {
          visibility: visible;
          opacity: 1;
          transform: translateY(-50%) scale(1);
        }
      }

      @media screen and (max-width: 768px){
        position: relative;
        width: 100%;
        text-align: right;
      }

      &--wrapper {
        visibility: hidden;
        opacity: 0;
        width: 100px;
        padding-top: 5px;
        padding-bottom: 5px;
        position: absolute;
        top: 50%;
        right: 100%;
        cursor: pointer;
        transition: all 0.2s ease;
        transform: translateY(-50%) scale(0);

        &:hover .volume-progress__bar::after {
          opacity: 1;
        }
        &::before {
          content: "";
          position: absolute;
          background: transparent;
          user-select: none;
          top: 0;
          left: -100%;
          right: 0;
          bottom: 0;
        }

        @media screen and (max-width: 768px){
          right: 38px;
          visibility: visible;
          opacity: 1;
          transform: translateY(-50%) scale(1);
        }
      }
    }
  }

  .skip-label {
    position: relative;
    &::after {
      content: attr(data-label);
      font-size: 0.875em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .inactive.skip-label {
    &::after {
      color: #c4c4c4;
    }
  }

  .play-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    pointer-events: none;
    margin: -40px -40px -40px -35px;
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }

  .play-icon {
    display: inline-block;
    transition: color 0.2s ease;
    font-size: 48px;
  }

  .skip-icon {
    display: inline-block;
    position: relative;
    font-size: 24px;
    transition: color 0.2s ease;

    &-label {
      font-size: 10px;
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .volume-icon {
    display: inline-block;
    font-size: 24px;
    height: 24px;
  }


  .has-text-dark {
    color: var(--ion-color-dark-tint);
  }

  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
      }
  }

  .none-events {
    pointer-events: none;
  }

  .seek-buttons {
    width: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

</style>
