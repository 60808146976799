<template>
  <div class="load-image">
    <slot
      v-if="status === 'loaded'"
      name="image"
    ></slot>
    <slot
      v-else-if="status === 'failed'"
      name="error"
    ></slot>
    <slot
      v-else-if="status === 'loading'"
      name="preloader"
    ></slot>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, onUpdated, watch } from '@vue/runtime-core';
export default {
  name: 'LoadImage',

  setup(_, {slots}) {
    const Status = {
      PENDING: 'pending',
      LOADING: 'loading',
      LOADED: 'loaded',
      FAILED: 'failed',
    };

    const status = ref(null);
    const img = ref(null);
    const src = ref(null);

    watch(() => src.value, (newValue) => {
      status.value = newValue ? Status.LOADING : Status.PENDING;
    });

    const createLoader = () => {
      destroyLoader();
      img.value = new Image();
      img.value.onload = handleLoad;
      img.value.onerror = handleError;
      img.value.src = src.value;
    };
    const destroyLoader = () => {
      if (img.value) {
        img.value.onload = null;
        img.value.onerror = null;
        img.value = null;
      }
    };
    const handleLoad = () => {
      destroyLoader();
      status.value = Status.LOADED;
    };
    const handleError = () => {
      destroyLoader();
      status.value = Status.FAILED;
    };

    const getImgElementFromImageSlot = () => {
      const imageSlot = slots.image && slots.image();
      if (imageSlot === undefined) {
        console.warn('Please define <template v-slot:image> slot in <load-image>');
        return;
      }
      return findImgElementFrom(imageSlot);
    };

    const findImgElementFrom = (slot) => {
      const queue = [...slot];
      while (queue.length > 0) {
        const child = queue.shift();
        if (
          child.type === 'img' ||
          (child.props && child.props['data-src'] != null)
        ) {
          return child;
        }
        if (child.children instanceof Array) {
          queue.push(...child.children);
        }
      }
      return null;
    };

    onMounted(() => {
      const imageElement = getImgElementFromImageSlot();
      if (imageElement == null) {
        return;
      }
      src.value = imageElement.props.src || imageElement.props['data-src'];
      if (src.value) {
        status.value = Status.LOADING;
        createLoader();
      } else {
        status.value = Status.PENDING;
      }
    });

    onUpdated(() => {
      const imageElement = getImgElementFromImageSlot();
      if (imageElement == null) {
        return;
      }
      const receivedSrc = imageElement.props.src || imageElement.props['data-src'];
      if (status.value === Status.LOADING && !img.value) {
        createLoader();
        return;
      }
      if (src.value !== receivedSrc) {
        src.value = receivedSrc;
        createLoader();
      }
    });

    return {
      status,
    };
  },
};
</script>