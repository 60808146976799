<template>
  <ion-button
    :="$attrs"
    mode="ios"
    color="light"
    fill="outline"
    size="small"
    class="uppercase --border-radius fz-12"
    @click="toggleFollowed"
  >
    {{ followText }}
  </ion-button>
</template>

<script>
import { IonButton, alertController } from '@ionic/vue';
import { heartOutline, heart } from 'ionicons/icons';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n/index';
import useRequestToast from '@/composables/useRequestToast';
import { useRouter } from "vue-router";

export default {
  name: 'FollowButton',
  components: {
    IonButton,
  },
  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
  },

  emits: ['unfollow', 'follow'],

  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const { openSuccessToast, openErrorToast } = useRequestToast();
    const router = useRouter();

    const isFollowed = computed(() => {
      const followedCompaniesIds =
        store.getters['favorites/getFollowedCompaniesIds'];
      return followedCompaniesIds.includes(props.companyId);
    });

    const followText = computed(() =>
      isFollowed.value ? t('following.unfollow') : t('following.follow'),
    );

    const toggleFollowed = () => {
      if (store.getters['authentication/isLogged']) {
        if (isFollowed.value) {
          deleteFavoritePrompt();
        } else {
          store
            .dispatch('favorites/followCompany', props.companyId)
            .then(() => {
              openSuccessToast(t('followCompanies.successfullyFollowed'));
              emit('follow');
            })
            .catch((err) => openErrorToast(err));
        }
      } else {
        store.dispatch('player/closePlayer');
        router.push('/login');
      }
    };

    const deleteFavoritePrompt = async () => {
      const alert = await alertController.create({
        header: t('followCompanies.deletePromptTitle'),
        message: t('followCompanies.deletePromptMessage'),
        buttons: [
          { text: t('followCompanies.deletePromptCancel') },
          {
            text: t('followCompanies.deletePromptConfirmText'),
            handler: deleteFavorite,
          },
        ],
      });
      return alert.present();
    };
    const deleteFavorite = () => {
      store
        .dispatch('favorites/unFollowCompany', props.companyId)
        .then(() => {
          openSuccessToast(t('followCompanies.removedFromFollowed'));
          emit('unfollow');
        })
        .catch((err) => openErrorToast(err));
    };

    return { heartOutline, heart, followText, toggleFollowed };
  },
};
</script>

<style scoped>
  ion-button {
    --padding-start: 16px;
    --padding-end: 16px;
  }
</style>