<template>
  <ion-content
    fullscreen
    class="top-safe-space"
  >
    <div
      class="is-flex is-flex-direction-column"
      style="height: 100%"
    >
      <ion-header class=" ion-padding-start ion-padding-vertical">
        <div class="is-flex ion-align-items-center">
          <h3>
            <b> {{ $t('verifyProfile.splitSelection.title') }} </b>
          </h3>
          <ion-buttons class="ml-auto">
            <ion-button
              class="modal-close-button"
              @click="close"
            >
              <ion-icon
                :icon="closeIcon"
                class="ion-margin-start"
              />
            </ion-button>
          </ion-buttons>
        </div>

        <p
          v-if="currentSectionKey === -1"
          class="mt-2 mb-4"
        >
          {{ $t('verifyProfile.splitSelection.subtitle') }}
        </p>

      </ion-header>

      <section class="ion-padding-vertical ion-padding-horizontal h-100">
        <UserProfessionsSplitSelection
          :professions-list="professionsList"
          :professions-additions-list="professionsAdditionsList"
          :selected-profession-ids="selectedProfessionIds"
          :add-user-profession-fn="addUserProfessionFn"
          @added="submit"
          @update:current-key="currentSectionKey = $event"
          @update:bignumber-details="$emit('update:bignumber-details', $event)"
          @close="$emit('close')"
        />
      </section>
    </div>
  </ion-content>
</template>

<script>
import { modalController,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonContent,
} from '@ionic/vue';

import { close as closeIcon } from 'ionicons/icons';

import UserProfessionsSplitSelection from "@/components/profile/professions/UserProfessionsSplitSelection";

export default {
  name: "EditSplitProfessionModal",
  components: {
    UserProfessionsSplitSelection,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonContent,
  },
  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    submitHandler: {
      type: Function,
      default: () => Promise.resolve(),
    },
    professionsList: {
      type: Array,
      default () {
        return [];
      },
    },
    professionsAdditionsList: {
      type: Array,
      default () {
        return [];
      },
    },
    addUserProfessionFn: {
      type: Function,
      required: true,
    },
    selectedProfessionIds: {
      type: Array,
      default () {
        return [];
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentSectionKey: -1,

      closeIcon,
    };
  },

  methods: {
    submit () {
      this.$emit('added');
    },
  },
};
</script>
