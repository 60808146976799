<template>
  <div
    :class="[{ 'bg-white': isBackgroundWhite && !isTransparent }, imgClasses]"
    class="img"
    style="width: 100%; height: 100%;"
  >
    <div
      v-if="getStatus === 'pending'"
      class="spinner-wrap is-flex ion-justify-content-center ion-align-items-center "
    >
      <!--      <IonSpinner />-->
    </div>
    <IonImg
      :src="imgSrc"
      :alt="imgAlt"
      class="main-img"
      :class="{ 'opacity-1': getStatus === 'loaded' }"
      :style="{ objectFit: imgFit, borderRadius, ...imgStyle }"
      @ionError="handleError"
      @ionImgDidLoad="handleLoad"
    />
    <!--    <IonImg-->
    <!--      v-if="getStatus === 'error'"-->
    <!--      :src="defaultImg"-->
    <!--      :alt="imgAlt"-->
    <!--      :style="{ objectFit: imgFit, borderRadius }"-->
    <!--      @ionImgDidLoad="handleDefaultLoad"-->
    <!--      class="h-100 err-img"-->
    <!--      :class="{ 'opacity-1': statusDefault === 'loaded' }"-->
    <!--    />-->
  </div>
</template>

<script>
import {  IonSpinner, IonImg } from '@ionic/vue';
import LoadImage from '@/components/common/LoadImage.vue';
import { computed, ref } from '@vue/reactivity';
import defaultImg from '/public/assets/default.jpg';

export default {
  name: 'AppImg',
  components: {
    IonSpinner,
    LoadImage,
    IonImg,
  },
  props: {
    imgSrc: {
      type: String,
      default: null,
    },
    imgAlt: {
      type: String,
      default: '',
    },
    imgFit: {
      type: String,
      default: 'cover',
    },
    loaderOnError: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '0',
    },
    imgStyle: {
      type: Object,
      default: () => ({}),
    },
    imgClasses: {
      type: [String, Array],
      default: '',
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const status = ref('pending');
    const statusDefault = ref(null);
    const getStatus = computed(() => {
      if (!props.imgSrc) {
        return props.loaderOnError ? 'pending' : 'error';
      }
      return status.value;
    });

    const handleError = () => {
      status.value = 'error';
    };
    const handleLoad = () => {
      status.value = 'loaded';
    };
    const handleDefaultLoad = () => {
      statusDefault.value = 'loaded';
    };
    const isBackgroundWhite = computed(() => getStatus.value === 'loaded' && props.imgSrc.slice(-3) === 'png' ? true : false);

    return {
      status,
      handleError,
      handleLoad,
      defaultImg,
      getStatus,
      handleDefaultLoad,
      statusDefault,
      isBackgroundWhite,
    };
  },
};
</script>

<style lang="scss" scoped>
.load-image {
  height: 100%;
  width: 100%;
}
.img {
  position: relative;
}
.main-img, .err-img {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
  transition: opacity .25s;
};
.absolute {
  position: absolute;
  top: 0;
}
.spinner-wrap {
  height: 100%;
  width: 100%;
}
ion-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.bg-white {
  background: var(--white);
}
.pb-100 {
  padding-bottom: 100%;
}
</style>
