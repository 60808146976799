<template>
  <IonIcon
    class="pointer"
    :="$attrs"
    :icon="isFavorite ? heart : heartOutline"
    :style="{ fontSize }"
    @click.stop="toggleFavourite"
  />
</template>

<script>
import { IonIcon, alertController } from '@ionic/vue';
import { heartOutline, heart } from 'ionicons/icons';
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import useRequestToast from '@/composables/useRequestToast';
import { useI18n } from 'vue-i18n/index';
import { useRouter } from "vue-router";

export default {
  name: 'FavouriteButton',
  components: {
    IonIcon,
  },
  props: {
    courseId: {
      type: [String, Number],
      required: true,
    },
    streamId: {
      type: [String, Number],
      default: '',
    },
    fontSize: {
      type: String,
      default: '1.5rem',
    },
  },

  emits: ['update:favorites'],

  setup(props, { emit }) {
    const store = useStore();
    const { openSuccessToast } = useRequestToast();
    const { t } = useI18n();
    const router = useRouter();

    const payload = computed(() => {
      return {
        course_id: props.courseId,
        user_id: store.getters['authentication/getUser'].id,
        stream_id: props.streamId,
      };
    });

    const isFavorite = computed(() => {
      if (props.streamId) {
        const favoritesStreamsIds = store.getters['favorites/getFavoritesStreamsIds'];
        return favoritesStreamsIds.includes(props.streamId);
      } else {
        const favoritesCoursesIds = store.getters['favorites/getFavoritesCoursesIds'];
        return favoritesCoursesIds.includes(props.courseId);
      }
    });

    const toggleFavourite = () => {
      if (store.getters['authentication/isLogged']) {
        if (isFavorite.value) {
          deleteFavoritePrompt();
        } else {
          props.streamId
            ? store.dispatch('course/favoriteStream', payload.value)
            : store.dispatch('course/favoriteCourse', payload.value);
          openSuccessToast(t('savedCourses.courseSavedMessage'));
          emit('update:favorites');
        }
      } else {
        store.dispatch('player/closePlayer');
        router.push('/login');
      }
    };

    const deleteFavoritePrompt = async() => {
      const alert = await alertController
        .create({
          header: t('savedCourses.dialogPromptTitle'),
          message: t('savedCourses.dialogPromptMessage'),
          buttons: [
            { text: t('savedCourses.dialogCancelText') },
            {
              text: t('savedCourses.dialogConfirmText'),
              handler: deleteFavorite,
            },
          ],
        });
      return alert.present();
    };
    const deleteFavorite = () => {
      return props.streamId
        ? store.dispatch('course/unfavoriteStream', payload.value).then(() => emit('update:favorites'))
        : store.dispatch('course/unfavoriteCourse', payload.value).then(() => emit('update:favorites'));
    };

    return { heartOutline, heart, toggleFavourite, isFavorite, deleteFavoritePrompt };
  },
};
</script>
