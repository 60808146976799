import axios from '@/http';
import {
  COURSE_DETAILS,
  PUBLIC_COURSE_IS_FAVORITE,
  USER_COURSES,
  DELETE_USER_COURSE,
  DELETE_USER_STREAM, COMPANY_DETAILS, COMPANY_POPULAR, COMPANY_RELEASES, COMPANY_LIBRARY, LIBRARY,
} from '@/config/api';

const initializeStates = () => {
  return {
    companyData: { id: 0 },
    popular: [],

    newReleases: [],


    library: [],
    libraryAll: [],

    libraryVisibleCount: 0,
    libraryPagination: 5,
    libraryTotalRecords: 0,
  };
};

export const state = () => initializeStates();

export const actions = {
  setCompanyDetails({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      const url = COMPANY_DETAILS.replace('{company_id}', companyId);
      axios
        .get(url)
        .then(({ data }) => {
          commit('setCompanyDetails', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setCompanyPopular({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      const url = COMPANY_POPULAR.replace('{company_id}', companyId);
      axios
        .get(url)
        .then(({ data }) => {
          commit('setCompanyPopular', data.popular_podcasts);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setCompanyReleases({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      const url = COMPANY_RELEASES.replace('{company_id}', companyId) + '?limit=10&offset=0';
      axios
        .get(url)
        .then(({ data }) => {
          commit('setCompanyReleases', data.new_releases);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setCompanyLibrary({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      const url = COMPANY_LIBRARY.replace('{company_id}', companyId);
      axios
        .get(url)
        .then(({ data }) => {
          commit('setVisibleCount', 0);
          commit('setCompanyLibrary', data.library);
          commit('loadLibraryRecords');
          commit('favorites/setFavoritesCoursesIds', { data: data.favourite_course_ids, flatArray: false }, {root: true});
          commit('favorites/setfollowedCompaniesIds', data.following_company_ids, {root: true});
          resolve(data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const mutations = {
  setCompanyDetails(state, data) {
    state.companyData = data;
  },
  setCompanyPopular(state, data) {
    state.popular = data;
  },
  setCompanyReleases(state, data) {
    state.newReleases = data;
  },
  setCompanyLibrary(state, data) {
    state.libraryAll = data;
    state.library = [];
  },

  loadLibraryRecords (state) {
    const newRecords = state.libraryAll.slice(state.libraryVisibleCount, state.libraryVisibleCount + state.libraryPagination);
    state.library.push(...newRecords);
    state.libraryVisibleCount = state.library.length;
  },

  setVisibleCount (state, count) {
    state.libraryVisibleCount = count;
  },

  setCourseTrackingState(state, data) {
    state.courseTrackingEnabled = data;
  },
  resetState (state) {
    Object.assign(state, initializeStates());
  },
};

export const getters = {
  getCompanyData: (state) => state.companyData,
  getCompanyPopular: (state) => state.popular,
  getCompanyReleases: (state) => state.newReleases,
  getCompanyLibrary: (state) => state.library,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
