import { createAuth }          from '@websanova/vue-auth/src/v3.js';
import driverAuthBearer      from './auth_drivers/bearer';
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from './auth_drivers/vue-router';

import router from "../router";
import axios from '../http';

import { LOGIN_URL, LOGOUT_URL, ME_URL} from "../config/api";

const auth = createAuth({
  plugins: {
    http: axios,
    router: router,
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: driverRouterVueRouter,
  },
  options: {
    rolesKey: 'type',
    tokenDefaultKey: 'access_token',
    refreshData: false,
    notFoundRedirect: '/',
    authRedirect: '/login',
    loginData: {
      url: LOGIN_URL,
      method: 'POST',
      redirect: '/',
      fetchUser: true,
      staySignedIn: true,
    },
    logoutData: {
      url: LOGOUT_URL,
      method: 'DELETE',
      redirect: '/login',
      makeRequest: true,
    },
    fetchData:  {
      url: ME_URL,
      method: 'GET',
      enabled: true,
    },
    parseUserData(data) {
      return data.user;
    },
  },
});

export default auth;
