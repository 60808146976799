<template>
  <div class="recently">
    <div
      v-if="recentlyPlayedFinal.length"
      class="is-flex ion-justify-content-between ion-align-items-center"
    >
      <h1> {{ $t('home.continueWatching') }}</h1>
      <ion-button
        fill="clear"
        color="light"
        class="fz-12 fw-300"
        router-link="/continue-watching"
      >{{ $t('home.recently') }}</ion-button>
    </div>


    <div class="recently-wrap">
      <ion-slides
        :options="slideOptions"
        :key="sliderKey"
      >
        <ion-slide
          v-for="data in recentlyPlayedFinal"
          :key="data.id"
        >
          <RecentlySinglePodcast
            :podcast-data="data"
            :key="`${data.id}-card`"
          />
        </ion-slide>
      </ion-slides>
    </div>
  </div>
</template>

<script>
import { IonSlides, IonSlide, IonButton } from '@ionic/vue';
import RecentlySinglePodcast from '@/components/common/RecentlySinglePodcast.vue';
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
import { ref } from '@vue/reactivity';
import {watch} from "@vue/runtime-core";


export default {
  name: 'RecentlyPlayedSlider',
  components: {
    IonSlides,
    IonSlide,
    IonButton,
    RecentlySinglePodcast,
  },

  setup() {
    const store = useStore();
    const sliderKey = ref(Math.random());

    const slideOptions = {
      slidesPerView: 'auto',
    };

    const recentlyPlayedData = computed(() => store.state.recentlyPlayed.recentlyPlayedDetails);

    const sortedRecentlyData = computed(() => [...recentlyPlayedData.value]);


    const recentlyPlayedFinal = computed(() => {
      const commonArray = [...sortedRecentlyData.value, ...store.state.player.recentlyPlayed];
      commonArray.sort((a, b) => b.progress_updated_timestamp - a.progress_updated_timestamp);
      return commonArray.filter((item, index, array) => {
        return array.findIndex(v => v.id === item.id) === index;
      }).slice(0, 10);
    });

    watch(() => recentlyPlayedFinal.value, () => {
      sliderKey.value = Math.random();
    } );


    return { slideOptions, recentlyPlayedData, sortedRecentlyData, recentlyPlayedFinal, sliderKey };
  },
};
</script>

<style scoped lang="scss">
.recently-wrap ion-slide {
  text-align: left;
  max-width: 310px !important;
  margin: 0px 16px 0px 0px;
}
</style>
