<template>
  <div class="is-flex ion-margin-bottom">
    <app-input
      :icon="mailOutline"
      :placeholder="$t('profile.emailLabel')"
      style="width: 100%"
      readonly
      :value="userEmail"
    >
      <template #inner-right-addon>
        <IonIcon
          :icon="checkmarkDone"
          :color="emailVerified ? 'success' : null"
          class="mr-4"
        />
      </template>
    </app-input>
  </div>

  <div class="is-flex is-flex-direction-column ion-justify-content-center ion-align-items-center">
    <ion-button
      mode="ios"
      class="uppercase --border-radius account-btn ion-margin-vertical"
      fill="outline"
      @click="changeEmail"
    >
      {{ $t('profile.changeEmail') }}
    </ion-button>
    <ion-button
      v-if="!emailVerified"
      mode="ios"
      class="uppercase --border-radius account-btn"
      fill="outline"
      @click="verifyEmail"
    >
      {{ $t('profile.resetEmailBtnText') }}
    </ion-button>
  </div>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import { mailOutline, checkmarkDone, closeOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
import useRequestToast from "@/composables/useRequestToast";
import { useI18n } from 'vue-i18n';


import {
  IonButton,
  IonIcon,
  modalController,
} from '@ionic/vue';
import ProfileEmailChangeModal from "@/components/profile/email/ProfileEmailChangeModal";

export default {
  name: "ProfileEmail",

  components: {
    IonIcon,
    IonButton,
    AppInput,
  },

  setup () {
    const store = useStore();
    const emailVerified = computed( () => store.getters['authentication/getUser'].email_verified_at);
    const userEmail = computed( () => store.getters['authentication/getUser'].email);

    const changeEmail = async () => {
      const modal = await modalController.create({ component: ProfileEmailChangeModal });
      await modal.present();
    };

    const { openSuccessToast } = useRequestToast();

    const { t } = useI18n();
    const verifyEmail = () => {
      store.dispatch('profile/verifyEmail')
        .then(() => {
          openSuccessToast(t('profile.emailResendNotification'));
        });
    };

    return {
      verifyEmail,
      changeEmail,
      userEmail,
      emailVerified,
      checkmarkDone, closeOutline, mailOutline,
    };
  },
};
</script>
