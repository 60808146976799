import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { modalController, toastController } from "@ionic/vue";
import { ref } from '@vue/reactivity';
import { useI18n }  from "vue-i18n/index";
import { useStore } from 'vuex';
import useInAppBrowser from "@/composables/useInAppBrowser";

import { waitTime } from "@/helpers/functions";

import VersionAlertModal from "@/components/app/VersionAlertModal";
import VersionUpdateModal from "@/components/app/VersionUpdateModal";

const iosAppStore = 'https://itunes.apple.com/';
const androidAppStore = 'https://play.google.com/store/apps';

export default () => {
  const store = useStore();
  const { openUrl } = useInAppBrowser();
  const { t } = useI18n();

  const appUpdateURL = ref(null);

  const updateApp = async () => {
    const url = appUpdateURL.value;
    openUrl(url, '_system');
  };

  const isHardUpdate = (currentAppVersion, latestAppVersion) => {
    const currentMajorV = currentAppVersion.split('.')[0];
    const latestMajorV = latestAppVersion.split('.')[0];
    return currentMajorV < latestMajorV;
  };

  const createToast = async () => {
    const toast =  await toastController.create({
      message: t('versionAlert.title'),
      position: 'top',
      color: 'light',
      buttons: [
        {
          text: t('commonKeys.update'),
          handler: updateApp,
        },
        {
          text: t('commonKeys.close'),
          role: 'Cancel',
        },
      ],
    });
    return toast.present();
  };

  const checkCurrentVersion = async (delay = 0) => {
    if (delay) {
      await waitTime(delay);
    }
    const latestVersions = await store.dispatch('app/getLatestVersion');
    const info = await Device.getInfo();
    const platform = info.platform;

    if (platform === 'web') { return; }

    const appInfo = await App.getInfo();
    const appVersion = appInfo.version;

    const currentAppVersion = appVersion || '1.1.1';
    const latestAppVersion = (platform === 'android' ? latestVersions.latest_android_version : latestVersions.latest_ios_version) || '1.1.1';

    // update app url
    if (platform === 'android') {
      appUpdateURL.value = latestVersions.latest_android_store_url || androidAppStore;
    } else {
      appUpdateURL.value = latestVersions.latest_ios_store_url || iosAppStore;
    }

    const versionIsBehind = currentAppVersion < latestAppVersion;

    if (!versionIsBehind) { return; }

    const hardUpdate = isHardUpdate(currentAppVersion, latestAppVersion);

    if (hardUpdate) {
      const modal = await modalController.create({
        component: VersionAlertModal,
        componentProps: {
          submit: updateApp,
        },
        backdropDismiss: false,
        keyboardClose: false,
      });
      await modal.present();
    } else {
      const modal = await modalController.create({
        component: VersionUpdateModal,
        componentProps: {
          submit: updateApp,
        },
        backdropDismiss: false,
        keyboardClose: false,
      });
      await modal.present();
    }
  };
  const getCurrentVersion = async () => {
    const latestVersions =  await store.dispatch('app/getLatestVersion');
    const info = await Device.getInfo();
    const platform = info.platform;

    if (platform !== 'web') {
      const latestAppVersion =  (platform === 'android' ? latestVersions.latest_android_version : latestVersions.latest_ios_version) || '1.1.1';
      return latestAppVersion;
    }
    return null;
  };

  return {
    versionToast: createToast,
    checkCurrentVersion,
    getCurrentVersion,
  };
};
