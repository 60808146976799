<template>
  <div
    v-if="releases.length"
    class="new"
  >
    <div class="new-header is-flex ion-justify-content-between ion-align-items-center">
      <h1 class="">{{ $t('home.new') }}</h1>
      <ion-button
        fill="clear"
        color="light"
        class="fz-12 fw-300"
        router-link="/main/library?tab=new-releases"
      >{{ $t('home.view') }}</ion-button>
    </div>

    <ion-slides
      :options="sliderOptions"
      :key="Math.random()"
    >
      <ion-slide
        v-for="(data, i) in releases"
        :key="`new-${i}`"
        class="ion-justify-content-start"
      >
        <NewPodcast
          class="podcast-item"
          :new-podcast="data"
          @click="$router.push(`/course/${data.id}`)"
        />
      </ion-slide>
    </ion-slides>
  </div>
</template>

<script>
import NewPodcast from '@/components/common/NewPodcast.vue';
import { IonSlide, IonSlides, IonButton } from "@ionic/vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";

export default {
  name: "NewReleasesCarousel",

  components: {
    NewPodcast,
    IonSlides,
    IonSlide,
    IonButton,
  },


  setup () {
    const sliderOptions = { slidesPerView: 'auto', slideShadows: true, slidesPerColumn: 2 };

    const store = useStore();
    const releases = computed(() => store.getters['newReleases/getNewReleasesHome']);

    return {
      releases,
      sliderOptions,
    };
  },
};
</script>

<style scoped lang="scss">
  ion-slide {
    max-width: 172px !important;
    min-width: 172px !important;
  }
</style>
