<template>
  <div>
    <h2
      v-if="showTitle"
      class="title has-primary-underline-half has-text-weight-medium"
      :class="{'has-text-white': isDark}"
    >
      {{ $t('verifyProfile.myProfessions') }}
    </h2>

    <div class="is-relative pb-5">
      <div
        v-if="computedProfessions.length === 0"
        class="mb-2"
      >
        {{ $t('verifyProfile.noProfessions') }}
      </div>
      <div
        v-for="(professionObj, index) in computedProfessions"
        :key="`${professionObj.id}-${index}`"
        class="mb-5"
      >
        <ion-row :key="`${professionObj.id}-${index}-profession`">
          <ion-col size="11">
            <AppInput
              type="text"
              readonly
              disabled
              expanded
              :value="professionObj.profession_details && professionObj.profession_details.name"
              name="profession"
            />
          </ion-col>

          <ion-col
            size="1"
            class="action-col"
          >
            <IonIcon
              class="pointer"
              :icon="removeCircle"
              @click="deletePrompt(professionObj)"
            />
          </ion-col>
        </ion-row>

        <ion-row v-if="!isBigAdditionProfession(professionObj)">
          <ion-col size="11">
            <div
              v-if="professionObj.bignumber"
              :key="`${professionObj.id}-${index}-bignumber`"
              class="is-flex"
            >
              <app-input
                type="text"
                readonly
                disabled
                expanded
                :value="professionObj.bignumber.big_number"
                class="w-100"
              >
                <template #inner-right-addon>
                  <IonIcon
                    :icon="checkmarkDone"
                    :color="!professionObj.bignumber.bignumber_check_required ? 'success': null"
                    class="icon right-icon mr-4"
                  />
                </template>
              </app-input>
              <!--              <div-->
              <!--                v-if="canEditProfessionComputed && professionObj.bignumber.bignumber_check_required"-->
              <!--                class="is-flex is-align-items-center ml-1"-->
              <!--              >-->
              <!--                &lt;!&ndash;              <BIcon&ndash;&gt;-->
              <!--                &lt;!&ndash;                icon="refresh"&ndash;&gt;-->
              <!--                &lt;!&ndash;                @click.native="openBignumberEditModal(professionObj)"&ndash;&gt;-->
              <!--                &lt;!&ndash;              />&ndash;&gt;-->
              <!--              </div>-->
            </div>
            <div
              v-else
              :key="`${professionObj.id}-${index}-regnumber`"
            >
              <AppInput
                type="text"
                readonly
                disabled
                :value="professionObj.registration_number"
              />
            </div>
          </ion-col>
          <IonCol
            size="1"
            class="action-col"
          />
          <!--            <IonIcon :icon="removeCircle"/>-->
        </ion-row>
        <p
          v-if="professionMessages.length"
          class="px-2"
        >
          <ion-text :color="getProfessionMessagesType(professionObj.profession_id)">
            <small> {{ getProfessionMessages(professionObj.profession_id) }}</small>
          </ion-text>
        </p>
      </div>

      <ion-modal
        :is-open="professionSplitSelectOpen"
        @didDismiss="professionSplitSelectOpen = false"
      >
        <EditSplitProfessionModal
          :professions-list="professionsList"
          :professions-additions-list="professionsAdditions"
          :add-user-profession-fn="addUserProfessionFn"
          :selected-profession-ids="computedProfessions.map(profession => profession.profession_id)"
          :show-title="false"
          @update:bignumber-details="$emit('update:bignumber-details', $event)"
          @added="fetchUserProfessions"
          @close="professionSplitSelectOpen = false"
        />
      </ion-modal>

      <ion-modal
        :is-open="professionSelectOpen"
        @didDismiss="professionSelectOpen = false"
      >
        <EditProfessionModal
          :professions-list="professionsList"
          :add-user-profession-fn="addUserProfessionFn"
          :selected-profession-ids="computedProfessions.map(profession => profession.profession_id)"
          :submit-handler="fetchUserProfessions"
          :show-title="false"
          @update:bignumber-details="$emit('update:bignumber-details', $event)"
        />
      </ion-modal>

      <div class="ion-text-center">
        <ion-button
          v-if="canAddProfessionComputed"
          mode="ios"
          fill="outline"
          shape="round"
          @click="openProfessionSelectModal"
        >
          {{ $t('verifyProfile.profession.add') }}
        </ion-button>
      </div>
      <IonLoading :is-open="fetchingUserProfessions"/>
    </div>

    <!--    <b-modal-->
    <!--      v-model:active="professionSelectOpen"-->
    <!--      :width="480"-->
    <!--      :can-cancel="['escape', 'x']"-->
    <!--      scroll="keep"-->
    <!--    >-->
    <!--      <div-->
    <!--        class="card p-4"-->
    <!--        :class="{'card-dark': isDark}"-->
    <!--      >-->
    <!--        <UserProfessionSelect-->
    <!--          :professions-list="professionsList"-->
    <!--          :add-user-profession-fn="addUserProfessionFn"-->
    <!--          :selected-profession-ids="computedProfessions.map(profession => profession.profession_id)"-->
    <!--          :user-id="userId"-->
    <!--          :is-dark="isDark"-->
    <!--          :skip-cache="skipCache"-->
    <!--          @close="closeProfessionSelectModal"-->
    <!--          @success-submit="fetchUserProfessions"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </b-modal>-->

    <!--    <b-modal-->
    <!--      v-model:active="professionBignumberUpdateOpen"-->
    <!--      :width="480"-->
    <!--      :can-cancel="['escape', 'x']"-->
    <!--      scroll="keep"-->
    <!--      destroy-on-hide-->
    <!--    >-->
    <!--      <div-->
    <!--        class="card p-4"-->
    <!--        :class="{'card-dark': isDark}"-->
    <!--      >-->
    <!--        <UserProfessionBignumberVerify-->
    <!--          :professions-list="professionsList"-->
    <!--          :update-user-profession-fn="updateUserProfessionFn"-->
    <!--          :profession-data="bignumberEditData"-->
    <!--          :user-id="userId"-->
    <!--          :is-dark="isDark"-->
    <!--          @close="closeBignumberEditModal"-->
    <!--          @success-submit="fetchUserProfessions"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </b-modal>-->

    <!--    <UserProfessionsSelect-->
    <!--      v-if="professionSelectOpen"-->
    <!--      :professions-list="professionsList"-->
    <!--      :add-user-profession-fn="(object) => $store.dispatch('profile/addBignumber', object)"-->
    <!--      :selected-profession-ids="computedProfessions.map(profession => profession.profession_id)"-->
    <!--      :show-title="false"-->
    <!--    />-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppInput from '@/components/common/AppInput.vue';

import { removeCircle, checkmarkDone } from 'ionicons/icons';
import {alertController, IonIcon, modalController, IonLoading, IonRow, IonCol, IonButton, IonText, IonModal} from '@ionic/vue';

import useRequestToast from "@/composables/useRequestToast";
const { openErrorToast } = useRequestToast();

// import UserProfessionsSelect from "@/components/profile/professions/UserProfessionSelect";
// import UserProfessionSelect from '@/components/profile/UserProfessionsSelect';
// import UserProfessionBignumberVerify from '@/components/profile/UserProfessionsBignumberVerify';
import EditProfessionModal from "@/components/profile/professions/EditProfessionModal";
import EditSplitProfessionModal from "@/components/profile/professions/EditSplitProfessionModal";

export default {
  name: 'UserProfessions',

  components: {
    // UserProfessionsSelect,
    // UserProfessionSelect,
    // UserProfessionBignumberVerify,
    IonLoading,
    IonIcon,
    AppInput,
    IonButton,
    IonRow,
    IonCol,
    IonText,
    IonModal,
    EditSplitProfessionModal,
    EditProfessionModal,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    isDark: {
      type: Boolean,
      default: false,
    },
    professions: {
      type: Array,
      required: true,
      default () {
        return [];
      },
    },
    userId: {
      type: [Number, String],
      default: null,
    },
    fetchUserProfessionsFn: {
      type: Function,
      required: true,
    },
    deleteUserProfessionFn: {
      type: Function,
      required: true,
    },
    addUserProfessionFn: {
      type: Function,
      required: true,
    },
    updateUserProfessionFn: {
      type: Function,
      default: () => Promise.resolve(),
    },
    skipCache: {
      type: Boolean,
      default: false,
    },
    professionMessages: {
      type: Array,
      default () {
        return [];
      },
    },
    showTitle: {
      type: Boolean,
      default: true,
    },

    canDeleteProfession: {
      type: Boolean,
      default: false,
    },

    canAddProfession: {
      type: Boolean,
      default: false,
    },

    canEditProfession: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['loading-list', 'closeTab'],

  data () {
    return {
      professionsNew: this.professions,
      professionSelectOpen: false,
      professionSplitSelectOpen: false,
      professionDeleting: {},
      fetchingUserProfessions: false,
      professionBignumberUpdateOpen: false,
      bignumberEditData: {},

      splitOpen: false,

      removeCircle,
      checkmarkDone,
    };
  },

  computed: {
    ...mapGetters({
      professionsListRaw: 'profile/getProfessionFromOccupation',
    }),

    professionsList () {
      return this.professionsListRaw.filter(p => !p.is_big_addition);
    },
    professionsAdditions () {
      return this.professionsListRaw.filter(p => p.is_big_addition);
    },
    addProfessionsText () {
      return this.computedProfessions.length ? this.$t('verifyProfile.addAnotherProfession') : this.$t('verifyProfile.addNewProfession');
    },
    computedProfessions: {
      get () {
        return this.professionsNew;
      },
      set (value) {
        this.professionsNew = value;
      },
    },

    hasProfessions () {
      return !!this.computedProfessions.length;
    },

    canEditProfessionComputed () {
      return this.canEditProfession && !this.readOnly;
    },
    canAddProfessionComputed () {
      return this.canAddProfession && !this.readOnly;
    },
    canDeleteProfessionComputed () {
      return this.canDeleteProfession && !this.readOnly;
    },
  },

  watch: {
    professions (value) {
      this.professionsNew = value;
    },
  },

  created () {
    this.$store.dispatch('profile/setProfessionsFromOccupations');
  },

  beforeUnmount () {
    this.$emit('loading-list', false);
  },

  methods: {
    async openProfessionSelectModal () {
      let modal;
      if (!this.hasProfessions) {
        this.professionSelectOpen = true;
        // modal = await modalController.create({
        //   component: EditProfessionModal,
        //   componentProps: {
        //     professionsList: this.professionsList,
        //     addUserProfessionFn: this.addUserProfessionFn,
        //     selectedProfessionIds: this.computedProfessions.map(profession => profession.profession_id),
        //     submitHandler: this.fetchUserProfessions,
        //     showTitle: false,
        //   },
        // });
      } else  {
        this.professionSplitSelectOpen = true;
        // modal = await modalController.create({
        //   component: EditSplitProfessionModal,
        //   componentProps: {
        //     professionsList: this.professionsList,
        //     professionsAdditions: this.professionsAdditions,
        //     addUserProfessionFn: this.addUserProfessionFn,
        //     selectedProfessionIds: this.computedProfessions.map(profession => profession.profession_id),
        //     submitHandler: this.fetchUserProfessions,
        //     showTitle: false,
        //   },
        // });
      }
      // await modal.present();
    },
    closeProfessionSelectModal () {
      this.professionSelectOpen = false;
    },
    fetchUserProfessions () {
      this.fetchingUserProfessions = true;
      this.$emit('loading-list', true);
      return this.fetchUserProfessionsFn(this.userId)
        .then(({ data }) => {
          this.computedProfessions = data;
        })
        .finally(() => {
          this.$emit('loading-list', false);
          //  this.$emit('closeTab');
          this.fetchingUserProfessions = false;

          // seems to be ionic bug, need to wait before closing modal, to prevent modalControllers exeptions
          setTimeout(() => {
            this.professionSplitSelectOpen = false;
            this.professionSelectOpen = false;
          }, 250);
        });
    },
    async deletePrompt(object) {
      const professionsWithSameBignumber = object.bignumber_id
        ? this.computedProfessions.filter(el => el.bignumber_id === object.bignumber_id)
        : [];

      const message = professionsWithSameBignumber.length > 1
        ? this.$t('profile.bignumberRemove.deletePrompMsgMultiProfession')
        : this.$t('profile.bignumberRemove.deletePrompMsg');

      const alert = await alertController
        .create({
          header: this.$t('profile.bignumberRemove.deletePrompTitle'),
          message,
          buttons: [
            { text: this.$t('commonKeys.cancel') },
            {
              text: this.$t('commonKeys.yes'),
              handler: () => this.deleteProfessionHandler(object),
            },
          ],
        });
      return alert.present();
    },
    deleteProfessionHandler (object) {
      this.professionDeleting[object.id] = true;
      this.deleteUserProfessionFn(object)
        .then(() => {
          // this.removeUserProfessionFormState(object);
          this.fetchUserProfessions();
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            openErrorToast(error);
          }
        })
        .finally(() => {
          this.professionDeleting[object.id] = false;
        });
    },
    removeUserProfessionFormState (profession) {
      const professionToDelete = this.computedProfessions.find(stateProfession => stateProfession.id === profession.id);
      if (professionToDelete) {
        this.computedProfessions.splice(this.computedProfessions.indexOf(professionToDelete), 1);
      }
    },

    getProfessionMessages (professionId) {
      const messageData = this.professionMessages.find(profession => profession.profession_id === professionId);
      if (messageData) {
        return messageData.message;
      }
      return '';
    },

    getProfessionMessagesType (professionId) {
      const messageData = this.professionMessages.find(profession => profession.profession_id === professionId);
      if (messageData) {
        return messageData.type;
      }
      return '';
    },

    openBignumberEditModal (professionObject) {
      this.bignumberEditData = professionObject;
      this.professionBignumberUpdateOpen = true;
    },
    closeBignumberEditModal () {
      this.bignumberEditData = {};
      this.professionBignumberUpdateOpen = false;
    },
    isBigAdditionProfession (professionObj) {
      return !!professionObj?.profession_details?.is_big_addition;
    },
  },
};
</script>

<style lang="scss" scoped>
  $body-color: grey;
  .card-dark {
    color: white !important;
    background: $body-color !important;
  }
  .outer-control {
    margin-top: 1.65rem;
  }

  .bignumber-field {
    display: flex !important;
    > *:first-child {
      flex: 1 !important;
    }
  }

  .flex-1 {
    flex: 1 !important;
  }

  .overflow-x-auto {
    overflow-y: hidden;
    overflow-x: auto;
  }

  .action-col {
    font-size: 24px;
    display: flex;
    align-items: center;
  }
</style>
