import axios from "@/http";
import { TRACKING_URL } from '@/config/api';

export default () => {
  const trackThis =  (action = '', meta_data = {}, entity_id = null) => {
    const payload = {
      action,
      meta_data,
      entity_id,
    };
    return new Promise((resolve) => {
      // always resolve tracking Promise
      axios.post(TRACKING_URL, payload, {
      }).finally(() => resolve());
    });
  };

  return { trackThis };
};
