import axios from "@/http";

import { GET_MOBILE_DETAILS_URL } from '@/config/api';

const initializeStates = () => {
  return {
    mobileDetails: false,
  };
};

export const state = () => initializeStates();

export const actions = {

  /**
   * @param commit
   * @returns {Promise<unknown>}
   */
  setMobileDetails ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(GET_MOBILE_DETAILS_URL)
        .then(({ data }) => {
          commit('setMobileDetails', data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const mutations = {

  /**
   * @param state
   * @param data
   */
  setMobileDetails (state, data) {
    state.mobileDetails = data;
  },

  resetMobileState (state) {
    Object.assign(state, initializeStates());
  },
};

export const getters = {
  getMobileDetails: state => state.mobileDetails,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
