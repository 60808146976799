<template>
  <h2 class="fw-400 title ml-2">{{greeting}}</h2>
</template>

<script>
import { computed } from 'vue';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n/index';


export default {
  name: "GreetingHeader",
  setup() {
    const store = useStore();
    const { t } = useI18n();


    const greeting = computed(() => {
      const userName = store.state.profile.profileDetails.first_name || '';
      const localTime = +new Date().toLocaleTimeString('en-GB', { hour: '2-digit' });
      const textGreeting = localTime > 8 && localTime < 13 ? t('home.goodMorning') : localTime >= 13 && localTime < 18 ? t('home.goodAfternoon') : t('home.goodNight');
      return textGreeting + ' ' +  userName;
    });

    return {
      greeting,
    };

  },
};
</script>
