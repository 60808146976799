import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import replace from 'lodash/replace';


import {convertSecondsToTimeString, getSecondsFromHMSString} from '@/helpers/functions';
import {
  RECENTLY_PLAYED_PROGRESS_STORAGE_KEY,
  RECENTLY_PLAYED_STORAGE_KEY,
  STREAM_PROGRESS_HISTORY_STORAGE_KEY, STREAM_PROGRESS_HISTORY_STORAGE_KEY_REGEX, STREAM_PROGRESS_HISTORY_STORAGE_TTL,
} from "@/config/constants";
import useAppStorage from "@/composables/useAppStorage";
import useFirebase8 from "@/composables/useFirebase8";

const { getKeyWithExpire, setKeyWithExpire, getKeys } = useAppStorage();

const defaultTrackingData = () => {
  return {
    hash: null,
    start: null,
    end: null,
    chapter_id: null,
    is_completed_at: null,
  };
};

const getLocalTimestamp = () => {
  return new Date(Date.now() - (new Date().getTimezoneOffset() * 60000)).getTime() / 1000 | 0;
};

const getRandomHash = () => {
  return uuidv4();
};

const optimizeRanges = (parts, { startKey = 'start', endKey = 'end' } = {}) => {
  const isAinB = (itemA, itemB) => ((itemA[startKey] >= itemB[startKey]) && (itemA[endKey] <= itemB[endKey]));

  const res = [];
  parts.forEach((el) => {
    const newEl = res.find(part => isAinB(el, part));
    !newEl && res.push(el);
  });

  return res.map((el) => {
    return {
      start: el[startKey],
      end: el[endKey],
    };
  });
};

const TRACKING_INTERVAL = 15 * 1000; // 15s
const STREAM_COMPLETED_PART = 0.9; // 90%

// const { getData, getUser, updateData, getTimestampKey } = useFirebase();

const { initAuth: fireAuth, initDB: fireDB, getTimestampKey } = useFirebase8();

const getUser = () => ({});
const updateData = () => ({});

export default {
  data () {
    return {
      streamTrackingData: defaultTrackingData(),

      trakingInitiliazated: false,
      includeSeekPosition: false,

      trackingIntervalId: null,

      localProgress: {},
      localStorageProgress: [],

      localStorageProgressFetched: false,

      dbProgress: [],
    };
  },

  computed: {
    formattedLocalProgress () {
      return Object.values(this.localProgress);
    },

    progressHistory () {
      return [...this.formattedLocalProgress, ...this.localStorageProgress, ...this.dbProgress];
    },

    appActive () {
      return this.$store.state.app.appActive;
    },

    currentStreamSeconds () {
      return this.$store.state.player.trackInfo.trackCurrentTime;
    },

    currentStreamDuration () {
      return this.$store.state.player.trackInfo.trackDuration;
    },

    currentSeekPosition () {
      return this.$store.getters['player/getSeekPosition'];
    },

    streamChapters () {
      return this.$store.getters['player/getCurrentEpisode']?.chapters ?? [];
    },

    streamId () {
      return this.$store.getters['player/getCurrentEpisode']?.id ?? null;
    },

    chaptersWithEndTimes () {
      const chapters = cloneDeep(this.streamChapters);

      chapters.sort((a, b) => getSecondsFromHMSString(a.time_begin) - getSecondsFromHMSString(b.time_begin));

      return chapters.map((chapter, index, chapters) => {
        if (!chapter.time_end) {
          const nextChapter = chapters[index + 1];
          if (nextChapter) {
            const newEndSeconds = getSecondsFromHMSString(nextChapter.time_begin) - 1;
            chapter.time_end = convertSecondsToTimeString(newEndSeconds);
          }
        }
        return chapter;
      });
    },
  },

  beforeDestroy () {
    this.clearTrackingInterval();
  },


  methods: {
    async startTracking () {
    // resets;
      this.localProgress = {};
      this.streamTrackingData = defaultTrackingData();
      this.localStorageProgress = [];
      this.dbProgress = [];
      this.includeSeekPosition = true;
      this.localStorageProgressFetched = false;

      // console.log('start tracking');
      // await this.getLastStreamPosition();

      this.getLastStreamPositionStorage();

      this.fetchDBProgress(this.streamId);
      this.getLocalStorageProgress(this.streamId).finally(() => {
        this.localStorageProgressFetched = true;
      });

      this.trakingInitiliazated = true;

      this.initTrackingInterval();

      this.includeSeekPosition = false;
    },

    // getLastStreamPositionOld() {
    //   return new Promise((resolve) => {
    //     const anonUser = getUser();
    //     getData(`progress/${this.streamId}/${anonUser.uid}`)
    //       .then((getSnapshot) => {
    //         let chapters = getSnapshot.val();
    //         if (chapters) {
    //           chapters = Object.values(chapters);
    //           chapters = chapters.filter(chapter => chapter.user_id === this.$store.getters['authentication/getUser'].id);
    //           chapters.sort((a, b) => a.timestamp - b.timestamp);
    //           const lastChapter = chapters.pop();
    //           if (lastChapter) {
    //             console.log('lastChapter', lastChapter);
    //             const seconds = getSecondsFromHMSString(lastChapter.start);
    //             if (seconds) {
    //               console.log('seek! to from MIXIN', seconds);
    //               this.$store.commit('player/seekStream', seconds);
    //               resolve();
    //             } else {
    //               console.log('get last from other place');
    //               // this.$store.dispatch('modules/livestream/getLastStreamPosition', this.streamId)
    //               //   .finally(resolve);
    //             }
    //           }
    //           resolve();
    //         }
    //
    //         resolve();
    //       })
    //       .catch((error) => {
    //         console.log('error', error);
    //         resolve();
    //       });
    //
    //   });
    // },
    getLastStreamPosition() {
      return new Promise((resolve) => {
        const uid = fireAuth().getUid();
        // console.log('getLastStreamPosition', uid);
        if (!uid) { return; }
        const path = `progress/${this.streamId}/${uid}`;
        // console.log('path', path);
        fireDB().ref(path).get()
          .then((getSnapshot) => {
            let chapters = getSnapshot.val();

            if (chapters) {
              chapters = Object.values(chapters);
              chapters = chapters.filter(chapter => chapter.user_id === this.$auth.user.id);
              chapters.sort((a, b) => a.timestamp - b.timestamp);
              const lastChapter = chapters.pop();
              if (lastChapter) {
                const seconds = getSecondsFromHMSString(lastChapter.start);
                if (seconds) {
                  this.$store.commit('modules/livestream/seekStream', seconds);
                  resolve();
                } else {
                  this.$store.dispatch('modules/livestream/getLastStreamPosition', this.streamId)
                    .finally(resolve);
                }
              }
              resolve();
            }

            resolve();
          })
          .catch((error) => {
            // console.log('error getLastStreamPosition', error);
            resolve();
          });
      });
    },

    getLastStreamPositionStorage () {
      // console.log('this.$store.state.player.recentlyPlayedProgress[this.streamId]', this.$store.state.player.recentlyPlayedProgress[this.streamId]);

      const beProgress = this.currentEpisode?.stream_user?.[0]?.end_time;
      // console.log(this.currentEpisode?.stream_user?.[0]?.end_time);
      const progress = this.$store.state.player.recentlyPlayedProgress[this.streamId]?.progress;
      // console.log('be progress is', beProgress);
      // console.log('progress store is: ',this.$store.state.player.recentlyPlayedProgress);
      // console.log('getLastStreamPositionStorage, progress: ', progress);

      const result = progress ?? beProgress;

      if (result) {
        const lastPosition = getSecondsFromHMSString(result);
        // console.log('seek from mixin', lastPosition);
        this.$store.commit('player/seekStream', lastPosition);
      } else {
        this.$store.commit('player/seekStream', 0);
      //  this.includeSeekPosition = false;
      }

      // console.log('getLastStreamPositionStorage end success');
    },

    updateStorageLastPosition() {
      this.$store.dispatch('player/updateRecentlyPlayedProgressStorage', { stream: this.streamId, progress: this.streamTrackingData.end ?? '00:00:00' });
    },

    initTrackingInterval (seekedTime, timeBeforeSeek) {
      const logged = this.$store.getters['authentication/isLogged'];

      if (!this.trakingInitiliazated || !logged) {
        return;
      }

      if (this.streamTrackingData.end && this.streamTrackingData.start && this.streamTrackingData.hash) {
        // update uncompleted part before creating new chapter
        if (timeBeforeSeek) {
          this.updateTrackingData(timeBeforeSeek);
          this.publishTrackingChapter();
        }
      }

      this.clearTrackingInterval();

      this.startNewTrackingChapter();
      this.publishTrackingChapter();
      this.updateStorageLastPosition();

      this.trackingIntervalId = setInterval(() => {
        // if (!this.appActive) {
        //   return;
        // }
        this.updateTrackingData();
        this.publishTrackingChapter();
        this.updateStorageLastPosition();
      }, TRACKING_INTERVAL);
    },

    clearTrackingInterval () {
      clearInterval(this.trackingIntervalId);
      this.trackingIntervalId = null;
    },

    startNewTrackingChapter () {
      let start = null;
      if (this.includeSeekPosition) {
      //  console.log('INCLUDE SEEK POSITION', this.currentSeekPosition);
        if (this.currentSeekPosition || this.currentSeekPosition === 0) {
          start = convertSecondsToTimeString(this.currentSeekPosition);
        } else {
          start = convertSecondsToTimeString(this.currentStreamSeconds);
        }
      } else {
        start = convertSecondsToTimeString(this.currentStreamSeconds);
      }

      const end = start;

      //  console.log('start new tracking chapter', start);

      const newTrackingData = defaultTrackingData();
      newTrackingData.hash = getRandomHash();
      newTrackingData.start = start;
      newTrackingData.end = end;

      this.streamTrackingData = newTrackingData;
    },

    updateTrackingData (previousPlayerSeconds) {
      this.streamTrackingData.end = previousPlayerSeconds
        ? convertSecondsToTimeString(previousPlayerSeconds)
        : convertSecondsToTimeString(this.currentStreamSeconds);

      if (this.streamTrackingData.end < this.streamTrackingData.start) {
        this.streamTrackingData.start = this.streamTrackingData.end;
      }

      const currentChapter = this.chaptersWithEndTimes.find(chapter => this.chapterPlaying(chapter));

      if (this.streamTrackingData.chapter_id && (this.streamTrackingData.chapter_id !== currentChapter?.id)) {
        // chapter id changed
        const prevChapterCompleted = this.chapterPlayed(this.chaptersWithEndTimes.find(chapter => chapter.id === this.streamTrackingData.chapter_id));
        // publish current chapter and create new
        if (prevChapterCompleted) {
          this.streamTrackingData.is_completed_at = getLocalTimestamp();
          this.publishTrackingChapter();

          this.startNewTrackingChapter();
        }
      }

      this.streamTrackingData.chapter_id = currentChapter?.id ?? null;

      if (!currentChapter?.id || (currentChapter && !currentChapter.end_time)) {
        // stream dont have chapters or last chapter without end time, check for stream end
        this.streamTrackingData.is_completed_at = this.isStreamCompleted() ? getLocalTimestamp() : null;
      }
    },

    publishTrackingChapterOld () {
      const path = `progress/${this.streamId}/${this.$fire.auth.getUid()}`;
      const key = String(this.streamTrackingData.hash);
      const payload = Object.assign(
        this.streamTrackingData,
        {
          //    timestamp: getTimestampKey(),
          user_id: this.$auth.user.id,
        },
      );
      this.$fire.database.ref(path).update({
        [key]: payload,
      });
    },

    publishTrackingChapterOldFirebase9 () {
      const path = `progress/${this.streamId}/${getUser().uid}`;
      const key = String(this.streamTrackingData.hash);
      const payload = Object.assign(
        this.streamTrackingData,
        {
          timestamp: getTimestampKey(),
          user_id: this.$store.getters['authentication/getUser'].id ?? null,
        },
      );
      updateData(path, {
        [key]: payload,
      });
    },

    publishTrackingChapter () {
      const uid = fireAuth().getUid();
      const logged = this.$store.getters['authentication/isLogged'];

      if (!uid || !logged) {
      //  console.log('no uid in publishTrackingChapter');
        return;
      }
      const path = `progress/${this.streamId}/${uid}`;
      const key = String(this.streamTrackingData.hash);

      const payload = Object.assign(
        this.streamTrackingData,
        {
          timestamp: getTimestampKey(),
          user_id: this.$store.getters['authentication/getUser'].id ?? null,
        },
      );
      fireDB().ref(path).update({
        [key]: payload,
      });

      this.updateProgressLocal(key, payload);
      if (this.localStorageProgressFetched) {
        this.setLocalStorageProgress(this.streamId);
      }
    },


    chapterPlaying (chapter) {
      if (getSecondsFromHMSString(chapter.time_begin) < this.currentStreamSeconds && !chapter.time_end) {
        return true;
      }
      return getSecondsFromHMSString(chapter.time_begin) < this.currentStreamSeconds && getSecondsFromHMSString(chapter.time_end) > this.currentStreamSeconds;
    },

    chapterPlayed (chapter) {
      if (!chapter.time_end) {
        return false;
      }
      return getSecondsFromHMSString(chapter.time_end) < this.currentStreamSeconds;
    },

    isStreamCompleted () {
      if (this.currentStreamDuration <= 0) {
        return false;
      }

      const plannedCompletedSeconds = this.currentStreamDuration * STREAM_COMPLETED_PART;
      return this.currentStreamSeconds >= plannedCompletedSeconds;
    },

    //history save

    async getLocalStorageProgress (streamId) {
      const storageKey = replace(STREAM_PROGRESS_HISTORY_STORAGE_KEY, '{id}', streamId);

      const progressStorage = await getKeyWithExpire(storageKey);
      if (progressStorage) {
        this.localStorageProgress = progressStorage;
      }
    },

    setLocalStorageProgress (streamId) {
      const storageKey = replace(STREAM_PROGRESS_HISTORY_STORAGE_KEY, '{id}', streamId);

      const progress = optimizeRanges(this.formattedLocalProgress);
      const commonProgress = concat(this.localStorageProgress, progress);
      setKeyWithExpire(storageKey, commonProgress, STREAM_PROGRESS_HISTORY_STORAGE_TTL);
    },

    updateProgressLocal (key, payload) {
      payload = cloneDeep(payload);
      payload.start = getSecondsFromHMSString(payload.start);
      payload.end = getSecondsFromHMSString(payload.end);
      this.localProgress[key] = payload;
    },

    async clearExpiredStorageProgress () {
      const storageKeys = await getKeys();
      if (storageKeys?.keys) {
        storageKeys.keys.forEach(key => {
          if (STREAM_PROGRESS_HISTORY_STORAGE_KEY_REGEX.test(key)) {
            getKeyWithExpire(key);
          }
        });
      }
    },

    fetchDBProgress (streamId) {
      this.$store.dispatch('player/getStreamUserProgress', {
        userId: this.$store.getters['authentication/getUser'].id,
        streamId,
      })
        .then((data) => {
          this.dbProgress = optimizeRanges(data.progress, { startKey: 'start_time', endKey: 'end_time' })
            .map((part) => {
              part.start = getSecondsFromHMSString(part.start);
              part.end = getSecondsFromHMSString(part.end);
              return part;
            });
        });
    },
  },
};
