<template>
  <div :class="{'no-opacity': alwaysShown}">
    <ion-button
      class="edit-button"
      @click.stop="openEditPage"
    >
      <IonIcon
        :icon="pencilOutline"
        size="small"
      />
    </ion-button>
  </div>
</template>

<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { pencilOutline } from 'ionicons/icons';

import useInAppBrowser from "@/composables/useInAppBrowser";

export default {
  name: 'EditButton',
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    alwaysShown: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { openUrl } = useInAppBrowser();

    const openEditPage = () => {
      const base = process.env.VUE_APP_URL;
      openUrl(`${base}/${props.url}`);
    };

    return {
      pencilOutline,
      openEditPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  width: 30px;
  height: 30px;
  --border-radius: 50%;
  --padding-start: 5px;
  --padding-end: 5px;
  opacity: 0;
}

.no-opacity .edit-button {
  position: fixed;
  top: 100px;
  right: 50px;
  opacity: 1;
}
</style>
